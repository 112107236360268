import esLocale from './es';
import enLocale from './en';
import './yup';

const messages = {
  es: esLocale,
  en: enLocale,
};

export default { messages };
