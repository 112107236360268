<template>
  <div class="flex mb-1 lg:flex-wrap">
    <mute-avatar
      v-for="user in displayUsers"
      :key="user.id"
      :src="user.avatar"
      size="small"
      class="mb-1 mr-1"
    />
    <p
      v-if="excededMaxUsers"
      class="text-sm leading-none text-gray-600"
    >
      +{{ additionalUsers }}
    </p>
  </div>
</template>

<script>
import MuteAvatar from './mute-avatar.vue';

const MAX_USERS_DISPLAYED_DESKTOP = 6;
const TITLE_CHARACTERS_PER_USER_ICON = 3;

export default {
  components: { MuteAvatar },
  props: {
    users: {
      type: Array,
      required: true,
    },
    cardTitleLength: {
      type: Number,
      default: null,
    },
  },
  computed: {
    maxUsersDisplayed() {
      if (this.hasLargeScreen || !this.cardTitleLength) return MAX_USERS_DISPLAYED_DESKTOP;

      return Math.floor(this.cardTitleLength / TITLE_CHARACTERS_PER_USER_ICON);
    },
    excededMaxUsers() {
      return this.users.length > this.maxUsersDisplayed;
    },
    additionalUsers() {
      return this.users.length - this.maxUsersDisplayed;
    },
    displayUsers() {
      if (this.excededMaxUsers) return this.users.slice(0, this.maxUsersDisplayed);

      return this.users;
    },
    hasLargeScreen() {
      return this.$store.getters.hasLargeScreen;
    },
  },
};
</script>
