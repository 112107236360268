import { createRouter, createWebHashHistory } from 'vue-router';

import Sections from './components/sections.vue';
import Announcements from './components/announcements.vue';
import UnreadMessages from './components/unread-messages.vue';
import SavedAnnouncements from './components/saved-announcements';
import DraftComments from './components/draft-comments';
import Search from './components/search';

const routes = [
  {
    path: '/',
    name: 'MuteMeetings',
    component: Sections,
    props: true,
    children: [
      {
        path: 'sections',
        component: Announcements,
        props: true,
      },
      {
        path: 'sections/:selectedSectionId/',
        component: Announcements,
        props: true,
      },
      {
        path: 'sections/:selectedSectionId/:selectedAnnouncementId',
        component: Announcements,
        props: true,
      },
      {
        path: '/unread-messages',
        component: UnreadMessages,
      },
      {
        path: '/saved-announcements',
        component: SavedAnnouncements,
      },
      {
        path: '/draft-comments',
        component: DraftComments,
      },
    ],
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    props: true,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if ((from.path === '/saved-announcements' || from.name === 'Search') &&
     to.params && to.params.selectedAnnouncementId) {
      return (
        document
          .getElementById(`announcement-${to.params.selectedAnnouncementId}`)
          .scrollIntoView({ block: 'start', behavior: 'smooth' })
      );
    }

    return savedPosition;
  },
});

export default router;
