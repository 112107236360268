import api from './index';

export default {
  getUnreadMessages(documentId) {
    return api({
      method: 'get',
      url: `/api/internal/documents/${documentId}/unread_messages`,
    });
  },
  markAllMessagesAsRead(documentId) {
    return api({
      method: 'put',
      url: `/api/internal/documents/${documentId}/mark_as_read`,
    });
  },
  markSectionMessagesAsRead(documentId, sectionId) {
    return api({
      method: 'put',
      url: `/api/internal/documents/${documentId}/sections/${sectionId}/mark_as_read`,
    });
  },
  markSectionAnnouncementsAsRead(documentId, sectionId) {
    return api({
      method: 'put',
      url: `/api/internal/documents/${documentId}}/sections/${sectionId}/mark_announcements_as_read`,
    });
  },
  markAnnouncementAsRead(documentId, announcementId) {
    return api({
      method: 'put',
      url: `/api/internal/documents/${documentId}/announcements/${announcementId}/mark_as_read`,
    });
  },
  markAnnouncementCommentsAsRead(documentId, announcementId) {
    return api({
      method: 'put',
      url: `/api/internal/documents/${documentId}/announcements/${announcementId}/mark_comments_as_read`,
    });
  },
  markCommentAsRead(documentId, commentId) {
    return api({
      method: 'put',
      url: `/api/internal/documents/${documentId}/comments/${commentId}/mark_as_read`,
    });
  },
};
