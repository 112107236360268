import { InputRule, mergeAttributes, PasteRule } from '@tiptap/core';
import Mention from '@tiptap/extension-mention';
import { PluginKey } from 'prosemirror-state';
import emojiRegex from 'emoji-regex';
import { isNil } from 'lodash';
import { colonsRuleHandler, unicodeRuleHandler } from './ruleHandlers';

const EMOJI_UNICODE_REGEX = emojiRegex();
const EMOJI_NAME_REGEX = /:[^:\s]+(?:::[^:\s]+)?:/g;

export const EmojiPluginKey = new PluginKey('emoji');

export default Mention.extend({
  name: 'emoji',
  addOptions() {
    const opts = this.parent ? this.parent() : {};

    return {
      ...opts,
      getEmoji: (name) => name,
      emojiIndex: null,
    };
  },
  addAttributes() {
    const attrs = this.parent ? this.parent() : {};

    return {
      ...attrs,
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id').split('::')[0].replace(/:/g, ''),
      },
      skin: {
        default: null,
        parseHTML: (element) => {
          const skin = element.getAttribute('data-id').split('::')[1];

          return skin && skin.replace(/:/g, '');
        },
      },
    };
  },
  // eslint-disable-next-line max-statements
  renderHTML({ node, HTMLAttributes }) {
    const { id, skin } = node.attrs;

    if (isNil(id)) return ['p'];

    const emoji = this.options.getEmoji(id, skin);
    let dataId = `${id}`;

    let htmlAttributes = mergeAttributes(
      { 'data-type': this.name, 'data-id': dataId, class: 'emoji-icon' },
      this.options.HTMLAttributes,
      HTMLAttributes,
    );

    if (emoji) {
      const [, skinColons] = emoji.colons.split('::');
      if (skinColons) {
        dataId += `::${skinColons.replace(/:/g, '')}`;
        htmlAttributes = mergeAttributes(htmlAttributes, { 'data-id': dataId });
      }

      if (emoji.native) {
        return ['span', htmlAttributes, emoji.native];
      } else if (emoji.imageUrl) {
        return [
          'span',
          mergeAttributes(
            { class: 'trix-content__custom-emoji', style: `background-image:url('${emoji.imageUrl}')` },
            htmlAttributes,
          ),
          // Zero-width space to allow cursor to be placed before/after emoji. Also helps with ActionText
          // accepting a message with only a custom emoji in the backend.
          '\u200B',
        ];
      }
    }

    return ['span', htmlAttributes, this.options.renderLabel({ options: this.options, node })];
  },
  renderText({ node }) {
    return node.attrs.id;
  },
  addInputRules() {
    return [
      new InputRule({
        find: new RegExp(`${EMOJI_UNICODE_REGEX.source}$`),
        handler: unicodeRuleHandler.bind(this),
      }),
      new InputRule({
        find: new RegExp(`${EMOJI_NAME_REGEX.source}$`),
        handler: colonsRuleHandler.bind(this),
      }),
    ];
  },
  addPasteRules() {
    return [
      new PasteRule({
        find: EMOJI_UNICODE_REGEX,
        handler: unicodeRuleHandler.bind(this),
      }),
      new PasteRule({
        find: EMOJI_NAME_REGEX,
        handler: colonsRuleHandler.bind(this),
      }),
    ];
  },
}).configure({
  suggestion: {
    char: ':',
    pluginKey: EmojiPluginKey,
  },
  renderLabel({ options, node }) {
    const { suggestion: { char } } = options;
    const { attrs: { id, skin } } = node;

    let label = `${char}${id}`;
    if (skin) {
      label += `::${skin}`;
    }

    return `${label}${char}`;
  },
});
