import api from './index';

export default {
  saveReaction(parentType, parentId, emojiParams) {
    return api({
      method: 'post',
      url: `/api/internal/${parentType}/${parentId}/reactions`,
      data: emojiParams,
    });
  },
  deleteReaction(id) {
    return api({
      method: 'delete',
      url: `/api/internal/reactions/${id}`,
    });
  },
};
