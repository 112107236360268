<template>
  <div>
    <div
      ref="container"
      class="flex flex-col overflow-hidden overflow-y-auto bg-white border rounded-lg max-h-40"
    >
      <template v-if="items.length">
        <button
          v-for="(item, index) in items"
          :id="index"
          :key="item.short_name"
          class="flex flex-row items-center justify-start p-2 border-b first:rounded-t-lg last:rounded-b-lg"
          :class="{ 'bg-blue-400': index === selectedIndex }"
          @click="selectItem(index)"
          @mouseover="onHoverEmoji(index)"
        >
          <span class="mr-2">
            <span v-if="item.native">{{ item.native }}</span>
            <img
              v-else
              :src="item.imageUrl"
              class="w-4"
            >
          </span>
          <span v-text="item.short_name" />
        </button>
      </template>
      <div
        v-else
        class="p-2"
      >
        {{ noResult }}
      </div>
    </div>
    <emoji-variant
      ref="variantRef"
      :emoji="selectedEmoji"
      :variant-index="selectedVariant"
      @hover-variant="onHoverVariant"
      @select-variant="selectItem(selectedIndex)"
    />
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import SuggestionMixin from '../suggestion/suggestionMixin';
import EmojiVariant from './EmojiVariant';

export default {
  components: {
    EmojiVariant,
  },
  mixins: [SuggestionMixin],
  data() {
    return {
      mounted: false,
      selectedVariant: 0,
      popperInstance: null,
      virtualElement: null,
    };
  },
  computed: {
    noResult() {
      return this.$t('editor.mention.noResult');
    },
    selectedEmoji() {
      return this.items[this.selectedIndex];
    },
    selectedButton() {
      if (!this.mounted) return null;

      const container = this.$refs.container;
      const buttons = container.children;

      return buttons[this.selectedIndex] || container;
    },
  },
  watch: {
    selectedButton() {
      this.popperInstance.update();
    },
    items() {
      this.selectedIndex = 0;
      this.popperInstance.update();
    },
  },
  mounted() {
    this.virtualElement = {
      getBoundingClientRect: this.getBoundingClientRect,
      contextElement: this.$refs.container,
    };

    const content = this.$refs.variantRef.$el;
    this.popperInstance = createPopper(this.virtualElement, content, {
      placement: 'right',
    });

    this.mounted = true;
  },
  beforeUnmount() {
    this.popperInstance.destroy();
  },
  methods: {
    selectItem(index) {
      let item = this.items[index];
      const { _skins: skins, short_name: id } = item;
      let skin = null;
      if (skins && this.selectedVariant < skins.length) {
        item = skins[this.selectedVariant];
        skin = item.colons.split('::')[1].replace(/:/g, '');
      }

      if (item) {
        this.command({ id, skin });
      }
    },
    onKeyDown(e) {
      const result = this.handleOnKeyDown(e);
      this.$nextTick(() => {
        this.popperInstance.update();
      });

      return result;
    },
    handleOnKeyDown({ event }) {
      if (this.verticalHandler(event)) {
        this.selectedVariant = 0;

        return true;
      }

      return (
        this.horizontalHandler(event) ||
        this.enterHandler(event)
      );
    },
    horizontalHandler(event) {
      const { key } = event;
      if (key !== 'ArrowLeft' && key !== 'ArrowRight') return false;

      const { _skins: skins } = this.selectedEmoji;

      if (!skins) return false;
      if (key === 'ArrowLeft') {
        this.selectedVariant = (this.selectedVariant + skins.length - 1) % skins.length;
      } else if (key === 'ArrowRight') {
        this.selectedVariant = (this.selectedVariant + 1) % skins.length;
      }

      return true;
    },
    onHoverVariant(index) {
      this.selectedVariant = index;
    },
    onHoverEmoji(index) {
      const oldIndex = this.selectedIndex;
      this.selectedIndex = index;
      if (oldIndex !== index) {
        this.selectedVariant = 0;
      }
    },
    getBoundingClientRect() {
      const element = this.selectedButton || this.$refs.container;

      // This is to prevent the variant selector from being positioned
      // incorrectly on the first render
      let rect = element.getBoundingClientRect();
      const { x, y, height, width } = rect;
      if ([x, y, height, width].every(value => value === 0)) {
        rect = this.$refs.container.getBoundingClientRect();
        rect.height /= 4;
      }

      return rect;
    },
  },
};
</script>
