<template>
  <announcement-section-wrapper
    v-if="allowUnreadMessages"
    tag="router-link"
    class="flex items-center lg:h-auto"
    :active="$route.path === '/unread-messages'"
    to="/unread-messages"
  >
    <div
      class="flex flex-row flex-1 items-center py-3 border-b whitespace-nowrap lg:whitespace-normal w-full"
    >
      <inline-svg
        :src="require('../../../assets/images/icons/unread-message-icon.svg')"
        class="w-4 h-4 mr-2 text-gray-400 fill-current"
      />
      <p class="leading-none">
        {{ $t('meetings.unread') }}
      </p>
      <mute-notification
        v-if="unreadMessagesAmount"
        :count="unreadMessagesAmount"
        class="ml-auto group-hover:hidden"
      />
    </div>
  </announcement-section-wrapper>
  <announcement-section-wrapper
    tag="router-link"
    class="flex items-center lg:h-auto"
    :active="$route.path === '/draft-comments'"
    to="/draft-comments"
    @click="$emit('hide-mobile-modal')"
  >
    <div
      class="flex flex-row flex-1 items-center py-3 border-b whitespace-nowrap lg:whitespace-normal w-full"
    >
      <inline-svg
        :src="require('../../../assets/images/icons/edit.svg')"
        class="w-4 h-4 mr-2 text-gray-400 fill-current"
      />
      <p class="leading-none">
        {{ $t('meetings.draftComments') }}
      </p>
      <mute-notification
        v-if="draftCommentsCount"
        :count="draftCommentsCount"
        class="ml-auto group-hover:hidden text-slate-800 bg-transparent"
      />
    </div>
  </announcement-section-wrapper>
  <announcement-section-wrapper
    tag="router-link"
    class="flex items-center lg:h-auto"
    :active="$route.path === '/saved-announcements'"
    to="/saved-announcements"
    @click="$emit('hide-mobile-modal')"
  >
    <div
      class="flex flex-row flex-1 items-center py-3 border-b whitespace-nowrap lg:whitespace-normal w-full"
    >
      <inline-svg
        :src="require('../../../assets/images/icons/bookmark.svg')"
        class="w-4 h-4 mr-2 text-gray-400 fill-current"
      />
      <p class="leading-none">
        {{ $t('meetings.savedAnnouncements') }}
      </p>
      <mute-notification
        v-if="savedAnnouncementsAmount"
        :count="savedAnnouncementsAmount"
        class="ml-auto group-hover:hidden text-slate-800 bg-transparent"
      />
    </div>
  </announcement-section-wrapper>
</template>

<script>
import AnnouncementSectionWrapper from '../announcement-section/announcement-section-wrapper.vue';

export default {
  components: {
    AnnouncementSectionWrapper,
  },
  emits: ['hide-mobile-modal'],
  computed: {
    draftCommentsCount() {
      return this.$store.getters.draftCommentsCount();
    },
    allowUnreadMessages() {
      return this.$store.state.document.allowUnreadMessages;
    },
    unreadMessagesAmount() {
      return this.$store.getters.totalUnreadCount;
    },
    savedAnnouncementsAmount() {
      const documentId = this.$store.state.document.document.id;
      const savedAnnouncements = this.$store.state.document.savedAnnouncementsByDocument[documentId] || {};

      return Object.entries(savedAnnouncements).reduce((total, [, items]) => total + items.length, 0);
    },
  },
};
</script>
