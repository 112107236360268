import api from './index';

export default {
  addAnnouncement(sectionId, data) {
    return api({
      method: 'post',
      url: `/api/internal/sections/${sectionId}/announcements`,
      data,
    });
  },
  addSection(documentId, body) {
    return api({
      method: 'post',
      url: `/api/internal/documents/${documentId}/sections`,
      data: body,
    });
  },
};
