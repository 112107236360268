<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="flex w-full h-full"
  >
    <div
      class="flex items-center flex-1 min-w-0"
    >
      <span v-html="text" />
      <inline-svg
        class="w-4 h-4 ml-auto text-gray-500 cursor-pointer fill-current hover:text-gray-600"
        :src="require('assets/images/icons/close-icon.svg')"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    text: { type: String, required: true },
  },
  emits: ['close'],
};
</script>

