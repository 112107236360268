<template>
  <div class="flex h-full overflow-y-auto">
    <div
      v-if="selectedSection"
      id="top-bound"
      class="flex flex-col min-w-0 border-r border-gray-200 lg:w-full"
    >
      <div
        class="flex flex-row justify-between items-center w-screen p-2 bg-slate-100 text-xs font-bold text-gray-400 border-b border-gray-200 max-h-full z-0 lg:w-auto relative top-0"
        :class="[selectedAnnouncement ? 'hidden lg:flex' : 'flex']"
      >
        {{ $t('meetings.announcement.title') }} "{{ selectedSectionTitle }}"
        <inline-svg
          class="lg:hidden text-gray-400 cursor-pointer fill-current hover:text-gray-600 -my-2"
          :src="require('assets/images/icons/close-icon.svg')"
          @click="closeSection"
        />
      </div>
      <div
        ref="announcementsContainer"
        class="flex-col overflow-y-auto grow mt-0 bg-white w-auto lg:h-2/6 md:h-4/5"
        :class="[selectedAnnouncement ? 'hidden lg:flex' : 'flex']"
      >
        <div
          v-if="sortedAnnouncements.length > 0"
          class="flex-col grow overflow-y-auto space-y-0 md:relative md:flex-col lg:w-full lg:flex-row lg:h-full"
        >
          <announcement
            v-for="(id, index) in sortedAnnouncements"
            :id="`announcement-${id}`"
            :key="id"
            class="justify-start"
            :announcement="announcements[id]"
            :author="announcements[id].author"
            :text="announcements[id].body"
            :selected="id === (selectedAnnouncement && selectedAnnouncement.id)"
            :borderless="lastRead(index)"
            :is-saved="isSaved(id)"
            :is-new="firstUnreadAnnouncementId === id"
            :selected-section-id="selectedSectionId"
            @change-announcement="changeAnnouncement(id)"
            @visibility-changed="(isVisible, entry) => visibilityChanged(isVisible, entry, id)"
          />
        </div>
        <p
          v-else
          class="flex items-center justify-center w-full h-full p-4 text-gray-600"
        >
          {{ $t('meetings.announcement.noAnnouncements') }}
        </p>
        <div
          v-if="notificationsTop"
          class="absolute inset-x-0 top-0 flex justify-center mt-12"
        >
          <more-unreads
            :is-on-top="true"
            @click="scrollToUnread(true)"
          />
        </div>
        <div
          v-if="notificationsBottom"
          class="absolute inset-x-0 bottom-0 flex justify-center mb-36 lg:mb-40"
        >
          <more-unreads
            @click="scrollToUnread(false)"
          />
        </div>
      </div>
      <add-announcement-card
        :class="[selectedAnnouncement ? 'hidden lg:block' : 'block']"
        :section-id="selectedSection.id"
        @announcement-sent="scrollBottom"
      />
    </div>
    <div
      v-if="Object.keys(announcements).includes(selectedAnnouncement?.id?.toString())"
      class="flex w-full overflow-hidden overflow-y-auto border-r-2 lg:relative lg:w-10/12"
    >
      <comments-section
        :announcement="selectedAnnouncement"
        :section-id="selectedSectionId"
        @close-comment="closeComment"
      />
    </div>
    <emoji-picker />
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy';
import Announcement from '../components/announcement/announcement.vue';
import AddAnnouncementCard from '../components/announcement/add-announcement-card.vue';
import CommentsSection from '../components/comments-section/comments-section.vue';
import EmojiPicker from '../components/shared/emoji-picker';
import MoreUnreads from '../components/more-unreads';

export default {
  components: {
    Announcement,
    AddAnnouncementCard,
    CommentsSection,
    EmojiPicker,
    MoreUnreads,
  },
  props: {
    selectedSectionId: {
      type: String,
      default: null,
    },
    selectedAnnouncementId: {
      type: String,
      default: null,
    },
  },
  computed: {
    sections() {
      return this.$store.getters.sections || {};
    },
    announcements() {
      return this.$store.getters.announcements || {};
    },
    sortedAnnouncements() {
      return orderBy(
        this.selectedSection.announcements,
        [announcementId => new Date(this.announcements[announcementId].createdAt)],
        ['asc'],
      );
    },
    selectedSection() {
      return this.sections[this.selectedSectionId];
    },
    selectedSectionTitle() {
      return (this.selectedSection) ? this.selectedSection.title : '';
    },
    selectedAnnouncement() {
      return this.announcements[this.selectedAnnouncementId];
    },
    firstUnreadAnnouncementId() {
      const unreadIds = this.$store.state.document.unreadAnnouncements.map(unread => unread.announcementId);

      return this.sortedAnnouncements.find(announcementId => unreadIds.includes(announcementId.toString()));
    },
    hiddenAnnouncements() {
      return this.$store.state.document.hiddenAnnouncements || {};
    },
    notificationsTop() {
      return Object.keys(this.hiddenAnnouncements).find(
        (key) =>
          !this.announcementIsMuted(key) &&
          this.announcementHasUnreadComments(key) &&
          this.hiddenAnnouncements[key].isOnTop &&
          key !== this.selectedAnnouncementId,
      );
    },
    notificationsBottom() {
      return Object.keys(this.hiddenAnnouncements).find(
        (key) =>
          !this.announcementIsMuted(key) &&
          this.announcementHasUnreadComments(key) &&
          !this.hiddenAnnouncements[key].isOnTop &&
          key !== this.selectedAnnouncementId,
      );
    },
    topBound() {
      return document.getElementById('top-bound').getBoundingClientRect().top;
    },
    documentId() {
      return this.$store.state.document.document.id;
    },
    savedAnnouncements() {
      const sections = this.$store.state.document.savedAnnouncementsByDocument[this.documentId] || {};

      return sections[this.selectedSectionId] || [];
    },
  },
  methods: {
    lastRead(index) {
      const nextAnnouncementId = this.sortedAnnouncements[index + 1];

      return this.firstUnreadAnnouncementId && this.firstUnreadAnnouncementId === nextAnnouncementId;
    },
    changeAnnouncement(id) {
      const announcementRoute = `/sections/${this.selectedSectionId}/${id}`;
      if (this.$route.fullPath !== announcementRoute) this.$router.push(announcementRoute);
    },
    closeComment() {
      const announcementRoute = `/sections/${this.selectedSectionId}/`;
      if (this.$route.fullPath !== announcementRoute) this.$router.push(announcementRoute);
    },
    closeSection() {
      const sectionsRoute = window.location.href.split('sections')[0];

      window.location.href = sectionsRoute;
    },
    scrollBottom() {
      const container = this.$refs.announcementsContainer;
      this.$nextTick(() => {
        container.scrollTop = container.scrollHeight;
      });
    },
    onTop(topBound) {
      return topBound < this.topBound;
    },
    visibilityChanged(isVisible, entry, index) {
      const isOnTop = this.onTop(entry.boundingClientRect.top);
      this.$store.dispatch('toggleHiddenAnnouncement', { isVisible, index, isOnTop });
    },
    announcementHasUnreadComments(announcementId) {
      return this.$store.state.document.unreadComments.find(
        (u) => u.announcementId === announcementId,
      );
    },
    announcementIsMuted(announcementId) {
      return Object.keys(this.$store.state.document.mutedAnnouncements).find(
        (id) => id === announcementId,
      );
    },
    scrollToUnread(scrollTop) {
      const hiddenUnreadAnnouncements = Object.keys(this.hiddenAnnouncements).filter(
        (key) => this.announcementHasUnreadComments(key),
      );
      const announcementId = hiddenUnreadAnnouncements[scrollTop ? 0 : hiddenUnreadAnnouncements.length - 1];
      document.getElementById(`announcement-${announcementId}`).scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
    isSaved(id) {
      return this.savedAnnouncements.includes(id);
    },
  },
};
</script>
