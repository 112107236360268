/**
 * Gets all nodes of a given type from the json content
 * of a tiptap editor.
 * @param {Object} node - Json content of a tiptap editor.
 * @param {String} node.type - Type of node.
 * @param {Array?} node.content - Content of node.
 * @param {String} type - Type of nodes to get.
 * @returns {Object[]} Array of nodes of the given type.
 */
export default function getNodesOfType(node, type) {
  const nodes = [];

  if (node.type === type) {
    nodes.push(node);
  }

  if (node.content) {
    node.content.forEach(child => {
      nodes.push(...getNodesOfType(child, type));
    });
  }

  return nodes;
}
