<template>
  <v-tooltip>
    <mute-chip
      class="px-2 space-x-1.5"
      :active="!!userReacted"
      @click="toggleReaction"
    >
      <span
        class="w-4 h-4"
        :class="emojiClasses"
        :style="emojiStyle"
      >
        {{ emojiContent }}
      </span>
      <p>
        {{ reactionGroup.groupReactions.length }}
      </p>
    </mute-chip>
    <template #popper>
      <div class="flex flex-col items-center justify-center">
        <div class="flex items-center p-1 justify-center bg-white rounded">
          <span
            class="w-6 h-6 text-2xl leading-none"
            :class="emojiClasses"
            :style="emojiStyle"
          >
            {{ emojiContent }}
          </span>
        </div>
        <i18n-t
          tag="p"
          :keypath="`meetings.reactions.message.${userReacted ? 'userInvolved' : 'userNotInvolved'}`"
          scope="global"
          :plural="reactionGroup.groupReactions.length"
        >
          <span>{{ formattedReactionUsers }}</span>
          <span class="text-slate-400">
            {{ reactionGroup.emojiColons }}
          </span>
        </i18n-t>
      </div>
    </template>
  </v-tooltip>
</template>

<script>
import { useI18n } from 'vue-i18n';
import reactionsApi from '../../api/reactions';
import MuteChip from './mute-chip.vue';

export default {
  components: { MuteChip },
  props: {
    reactionGroup: {
      type: Object,
      required: true,
    },
    reactable: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  computed: {
    userReaction() {
      return this.reactionGroup.groupReactions.find(
        reaction => reaction.userId === this.$store.state.user.id,
      );
    },
    userReacted() {
      return !!this.userReaction;
    },
    isNative() {
      return this.reactionGroup.emojiType === 'native';
    },
    reactionUsers() {
      return this.reactionGroup.groupReactions.map((reaction) => {
        if (reaction.userId === this.$store.state.user.id) return this.t('meetings.reactions.you');

        return reaction.userUsername;
      });
    },
    formattedReactionUsers() {
      const singleReaction = this.reactionGroup.groupReactions.length === 1;
      const firstUsers = singleReaction ? this.reactionUsers : this.reactionUsers.slice(0, -1);

      return this.t('meetings.reactions.users', {
        count: this.reactionGroup.groupReactions.length,
        users: firstUsers.join(', '),
        lastUser: singleReaction ? undefined : this.reactionUsers[this.reactionUsers.length - 1],
      });
    },
    emojiContent() {
      if (this.isNative) return this.reactionGroup.content;

      return null;
    },
    emojiStyle() {
      if (this.isNative) return null;

      return { backgroundImage: `url(${this.reactionGroup.content})` };
    },
    emojiClasses() {
      if (this.isNative) return 'inline-block';

      return 'inline-block w-4 h-4 bg-contain bg-no-repeat bg-center';
    },
    reactableType() {
      return this.reactable.announcementId ? 'comment' : 'announcement';
    },
  },
  methods: {
    toggleReaction(event) {
      event.stopPropagation();
      if (this.userReacted) {
        this.deleteReaction(this.userReaction.id);
      } else {
        const emojiParams = {
          content: this.reactionGroup.content,
          emojiType: this.reactionGroup.emojiType,
          emojiColons: this.reactionGroup.emojiColons,
        };
        this.saveReaction(emojiParams);
      }
    },
    saveReaction(emojiParams) {
      reactionsApi.saveReaction(`${this.reactableType}s`, this.reactable.id, emojiParams);
    },
    deleteReaction(id) {
      reactionsApi.deleteReaction(id);
    },
  },
};
</script>
