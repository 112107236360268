<template>
  <div
    class="select-none"
    :class="sizeClasses.labelSizes"
  >
    <label
      v-if="!isSmall"
      class="absolute text-gray-400 transition-all duration-100 ease-in-out origin-top-left -translate-y-1/2 cursor-text"
      :class="[
        focusedInputClasses,
        { 'text-red-300': hasError }
      ]"
      @click="focus"
    >
      <span v-if="label">{{ label }}</span>
    </label>
  </div>
</template>

<script>
import { MuteInputClassesMixin } from './mixins/mute-input';

export default {
  mixins: [MuteInputClassesMixin],
  props: {
    label: { type: String, default: null },
    rules: { type: String, default: '' },
    size: { type: String, default: 'large' },
    focus: { type: Function, default: () => ({}) },
    focused: { type: Boolean, default: false },
    hasError: { type: Boolean, default: false },
  },
};
</script>
