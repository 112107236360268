<template>
  <div class="border-slate-50 p-1 md:p-3 bg-white border-t max-h-full w-auto relative mb-auto">
    <div
      class="flex flex-row min-w-0 mb-2"
      @keydown.enter.ctrl="sendAnnouncement"
      @keydown.enter.meta="sendAnnouncement"
    >
      <tip-tap-editor
        v-model="announcementBody"
        class="grow min-w-0"
        editor-class="min-h-[4rem]"
        :disabled="!announcementsAllowed"
        :disabled-indicator="!announcementsAllowed"
        resource="announcements"
        allow-indicator
        :placeholder="$t('meetings.announcement.placeholder')"
        bordered
        @json-input="setAnnouncementBodyJson"
      />
      <div class="flex flex-col self-stretch mt-8">
        <mute-button
          ref="sendAnnouncementBtn"
          class="flex-1 lg:w-auto max-h-20"
          :disabled="!announcementBody || !announcementsAllowed"
          icon-file-name="send.svg"
          @click="sendAnnouncement"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TipTapEditor from '../shared/tiptap/tiptap.vue';
import sectionsApi from '../../api/sections';
import getTags from '../shared/tiptap/helpers/getTags';

export default {
  components: {
    TipTapEditor,
  },
  props: {
    sectionId: {
      type: Number,
      required: true,
    },
  },
  emits: ['announcement-sent'],
  data() {
    return {
      announcementBody: '',
      announcementBodyJson: {},
      error: false,
      loading: false,
    };
  },
  computed: {
    documentConfiguration() {
      return this.$store.state.document.documentConfiguration;
    },
    announcementsAllowed() {
      return (this.documentConfiguration && this.documentConfiguration.announcementsAllowed) || false;
    },
  },
  methods: {
    sendAnnouncement() {
      this.$refs.sendAnnouncementBtn.$el.focus();
      if (!this.announcementBody || this.loading || !this.announcementsAllowed) return;
      const tags = getTags(this.announcementBodyJson);

      this.error = false;
      this.loading = true;
      sectionsApi
        .addAnnouncement(this.sectionId, { body: this.announcementBody, tags })
        .then(() => {
          this.loading = false;
          this.announcementBody = '';
          this.$emit('announcement-sent');
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
    setAnnouncementBodyJson(announcementBodyJson) {
      this.announcementBodyJson = announcementBodyJson;
    },
  },
};
</script>
