<template>
  <mute-chip
    ref="emojiPill"
    class="w-12"
    @click.stop="toggleEmojiPicker"
  >
    <inline-svg
      class="w-3.5 h-3.5 fill-current"
      :src="require('../../../assets/images/icons/emoji-icon.svg')"
    />
    <p>+</p>
  </mute-chip>
</template>

<script>
import MuteChip from './mute-chip.vue';

export default {
  components: { MuteChip },
  props: {
    type: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    pickerShown() {
      const { type, id, show } = this.$store.state.emoji;

      return show && type === this.type && id === this.id;
    },
  },
  methods: {
    toggleEmojiPicker() {
      if (this.pickerShown) {
        this.$store.dispatch('closeEmojiPicker');
      } else {
        const { left, top } = this.$refs.emojiPill.$el.getBoundingClientRect();
        this.$store.dispatch('openEmojiPicker', { type: this.type, id: this.id, left, top });
        document.querySelector('.emoji-mart-search input').focus();
      }
    },
  },
};
</script>
