<template>
  <div
    v-if="commentsCount > 0"
    class="flex items-center text-sm text-gray-600"
  >
    <inline-svg
      :src="require('../../../assets/images/icons/comment-icon.svg')"
      class="w-4 h-4 mr-1 fill-current"
    />
    <p>{{ commentsCount }} {{ pluralize($tc('meetings.comment.comment', 2).toLowerCase(), commentsCount) }}</p>
  </div>
</template>

<script>
import pluralize from 'pluralize';

export default {
  props: { announcement: { type: Object, required: true } },
  computed: {
    commentsCount() {
      if (!this.announcement.comments) return 0;

      return this.announcement.comments.length;
    },
  },
  methods: { pluralize },
};
</script>
