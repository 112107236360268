<template>
  <div class="flex flex-wrap items-center">
    <reaction-group
      v-for="(reactionGroup, index) in reactionGroups"
      :key="index"
      class="mr-2"
      :reaction-group="reactionGroup"
      :reactable="reactable"
    />
    <emoji-select
      :id="reactable.id"
      :type="type"
    />
  </div>
</template>

<script>
import filter from 'lodash/filter';
import pick from 'lodash/pick';
import groupBy from 'lodash/groupBy';
import minBy from 'lodash/minBy';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';
import ReactionGroup from '../../components/shared/reaction-group.vue';
import EmojiSelect from './emoji-select.vue';

export default {
  components: {
    ReactionGroup,
    EmojiSelect,
  },
  props: {
    reactable: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    reactions() {
      return this.$store.state.document.reactions;
    },
    groupedReactions() {
      const pertinentReactions = pick(this.reactions, this.reactable.reactions);
      const validReactions = filter(pertinentReactions, reaction => reaction.emojiColons);

      return groupBy(validReactions, reaction => {
        const [emoji, skin] = reaction.emojiColons.replace(/^:/, '').replace(/:$/, '').split('::');
        const storeEmoji = this.$store.getters.getEmoji(emoji, skin);

        return storeEmoji?.imageUrl || storeEmoji?.native || reaction.content;
      });
    },
    reactionGroups() {
      if (!this.reactable) return [];

      const groups = map(this.groupedReactions, (group, key) =>
        ({ content: key,
          emojiType: group[0].emojiType,
          emojiColons: group[0].emojiColons,
          groupReactions: group }));

      return orderBy(
        groups,
        [group => new Date(minBy(group.groupReactions,
          reaction => new Date(reaction.createdAt)).createdAt),
        ],
        ['asc'],
      );
    },
  },
};
</script>
