import { serialize } from 'object-to-formdata';
import api from './index';

export default {
  getCustomEmojis(teamId) {
    return api({
      method: 'get',
      url: `/api/internal/teams/${teamId}/custom_emojis`,
    });
  },
  createCustomEmoji(teamId, body) {
    return api({
      method: 'post',
      url: `/api/internal/teams/${teamId}/custom_emojis`,
      data: serialize(body),
    });
  },
};
