<template>
  <div class="absolute flex flex-row items-center justify-start bg-white border rounded-r-lg left-full">
    <button
      v-for="(variant, index) in skins"
      :key="variant.colons"
      class="p-2 border-l last:rounded-r-lg"
      :class="{ 'bg-blue-400': index === variantIndex }"
      @mouseover="$emit('hover-variant', index)"
      @click="$emit('select-variant', index)"
    >
      <span v-if="variant.native">{{ variant.native }}</span>
      <img
        v-else
        :src="variant.imageUrl"
        class="w-4"
      >
    </button>
  </div>
</template>

<script>
export default {
  props: {
    emoji: { type: Object, default: null },
    variantIndex: { type: Number, default: 0 },
  },
  emits: ['hover-variant', 'select-variant'],
  computed: {
    skins() {
      const { _skins: skins } = this.emoji || {};

      return skins || [];
    },
  },
};
</script>
