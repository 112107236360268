<template>
  <div class="flex flex-col w-56 overflow-hidden overflow-y-auto bg-white border rounded-lg max-h-40">
    <template v-if="items.length">
      <button
        v-for="(item, index) in items"
        :id="index"
        :key="item"
        class="flex flex-row items-center justify-start p-2 border-b"
        :class="{
          'bg-blue-400': index === selectedIndex,
          'rounded-t-lg': index === 0,
          'rounded-b-lg': index === items.length - 1,
        }"
        @click="selectItem(index)"
      >
        <span class="mr-2">
          #
        </span>
        <span v-text="item" />
      </button>
    </template>
    <div
      v-else
      class="p-2"
    >
      {{ noResult }}
    </div>
  </div>
</template>

<script>
import SuggestionMixin from '../suggestion/suggestionMixin';

export default {
  mixins: [SuggestionMixin],
  computed: {
    noResult() {
      return this.$t('editor.mention.noResult');
    },
  },
};
</script>
