import { createI18n } from 'vue-i18n';
import Locales from '../locales/locales.js';

const locale = window.localStorage.locale || window.navigator.language.split('-')[0];

const i18n = createI18n({
  locale,
  allowComposition: true,
  fallbackLocale: 'en',
  messages: Locales.messages,
});

export default i18n;
