<template>
  <bubble-menu
    v-show="editor.isActive('custom-image')"
    :editor="editor"
    :tippy-options="{ animation: false, placement: 'top-start' }"
    class="flex bg-white border border-gray-500 rounded-lg shadow-lg"
  >
    <mute-button
      v-for="(item, index) in sizes"
      :key="index"
      borderless
      size="sm"
      variant="white"
      class="px-2 py-1 hover:text-black hover:bg-gray-200"
      :class="[{
        'bg-gray-200': editor.isActive('custom-image', { size: item.type }),
        'border-l border-r': index > 0 && index < sizes.length - 1,
        'rounded-l-lg': index === 0,
        'rounded-r-lg': index === sizes.length - 1,
      }]"
      @click="setImageSize(item.type)"
    >
      {{ item.label }}
    </mute-button>
  </bubble-menu>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { BubbleMenu } from '@tiptap/vue-3';

export default {
  components: {
    BubbleMenu,
  },
  props: {
    editor: {
      type: Object,
      default: null,
    },
    disabled: { type: Boolean, default: false },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  computed: {
    sizes() {
      const sizes = [{ type: 'small' }, { type: 'medium' }, { type: 'large' }];

      return sizes.map((item) => ({
        label: this.t(`editor.image.size.${item.type}`),
        ...item,
      }));
    },
  },
  methods: {
    setImageSize(size) {
      this.editor.chain().focus().setImageSize(size)
        .run();
    },
  },
};
</script>
