<template>
  <transition name="fade">
    <div
      v-if="showError"
      class="p-2 text-red-500 bg-red-100 rounded-lg shadow"
    >
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    showError: { type: Boolean, default: false },
  },
};
</script>
