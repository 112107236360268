import api from './index';

export default {
  addComment(announcementId, body) {
    return api({
      method: 'post',
      url: `/api/internal/announcements/${announcementId}/comments`,
      data: {
        body,
      },
    });
  },
  removeAnnouncement(announcementId) {
    return api({
      method: 'delete',
      url: `/api/internal/announcements/${announcementId}`,
    });
  },
  editAnnouncement(announcementId, data) {
    return api({
      method: 'patch',
      url: `/api/internal/announcements/${announcementId}`,
      data,
    });
  },
};
