<template>
  <div
    class="absolute right-2 top-2 items-center h-6 px-2 space-x-1 text-gray-500"
  >
    <button
      v-for="item in items"
      :key="item.id"
      @click.stop="$emit('item-click', item.id)"
    >
      <inline-svg
        :src="item.iconSrc"
        :class="item.class"
        class="w-4 h-4 text-gray-500 fill-current"
      />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    items: { type: Array, required: true },
  },
  emits: ['item-click'],
};
</script>
