import HardBreak from '@tiptap/extension-hard-break';

export default HardBreak.extend({
  addKeyboardShortcuts() {
    return {
      'Mod-Enter': () => true,
      'Shift-Enter': () => this.editor.commands.setHardBreak(),
    };
  },
});
