import { decamelizeKeys } from 'humps';
import { getCurrentInstance } from 'vue';
import { useStore } from 'vuex';

function getCable() {
  const vueInstance = getCurrentInstance();

  return vueInstance.proxy.$cable;
}

export default function useBroadcastDocumentMessage() {
  const store = useStore();
  const $cable = getCable();

  return (data) => {
    const { _cable: cable } = $cable;
    if (cable.connection.disconnected) return;

    const { user, document } = store.state;
    const userId = user.id;
    const documentId = document.document.id;

    const payload = {
      id: userId,
      documentId,
      broadcastAction: 'update',
      ...data,
    };

    $cable.perform({
      channel: 'DocumentChannel',
      action: 'receive',
      data: decamelizeKeys(payload),
    });
  };
}
