import { setLocale } from 'yup';

setLocale({
  mixed: {
    required: 'mixed.required',
  },
  string: {
    username: 'string.username',
    email: 'string.email',
    url: 'string.url',
    min: 'string.min{${min}}',
  },
  array: {
    min: 'array.min{${min}}',
  },
  custom: {},
});
