<template>
  <div
    class="relative"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
  >
    <slot />
    <hover-bar
      v-if="hoverBarItems && !editing"
      :items="hoverBarItems"
      :class="(hovered ? 'flex' : 'flex lg:hidden')"
      @item-click="(itemId) => $emit('hover-bar-item-click', itemId)"
    />
    <base-modal
      v-if="[true, false].includes(isConfirmationModalOpen)"
      :title="$t('meetings.textEditor.confirmation')"
      :open="isConfirmationModalOpen"
      @close="closeRemoveModal"
    >
      <div>
        <p class="mb-4">
          {{ $t('meetings.textEditor.deleteWarning', { type: objectTypeText }) }}
        </p>
        <div class="flex justify-end">
          <mute-button
            variant="white"
            class="mr-4"
            @click="closeRemoveModal"
          >
            {{ $t('meetings.textEditor.cancel') }}
          </mute-button>
          <mute-button @click="confirmRemoval">
            {{ $t('meetings.textEditor.delete') }}
          </mute-button>
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import HoverBar from './hover-bar.vue';

export default {
  components: { HoverBar },
  props: {
    objectId: {
      type: Number,
      required: true,
    },
    variant: {
      type: String,
      required: true,
    },
    editing: {
      type: Boolean,
      default: false,
    },
    hoverBarItems: {
      type: Array,
      default: null,
    },
    isConfirmationModalOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['hover-bar-item-click', 'close-confirmation-modal', 'remove'],
  setup() {
    const { t } = useI18n();

    return { t };
  },
  data() {
    return {
      hovered: false,
    };
  },
  computed: {
    objectTypeText() {
      return this.variant === 'comment' ?
        this.t('meetings.comment.comment').toLowerCase() :
        this.t('meetings.announcement.announcement').toLowerCase();
    },
  },
  methods: {
    closeRemoveModal() {
      this.$emit('close-confirmation-modal');
    },
    confirmRemoval() {
      this.$emit('remove');
      this.closeRemoveModal();
    },
  },
};
</script>
