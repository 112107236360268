<template>
  <span
    class="flex items-center p-3 rounded shadow text-xs md:text-base"
    :class="{
      'text-green-500 bg-green-200': kind === 'success',
      'text-amber-600 bg-amber-200': kind === 'warning',
      'text-red-500 bg-red-200': kind === 'error',
      'text-purple-500 bg-purple-200': kind === 'info',
      'bg-opacity-25': transparent,
    }"
  >
    <inline-svg
      v-if="withIcon"
      :src="require(`assets/images/icons/${variantIcon}.svg`)"
      class="mr-2 fill-current "
    />
    <slot />
  </span>
</template>

<script>
export default {

  props: {
    kind: {
      type: String,
      required: true,
    },
    withIcon: {
      type: Boolean,
      default: true,
    },
    transparent: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    variantIcon() {
      const ICONS_NAME_BY_KIND = {
        success: 'check-icon',
        warning: 'warning',
        error: 'close-icon',
        info: 'info-icon',
      };

      return ICONS_NAME_BY_KIND[this.kind];
    },
  },
};
</script>
