<!-- eslint-disable tailwindcss/no-custom-classname -->
<template>
  <div
    ref="linkPopper"
    class="link-popper w-11/12 bg-white rounded-lg shadow-lg lg:w-1/2"
    @keydown.esc.stop="closePopper"
  >
    <div
      class="flex flex-row items-center justify-center p-2"
    >
      <div
        class="grow"
        @keydown.enter.stop="setLink"
      >
        <mute-input
          ref="input"
          v-model="linkValue"
          size="small"
          :label="$t('editor.link.input')"
        />
      </div>
      <div
        class="flex flex-row ml-2"
      >
        <mute-button
          class="p-1 border rounded-l-lg hover:text-black hover:bg-gray-200"
          variant="white"
          size="sm"
          borderless
          @click="setLink"
        >
          {{ $t('editor.link.set') }}
        </mute-button>
        <mute-button
          class="p-1 border rounded-r-lg hover:text-black hover:bg-gray-200"
          variant="white"
          size="sm"
          borderless
          @click="unsetLink"
        >
          {{ $t('editor.link.unset') }}
        </mute-button>
      </div>
    </div>
    <span
      class="arrow"
      data-popper-arrow
    />
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';

export default {
  props: {
    toolbarRef: { type: HTMLElement, default: null },
    editor: { type: Object, default: null },
  },
  data() {
    return {
      linkPopper: null,
      linkValue: '',
    };
  },
  beforeUnmount() {
    if (this.linkPopper) {
      this.linkPopper.destroy();
      this.linkPopper = null;
    }
  },
  methods: {
    mountLinkPopper() {
      const toolbar = this.toolbarRef;
      const linkPopper = this.$refs.linkPopper;

      this.linkPopper = createPopper(toolbar, linkPopper, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              // eslint-disable-next-line no-magic-numbers
              offset: [0, -20],
            },
          },
        ],
      });
    },
    openLinkPopper() {
      if (!this.linkPopper) this.mountLinkPopper();
      const linkPopper = this.$refs.linkPopper;
      this.linkValue = this.editor.getAttributes('link').href || '';
      linkPopper.setAttribute('data-show', true);
      this.$refs.input.focus();

      this.$nextTick(() => {
        this.linkPopper.update();
      });
    },
    closePopper() {
      if (!this.linkPopper) this.mountLinkPopper();
      const linkPopper = this.$refs.linkPopper;
      linkPopper.removeAttribute('data-show');

      this.$nextTick(() => {
        this.linkPopper.update();
      });
    },
    toggleLinkPopper() {
      const linkPopper = this.$refs.linkPopper;

      if (linkPopper['data-show']) {
        this.closePopper();
      } else {
        this.openLinkPopper();
      }
    },
    unsetLink() {
      this.editor.chain().focus().extendMarkRange('link')
        .unsetLink()
        .run();
    },
    setLink() {
      let url = this.linkValue;

      if (url) {
        if (!/^https?:\/\//.test(url)) {
          url = `https://${url}`;
        }

        this.editor.chain().focus().extendMarkRange('link')
          .setLink({ href: url, target: '_blank' })
          .run();
      } else {
        this.unsetLink();
      }

      this.linkValue = '';
      this.closePopper();
    },
  },
};
</script>

<style>
.link-popper {
  display: none;
}

.link-popper[data-show] {
  display: block;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.link-popper[data-popper-placement^='top'] > .arrow {
  bottom: -4px;
}

.link-popper[data-popper-placement^='bottom'] > .arrow {
  top: -4px;
}

.link-popper[data-popper-placement^='left'] > .arrow {
  right: -4px;
}

.link-popper[data-popper-placement^='right'] > .arrow {
  left: -4px;
}
</style>

