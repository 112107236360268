<template>
  <editable
    class="w-full border-b border-gray-100"
    variant="comment"
    :object-id="Number(comment.id)"
    :editing="editing"
    :hover-bar-items="actionBarElements"
    :is-confirmation-modal-open="isConfirmationModalOpen"
    @remove="removeComment"
    @hover-bar-item-click="handleActionClick"
    @close-confirmation-modal="isConfirmationModalOpen = false"
  >
    <separator
      v-if="isNew"
      :element-id="comment.id"
      @scroll-to-element="scrollHere"
    />
    <div class="flex p-3">
      <div class="flex flex-col flex-1 min-w-0 break-words">
        <div class="flex flex-row">
          <mute-avatar
            size="medium"
            class="self-start shrink-0 mr-2"
            :src="comment.author.avatar"
          />
          <div class="font-bold text-purple-500">
            {{ comment.author.username }}
          </div>
        </div>
        <editable-text
          class="mb-2 text-sm"
          :text="comment.body"
          :editing="editing"
          :content-type="'comment'"
          :content-id="comment.id"
          :author="comment.author"
          @confirm-edit="confirmCommentEdit"
          @cancel-edit="editing=false"
        />
        <reactions-section
          type="comments"
          :reactable="comment"
        />
      </div>
    </div>
  </editable>
</template>

<script>
import ReactionsSection from '../shared/reactions-section.vue';
import Editable from '../shared/editable.vue';
import EditableText from '../shared/editable-text.vue';
import commentsApi from '../../api/comments';
import Separator from '../shared/separator.vue';

export default {
  components: {
    ReactionsSection,
    Editable,
    EditableText,
    Separator,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    sectionId: {
      type: String,
      required: true,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editing: false,
      newComment: this.comment.body,
      hover: false,
      isConfirmationModalOpen: false,
    };
  },
  computed: {
    users() {
      return Object.values(this.$store.state.document.users).map((user) => user.username);
    },
    actionBarElements() {
      if (!this.isCurrentUserComment) return null;

      return [
        {
          id: 'edit',
          iconSrc: require('../../../assets/images/icons/edit.svg'),
        },
        {
          id: 'delete',
          iconSrc: require('../../../assets/images/icons/delete.svg'),
        },
      ];
    },
    isCurrentUserComment() {
      return this.comment.author.id === this.$store.state.user.id && !this.editing;
    },
  },
  methods: {
    scrollHere() {
      document.getElementById(`comment-${this.comment.id}`).scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    },
    removeComment() {
      commentsApi.removeComment(this.comment.id);
    },
    editComment() {
      this.editing = true;
    },
    confirmCommentEdit(newComment) {
      commentsApi.editComment(this.comment.id, newComment)
        .then(() => {
          this.editing = false;
        });
    },
    handleActionClick(actionId) {
      const actions = {
        edit: () => { this.editing = true; },
        delete: () => { this.isConfirmationModalOpen = true; },
      };

      return actions[actionId] && actions[actionId]();
    },
  },
};
</script>

<style lang="scss">
.w-full-18 {
  width: calc(100% - 4.5rem);
}
</style>
