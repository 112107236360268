import i18n from '../../../../plugins/i18n';

function getTooltip(button) {
  return i18n.global.t(`editor.${button}.tooltip`);
}

/**
 * Returns buttons to use in the toolbar.
 * @param {*} editor Tiptap editor instance
 * @param {object} config Required config object for some buttons
 * @param {object} config.link Link button config
 * @param {function} config.link.toggle Function to toggle link popup
 * @param {object} config.image Image button config
 * @param {function} config.image.onClick Function to trigger upload
 */
function getToolbarButtons(editor, { link, image }) {
  return [
    {
      bold: {
        iconFileName: 'format-bold.svg',
        onClick: () => editor.chain().focus().toggleBold()
          .run(),
        isActive: () => editor && editor.isActive('bold'),
        tooltip: `${getTooltip('bold')} (Ctrl / ⌘ + B)`,
      },
      italic: {
        iconFileName: 'format-italic.svg',
        onClick: () => editor.chain().focus().toggleItalic()
          .run(),
        isActive: () => editor && editor.isActive('italic'),
        tooltip: `${getTooltip('italic')} (Ctrl / ⌘ + I)`,
      },
      strike: {
        iconFileName: 'format-strike.svg',
        onClick: () => editor.chain().focus().toggleStrike()
          .run(),
        isActive: () => editor && editor.isActive('strike'),
        tooltip: `${getTooltip('strike')} (Ctrl / ⌘ + ⇧ + X)`,
      },
      link: {
        iconFileName: 'link-icon.svg',
        onClick: () => link.toggle(),
        isActive: () => editor && editor.isActive('link'),
        tooltip: getTooltip('link'),
      },
    },
    {
      title: {
        iconFileName: 'format-title.svg',
        onClick: () => editor.chain().focus().toggleHeading({ level: 1 })
          .run(),
        isActive: () => editor && editor.isActive('heading', { level: 1 }),
        tooltip: getTooltip('title'),
      },
      quote: {
        iconFileName: 'format-quote.svg',
        onClick: () => editor.chain().focus().toggleBlockquote()
          .run(),
        isActive: () => editor && editor.isActive('blockquote'),
        tooltip: `${getTooltip('quote')} (>)`,
      },
      code: {
        iconFileName: 'format-code.svg',
        onClick: () => editor.chain().focus().toggleCodeBlock()
          .run(),
        isActive: () => editor && editor.isActive('codeBlock'),
        tooltip: `${getTooltip('code')} (\`\`)`,
      },
      bulletList: {
        iconFileName: 'format-bullet-list.svg',
        onClick: () => editor.chain().focus().toggleBulletList()
          .run(),
        isActive: () => editor && editor.isActive('bulletList'),
        tooltip: `${getTooltip('bulletList')} (-)`,
      },
      orderedList: {
        iconFileName: 'format-numbered-list.svg',
        onClick: () => editor.chain().focus().toggleOrderedList()
          .run(),
        isActive: () => editor && editor.isActive('orderedList'),
        tooltip: `${getTooltip('orderedList')} (1.)`,
      },
      outdent: {
        iconFileName: 'format-indent-decrease.svg',
        onClick: () => editor.chain().focus().liftListItem('listItem')
          .run(),
        disabled: () => editor && !editor.can().liftListItem('listItem'),
        tooltip: `${getTooltip('outdent')} (⇧ + ↹)`,
      },
      indent: {
        iconFileName: 'format-indent-increase.svg',
        onClick: () => editor.chain().focus().sinkListItem('listItem')
          .run(),
        disabled: () => editor && !editor.can().sinkListItem('listItem'),
        tooltip: `${getTooltip('indent')} (↹)`,
      },
    },
    {
      attach: {
        iconFileName: 'attach-file-icon.svg',
        onClick: () => image.onClick(),
        disabled: () => image.disabled(),
        tooltip: getTooltip('attach'),
      },
    },
    {
      undo: {
        iconFileName: 'undo-icon.svg',
        onClick: () => editor.chain().focus().undo()
          .run(),
        tooltip: `${getTooltip('undo')} (Ctrl / ⌘ + Z)`,
      },
      redo: {
        iconFileName: 'redo-icon.svg',
        onClick: () => editor.chain().focus().redo()
          .run(),
        tooltip: `${getTooltip('undo')} (Ctrl / ⌘ + Y)`,
      },
    },
  ];
}

export default getToolbarButtons;
