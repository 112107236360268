import getNodesOfType from './getNodesOfType';

/**
 * Gets all tags from the json content of tiptap editor.
 * @param {object} jsonContent - Json content of a tiptap editor.
 * @returns {string[]} Array of unique tags
 */
export default function getTags(jsonContent) {
  const tags = getNodesOfType(jsonContent, 'tag');

  const tagSet = new Set(tags.map(({ attrs }) => attrs.id));

  return Array.from(tagSet);
}
