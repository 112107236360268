<template>
  <notifications
    position="top right"
    class="m-5 mt-32"
    :width="hasLargeScreen ? '40%' : 'auto'"
    :duration="10000"
  >
    <template
      #body="{ item, close }"
    >
      <state-change-content
        v-if="item.data.type === 'document-configuration'"
        v-bind="item.data"
        class="mb-2 rounded-lg"
        data-testId="notification-wrapper"
        @close="close"
      />
      <div
        v-else
        class="p-2 mb-2 border-2 rounded-lg"
        :class="colorClasses(item.data)"
        data-testId="notification-wrapper"
      >
        <user-content
          v-if="item.data.type === 'mention'"
          v-bind="item.data"
          @close="close"
        />
        <default-content
          v-else
          :text="item.text"
          @close="close"
        />
      </div>
    </template>
  </notifications>
</template>

<script>
import DefaultContent from './default-content.vue';
import UserContent from './user-content.vue';
import StateChangeContent from './state-change-content.vue';

export default {
  components: {
    UserContent,
    DefaultContent,
    StateChangeContent,
  },
  computed: {
    hasLargeScreen() {
      return this.$store.getters.hasLargeScreen;
    },
  },
  methods: {
    colorClasses(notificationData) {
      const colors = {
        mention: 'bg-white',
        red: 'bg-red-200 border-red-300 text-red-500',
        green: 'bg-green-200 border-green-300 text-green-500',
      };

      const type = notificationData.color || notificationData.type;

      return colors[type] || 'bg-white';
    },
  },
};
</script>
