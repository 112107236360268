/**
 * This provides the `focused` data property for vue components.
 * Also adds methods `focus` to set the focused state and
 * `blur` to clear the focused state.
 * Requires the component to have a ref called `input` to focus on.
 *
 * @mixin
 */
export const MuteInputFocusMixin = {
  props: {
    disabled: { type: Boolean, default: false },
  },
  emits: ['blur'],
  data() {
    return {
      focused: false,
    };
  },
  methods: {
    blur(event) {
      this.focused = false;
      this.$emit('blur', event);
    },
    focus() {
      if (this.disabled) return;
      this.focused = true;
      if (this.$refs.input) this.$refs.input.focus();
    },
  },
};

/**
 * This provide common classes for input components.
 *
 * @mixin
 */
export const MuteInputClassesMixin = {
  props: {
    size: { type: String, required: true },
    value: { type: [String, Object, Array, Boolean], default: null },
  },
  computed: {
    isSmall() {
      return this.size === 'small';
    },
    sizeClasses() {
      const mainContainerSizes = {
        small: 'h-8',
        medium: 'relative h-8 md:h-12',
        large: 'relative h-12 md:h-16',
      };

      const inputSizes = {
        small: 'px-1.5 py-1 text-sm',
        medium: 'absolute inset-0 px-1.5 md:px-2 py-1.5 md:py-3.5 text-sm',
        large: 'absolute inset-0 px-2 pt-5 pb-2 text-sm md:px-3 md:pt-7 md:pb-3 md:text-base',
      };

      const labelSizes = {
        small: 'pl-1.5',
        large: 'pl-2 md:pl-3',
      };

      return {
        mainContainerSizes: mainContainerSizes[this.size],
        inputSizes: inputSizes[this.size],
        labelSizes: labelSizes[this.size],
      };
    },
    focusedInputClasses() {
      const notFocusedSizes = {
        large: 'mt-6 md:mt-8',
      };

      const focusedSizes = {
        large: 'mt-4 scale-75 md:mt-6',
      };

      const sizes = this.focused || this.value ? focusedSizes : notFocusedSizes;

      return sizes[this.size] || sizes.large;
    },
    errorMessageClasses() {
      const errorMessageSizes = {
        small: 'ml-1.5',
        large: 'ml-2 md:ml-3',
      };

      return errorMessageSizes[this.size];
    },
  },
};
