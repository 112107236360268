<template>
  <div class="border-slate-50 p-1 md:p-3 bg-white border-t max-h-full w-auto relative mb-auto">
    <div
      class="flex grow min-w-0 mb-2"
      @keydown="sendCommentIfCtrlOrCmdEnter"
    >
      <tip-tap-editor
        v-model="commentBody"
        input-id="add-comment"
        class="grow min-w-0"
        :disabled-indicator="!commentsAllowed"
        resource="comments"
        allow-indicator
        :placeholder="$t('meetings.comment.placeholder')"
        bordered
      />
      <div class="flex flex-col self-stretch mt-8">
        <mute-button
          ref="sendCommentBtn"
          :disabled="!commentsAllowed || !commentBody"
          class="flex-1 lg:w-auto max-h-20"
          icon-file-name="send.svg"
          data-testId="comments-button"
          @click="sendComment"
        />
      </div>
    </div>
  </div>
</template>

<script>
import announcementsApi from '../../api/announcements';
import TipTapEditor from '../shared/tiptap/tiptap.vue';

// eslint-disable-next-line no-magic-numbers
const ENTER_KEY_CODE = 13;

export default {
  components: {
    TipTapEditor,
  },
  props: {
    thread: {
      type: Object,
      required: true,
    },
  },
  emits: ['comment-sent'],
  data() {
    return {
      commentBody: '',
      error: false,
    };
  },
  computed: {
    commentsAllowed() {
      return this.$store.getters.commentsAllowed();
    },
    usernames() {
      return Object.values(this.$store.state.document.users).map((user) => user.username);
    },
  },
  watch: {
    commentBody() {
      this.$store.dispatch(
        'setDraftComment',
        { sectionId: this.thread.sectionId, announcementId: this.thread.id, content: this.commentBody },
      );
    },
    thread() {
      this.setCommentBody();
    },
  },
  beforeMount() {
    this.setCommentBody();
  },
  methods: {
    sendCommentIfCtrlOrCmdEnter(event) {
      if (!this.commentsAllowed) {
        return;
      }

      if (event.keyCode === ENTER_KEY_CODE && (event.ctrlKey || event.metaKey)) {
        this.$refs.sendCommentBtn.$el.focus();
        this.sendComment();
      }
    },
    sendComment() {
      if (!this.commentsAllowed || !this.commentBody || this.loading) return;
      this.error = false;
      this.loading = true;
      announcementsApi.addComment(this.thread.id, this.commentBody)
        .then(() => {
          this.loading = false;
          this.commentBody = '';
          this.$emit('comment-sent');
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
    setCommentBody() {
      this.commentBody = this.$store.getters.draftComment(this.thread.id);
    },
  },
};
</script>
