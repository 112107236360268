import axios from 'axios';
import qs from 'qs';
import map from 'lodash/map';
import capitalize from 'lodash/capitalize';
import convertKeys from '../utils/case-converter';

const tokenEl = document && document.querySelector('meta[name="csrf-token"]');
const CSRFToken = tokenEl && tokenEl.getAttribute('content');

const api = axios.create({
  headers: {
    'X-CSRF-Token': CSRFToken,
  },
  paramsSerializer: (params) => qs.stringify(params),
});

api.interceptors.response.use(
  response => {
    if (response.data && response.headers['content-type'].match('application/json')) {
      response.data = convertKeys(response.data, 'camelize');
    }

    return response;
  },
  error => {
    if (error.response) {
      const { data } = error.response;
      switch (data.message) {
      case 'invalid_attributes':
        error.details = map(data.errors, (errorMessage, attribute) => capitalize(`${attribute} ${errorMessage}`));
        break;
      case 'server_error':
        error.details = [`Error interno del servidor: ${data.detail}`];
        break;
      default:
        break;
      }
    }

    return Promise.reject(error);
  },
);

api.interceptors.request.use(config => {
  const newConfig = { ...config };
  if (config.params) {
    newConfig.params = convertKeys(config.params, 'decamelize');
  }
  if (config.data) {
    newConfig.data = convertKeys(config.data, 'decamelize');
  }

  return newConfig;
});

export default api;
