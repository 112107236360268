<template>
  <div
    class="lg:relative grow w-screen bg-slate-50 overflow-y-auto"
  >
    <div
      v-if="resultsCount === 0"
      id="no-results-msg"
      class="flex h-full justify-center items-center"
    >
      <div
        class="text-gray-400 font-bold"
      >
        {{ $t('meetings.search.noResults') }}
      </div>
    </div>
    <div
      v-if="resultsCount !== 0"
      class="flex flex-col mt-40 lg:-mt-px lg:overflow-hidden"
    >
      <div
        id="results-count"
        class="text-gray-400 text-sm font-bold px-2 py-3 lg:py-4 w-full lg:fixed bg-slate-50"
      >
        {{ $t('meetings.search.resultsCount') + resultsCount }}
      </div>
      <div
        class="flex flex-col overflow-y-auto lg:py-8"
      >
        <div
          v-for="(announcements, key) in sectionsByTitle"
          :key="key"
        >
          <div
            class="text-green-500 text-xl font-semibold px-2 py-3"
          >
            {{ key }}
          </div>
          <div
            v-for="announcement in announcements"
            :key="announcement.id"
          >
            <announcement
              :id="`announcement-${announcement.id}`"
              class="justify-start"
              :announcement="announcement"
              :author="announcement.author"
              :text="announcement.body"
              :selected-section-id="announcement.sectionId.toString()"
              :is-saved="isSaved(announcement.id)"
              @change-announcement="goToAnnouncement(announcement.sectionId, announcement.id)"
            />
            <div
              v-for="commentId in searchResults[announcement.id]"
              :key="commentId"
              class="bg-white pl-4 cursor-pointer hover:bg-gray-100"
              @click="goToAnnouncement(announcement.sectionId, announcement.id)"
            >
              <comment
                :id="`comment-${commentId}`"
                :comment="commentsFromStore[commentId]"
                :section-id="announcement.sectionId.toString()"
                :is-new="false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Announcement from './announcement/announcement.vue';
import Comment from './comments-section/comment.vue';
import EntitiesApi from '../api/entities.js';

export default {
  components: { Announcement, Comment },
  data() {
    return {
      searchResults: [],
      resultsCount: 0,
    };
  },
  computed: {
    documentId() {
      return this.$store.state.document.document.id;
    },
    announcementsFromStore() {
      return this.$store.state.document.announcements || {};
    },
    queriedAnnouncements() {
      return Object.keys(this.searchResults).map(id => this.announcementsFromStore[id]);
    },
    commentsFromStore() {
      return this.$store.state.document.comments || {};
    },
    savedAnnouncements() {
      const sections = this.$store.state.document.savedAnnouncementsByDocument[this.documentId] || {};

      return Object.values(sections).flat() || [];
    },
    sectionsByTitle() {
      const sectionsByTitle = {};
      this.queriedAnnouncements.forEach((result) => {
        const title = this.getSectionNameById(result.sectionId);
        if (sectionsByTitle[title]) {
          sectionsByTitle[title].push(result);
        } else {
          sectionsByTitle[title] = [result];
        }
      });

      return sectionsByTitle;
    },
  },
  watch: {
    $route() {
      this.fetchResults();
    },
  },
  created() {
    this.$store.dispatch('toggleOnSearchPage', true);
  },
  mounted() {
    this.fetchResults();
  },
  beforeUnmount() {
    this.$store.dispatch('toggleOnSearchPage', false);
  },
  methods: {
    getSectionNameById(sectionId) {
      return this.$store.state.document.sections[sectionId].title;
    },
    goToAnnouncement(sectionId, announcementId) {
      this.$router.push(`/sections/${sectionId}/${announcementId}`);
    },
    async fetchResults() {
      if (this.$route.query.query) {
        await EntitiesApi.indexSearchResults(this.documentId, this.$route.query).then(
          (queryResult) => {
            if (queryResult.data.entities) {
              this.searchResults = this.parseResults(queryResult.data.entities);
              this.resultsCount = queryResult.data.entities.length;
            }
          },
        );
      } else {
        this.resultsCount = 0;
        this.searchResults = [];
      }
    },
    isSaved(id) {
      return this.savedAnnouncements.includes(id);
    },
    parseResults(results) {
      return results.reduce((acc, result) => {
        if (!!result.sectionId) {
          return { ...acc, [result.id]: [] };
        }
        if (!!result.announcementId) {
          return {
            ...acc, [result.announcementId]: [...(acc[result.announcementId] || []), result.id],
          };
        }

        return acc;
      }, {});
    },
  },
};
</script>
