import { mapValues, merge, omit } from 'lodash';

import { Editor } from '@tiptap/vue-3';

import Placeholder from '@tiptap/extension-placeholder';
import StarterKit from './extensions/starterkit';
import Heading from './extensions/heading';
import Link from './extensions/link';
import Mention from './extensions/mention';
import Tag from './extensions/tag';
import Emoji from './extensions/emoji';
import Image from './extensions/image';
import HardBreak from './extensions/hardBreak';

/**
 * Returns an array with the configured extensions, ommiting the ones that are not enabled.
 * @param {Object} configs - Object with the configuration of the extensions.
 * @param {Object} configs.Placeholder - Placeholder extension configuration.
 * @param {String} configs.Placeholder.placeholder - Placeholder text.
 * @param {Object} configs.Link - Link extension configuration.
 * @param {Boolean} configs.Link.openOnClick - Whether to enable link opening on click.
 * @param {Object} configs.Mention - Mention extension configuration.
 * @param {Object} configs.Mention.suggestion - Mention suggestion configuration.
 * @param {Object} configs.Tag - Tag extension configuration.
 * @param {Object} configs.Tag.suggestion - tag suggestion configuration.
 * @param {Object} configs.Emoji - Emoji extension configuration.
 * @param {Object} configs.Emoji.suggestion - emoji suggestion configuration.
 * @param {Function} configs.Emoji.getEmoji - function to get an emoji based on its name and skin.
 * @param {Object} configs.Emoji.emojiIndex - EmojiMart index.
 * @param {String[]} disabledExtensions - Array with the names of the disabled extensions.
 * @returns {Object[]} Array with the configured extensions.
 */
export function getTipTapExtensions(configs, disabledExtensions = []) {
  const extensions = {
    StarterKit,
    Heading,
    HardBreak,
    Placeholder,
    Link,
    Mention,
    Tag,
    Emoji,
    Image,
  };

  const configuredExtensions = mapValues(extensions, (extension, name) => extension.configure(configs[name] || {}));

  const filteredExtensions = Object.values(omit(configuredExtensions, disabledExtensions));

  return filteredExtensions;
}

/**
 * Returns the TipTap editor object
 * @param {String} content - Initial content of the editor.
 * @param {Boolean} enabled - Whether the editor is enabled or not.
 * @param {Object[]} extensions - Array with the configured extensions.
 * @param {Object} configs - Object with other editor configuration options.
 * @returns TipTap editor object
 */
export function getTipTapEditor(content, enabled, extensions, configs = {}) {
  let editorConfigs = {
    content,
    extensions,
    editable: enabled,
    onCreate: (props) => {
    // This is necesary because the suggestion with `#` conflicts with
    // markdown headings.
      const { state, view } = props.editor;

      if (state.plugins.length > 0) {
        const restOfPlugins = [];
        const suggestionPlugins = [];

        state.plugins.forEach((plugin) => {
          if (plugin.key.includes('tag')) {
            suggestionPlugins.push(plugin);
          } else {
            restOfPlugins.push(plugin);
          }
        });

        view.updateState(
          state.reconfigure({
            plugins: [...suggestionPlugins, ...restOfPlugins],
          }),
        );
      }
    },
  };

  editorConfigs = merge(editorConfigs, configs);

  return new Editor(editorConfigs);
}
