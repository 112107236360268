import axios from 'axios';

export default {
  downloadImg(url) {
    return axios({
      method: 'get',
      url,
      responseType: 'arraybuffer',
    });
  },
};
