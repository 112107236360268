export default {
  sessions: {
    continue: 'Continue',
    forgotPassword: 'Forgot password?',
    header: 'Welcome!',
    login: 'Log in',
    passwordRecovery: {
      email: {
        address: 'Associated email',
        message: 'Enter the email associated with your account. If it exists, we will send you a link to retrieve it.',
        send: 'Send recovery email',
      },
      message: 'Password recovery',
      newPassword: {
        confirmation: 'Confirm your new password',
        enterPassword: 'Enter your new password',
        new: 'New password',
        recover: 'Recover password',
      },
    },
    signUp: {
      almostThere: 'We are almost there!',
      endSignUp: 'Sign up',
      fields: {
        confirmPassword: 'Confirm password',
        email: 'Email address',
        fill: 'Fill in the following fields to sign in.',
        password: 'Password',
        userName: 'Username',
      },
      signUp: 'Sign up',
    },
    remember: 'Remember me?',
    silentMeetings: 'silent meetings',
    start: 'You may start by logging in or signing up',
  },
  templates: {
    title: 'Meetings',
    actions: {
      create: 'Create meeting',
    },
    topbar: {
      back: 'Back',
      new: 'Create new template',
      edit: 'Edit template',
    },
    index: {
      goToTodaysMute: "Go to today's mute",
      latestDocuments: {
        title: 'Mutes',
        isToday: 'Today!',
        noDocuments: 'There are no mutes related to this meeting yet.',
        createIfNoDocuments: 'You can create one {0}.',
        here: 'here',
        showPastDocuments: 'View list of past mutes',
      },
    },
    noTemplates: 'No templates could be found',
    noSelected: 'Select a template to preview',
    informativeMessage: {
      title: 'No meetings scheduled yet',
      subtitle: 'Create one using the button below',
    },
    preview: {
      edit: 'Edit',
      participants: 'Members',
      moderators: 'Moderators',
      showMore: 'Show more',
      showLess: 'Show less',
      sections: 'Sections',
      instructions: 'Instructions',
      information: {
        configuration: 'Configurations',
        access: {
          title: 'Access',
          data: ' - {access}, {participants} member{plural}',
          invite: 'Invitation',
          team: 'All team',
        },
      },
      configuration: {
        title: 'Initial configuration',
        announcements: 'Announcements',
        comments: 'Comments',
        readyStatus: 'Ready status',
      },
      delete: {
        cta: 'Delete',
        confirm: 'Are you sure? This action is permanent. Associated mutes to this template will not get affected.',
        error: 'Something went wrong creating the template 😰',
        message: 'Delete template',
        no: 'No, cancel',
        yes: 'Yes, delete template',
      },
      recurrence: 'It repeats every {weeks} weeks, on {day}s at {hour}',
      recurrent: {
        none: 'It does not repeat',
        singleWeek: 'Every {day} at {hour}',
        multipleWeeks: 'Every {weeks} weeks, on {day} at {hour}',
      },
    },
    form: {
      error: 'Something went wrong creating the template 😰',
      name: 'Template name',
      weekDays: {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
      },
      recurrent: 'Recurrent',
      recurrentDetails: {
        weeks: ' — Every {week} week(s)',
        dayDetail: 'On {day}, at {hour}',
      },
    },
  },
  teams: {
    administration: {
      confirmDeletion: {
        single: 'Are you sure you want to delete the user {value}?',
        multiple: 'Are you sure you want to delete these {value} users?',
        message: "Their announcements and comments will remain, but they will lose access to the team's resources.",
        back: 'No, go back',
        continue: 'Yes, delete',
        title: 'Delete member',
      },
      deleteMember: 'Delete member',
      delete: 'Delete',
      actions: 'Actions',
      users: 'Users',
      admin: 'Administrator',
      search: 'Search',
      selected: '{amount}  selected',
    },
    manage: 'manage',
    myTeams: 'My teams',
  },
  documents: {
    templates: 'Team templates',
    goToHome: 'Go to home',
    create: {
      create: 'Create mute',
      error: 'Something went wrong creating the mute 😰',
      fields: {
        add: '<div class="flex justify-between mx-4 text-xs"><p>+</p><p>Add</p></div>',
        title: 'Title',
        dateTime: 'Date and time',
        instructions: {
          label: 'Instructions',
          placeholder: 'Write the instructions here',
          info: `Describe how you want to organize your meeting here, for example:
          Write announcements for the first 20 minutes, then move on to the comment phase. Sessions
          will indicate X things and Y announcements`,
        },
        meetLink: 'Video meeting URL',
        access: {
          team: 'Public for the team',
          invite: 'Specify team members',
          label: 'Participants',
          select: 'Select the participants',
        },
        mastersOfCeremony: {
          label: 'Hosts',
          select: 'Select the hosts',
        },
        configurations: {
          commentsLabel: 'Allow comments',
          announcementsLabel: 'Allow announcements',
          readyStatusLabel: "Allow users to indicate when they're ready",
        },
        sections: 'Sections',
        sectionNumber: 'Section #{index}',
        viewMoreMasters: '<div class="flex justify-between mx-2 text-xs"><p>+</p><p>View more</p></div>',
      },
      configurations: 'What are the initials settings of your meeting?',
      configurationsInfo: `
        You can block announcements or comments from the start and then let the host activate them
        during the meeting. Also, you may specify if users will be able to inform that they are
        ready to the host
      `,
      access: 'Mute access policies',
      accessHint: `
        The "Public for the team" access means that all members may enter the mute.
        A new member will also be able to enter previous public mutes.
        If you remove someone from the team, he won't be able too see the mute again.
      `,
      mastersOfCeremony: 'Who will host the meeting?',
      masterOfCeremonyInfo: `Hosts are the moderators of the meeting. They can allow and restrict
                             comments or anouncements and create sections during a mute.`,
      message: 'Create mute',
      newMessage: 'New mute',
      templateMessage: 'From template',
      new: 'New mute',
      save: 'Save',
    },
    easyCreate: {
      back: 'Back',
      continue: 'Continue',
      first: {
        firstMuteInformation: `So you can get the most out of Mute on your first experience, we recommend you
        that the meeting that you are going to schedule satisfy the following features:`,
        meetingType: 'Recurring and internal meeting of your company or organization',
        meetingTypeExplanation: `Some examples of meeting that work are: metrics reviews,
        product planning or team meetings.`,
        participants: 'Between 3 y 20 participants',
        participantsExplanation: `Mute is made for any number of participants, but in the first meet
        the experience is better with this number.`,
      },
      fields: {
        add: '<div class="flex justify-between mx-2 text-xs"><p>+</p><p>Add</p></div>',
        dateTime: 'Date and time',
        sections: 'What secondary topics are you going to discuss in the meeting?',
        sectionsExplanation: `They are discussion categories, for example:
        "Recruiting", "Planification", "Discussion with client", etc `,
        sectionNumber: 'Subtopic #{index}',
        title: 'Which is the central topic of your meeting?',
        titleExplanation: 'Think of it as the central objetive, for example: "Production planification"',
        date: 'When do you want to have your meeting?',
      },
      registrationFields: {
        email: 'Your email',
        emailUse: 'We will use it to create your account',
        teamName: 'What is your team name?',
        teamUse: `It doesn't matter how many people there are in the team.
        It can be the name of your company or organization`,
        organizationLabel: 'Name of your company, team or organization',
      },
      invitation: {
        secondOption: 'You can share this link with your team to give them access. It will last 36 hours.',
        finish: 'Finish',
      },
      done: {
        ready: '¡Ready!',
        enterToMeeting: `You and your team can join to the meeting whenever you want!.
        We have created you an account with an autogenerated password, you can change it in your profile.`,
        goMeeting: 'Go to Mute',
        back: 'Go to my profile',
      },
      steps: {
        step1: 'Your first Mute',
        step2: 'Your team info',
        step3: 'Your meeting info',
        step4: 'Invite your team',
      },
    },
    information: 'About the mute',
    delete: {
      confirm: `Are you sure you want to delete {0}?
                All related comments and announcements will also be deleted.`,
      error: 'Something went wrong deleting the mute 😰',
      message: 'Delete mute',
      no: 'No',
      yes: 'Yes',
    },
    document: 'Mute | Mutes',
    dontExist: 'There are no mutes for your team yet',
    editTooltip: `
      You can't edit an ongoing or past mute`,
    edit: {
      edit: 'Edit "{name}"',
      error: 'Something went wrong editing the mute 😰',
      message: 'Edit mute',
    },
    filter: {
      ascending: 'Ascending',
      date: 'Date',
      descending: 'Descending',
      orderBy: 'Order by',
      orderedBy: 'Ordered by',
      title: 'Title',
    },
    invite: {
      byEmail: 'Invite by email',
      byLink: 'Invite by link',
      copy: 'Copied to clipboard!',
      emailAdd: 'Add email',
      emailMessage: 'Write the emails of the people you want to invite to the team.',
      emailSend: 'Invite',
      emailSent: 'Email sent!',
      emailError: 'An error occurred while sending the email',
      hour: 'hour | hours',
      invitation: 'Invitation to the team',
      link: 'Invitation link',
      message: 'Invite',
      modalMessage: 'Share this invitation link with your team members. The URL will expire in {0}',
    },
    members: 'Members ({amount})',
    myTeam: 'My team',
    firstDocument: 'This is your first Mute, click on it to access',
    table: {
      copy: 'Duplicate',
      date: 'Date',
      delete: 'Delete',
      edit: 'Edit',
      template: 'Template',
      templateTooltip: 'Create a template from this document',
      title: 'Title',
      view: 'Open',
    },
  },
  profile: {
    hello: 'Hello {name}!',
    logOut: 'Log out',
    memberships: {
      noTeams: 'You have no teams yet. To join one, you need to ask for an invitation link.',
    },
    myProfile: 'My profile',
    picture: {
      title: 'Profile picture',
      edit: 'Edit',
      crop: 'Crop and save',
      gravatar: 'Gravatar',
      info: 'If no file is uploaded, your picture will fallback to the {0} associated to your email.',
      upload: 'Upload',
      cancel: 'Cancel',
      deleteImage: 'Delete',
      delete: {
        info: `Are you sure you want to delete your profile picture? Whatever you have in
        {0} will be assigned as the default.`,
        confirmation: 'Yes, delete',
        title: 'Delete profile picture',
        cancel: 'No, go back',
      },
    },
    save: 'Save',
  },
  meetings: {
    announcement: {
      announcement: 'Announcement | Announcements',
      announce: 'Announce',
      new: 'New',
      placeholder: 'Write your announcement here...',
      error: 'Something went wrong creating the announcement. Please try again.',
      noAnnouncements: 'There are no announcements in this section yet.',
      title: 'Announcements',
      singleTitle: 'Announcement',
      saved: 'Saved announcements',
      noSavedAnnouncements: "You haven't saved any announcements yet",
      drafts: 'Drafts',
      noDrafts: 'You have no pending drafts',
      unreadMessages: 'Unread messages',
      noUnreadMessages: 'You have no pending messages',
      markSectionAsRead: 'Mark section as read',
      markAllMessagesAsRead: 'Mark all messages as read',
    },
    comment: {
      comment: 'Comment | Comments',
      placeholder: 'Write your comment here...',
      error: 'Something went wrong creating the comment. Please try again.',
      noComments: 'There are no comments in this announcement yet.',
      draftComment: 'Comments are not allowed right now, but you can write it and send it later.',
      title: 'Comments thread',
      singleTitle: 'Comment',
    },
    config: {
      announcements: 'Announcements',
      comments: 'Comments',
      experimental: 'Experimental',
      message: 'Settings',
      notifications: 'Notifications',
      unreadMessages: 'Unread messages',
      moreSettings: 'More settings',
      global: {
        title: 'Global settings',
        warning: 'These settings affect every member of the team',
      },
      personal: {
        title: 'Personal settings',
        warning: 'These settings only affect you',
      },
      allowed: {
        comments: 'Comments are allowed',
        announcements: 'Announcements are allowed',
        nothing: 'Announcements and comments are not allowed',
        both: 'Announcements and comments are allowed',
      },
      locked: {
        comments: 'Comments are locked',
        announcements: 'Announcemets are locked',
      },
      enabled: {
        comments: '<b>Comments</b> have been enabled',
        announcements: '<b>Announcements</b> have been enabled',
        indicator: 'Enabled',
      },
      disabled: {
        comments: '<b>Comments</b> have been disabled',
        announcements: '<b>Announcements</b> have been disabled',
        indicator: 'Disabled',
      },
      more: 'More',
    },
    document: {
      document: 'Mute',
      stats: 'Statistics',
      docFormat: 'Other options',
      downloadPdf: 'Download .pdf',
      downloadDocx: 'Download .docx',
      open: 'View as a document',
      noAnnouncements: 'No announcements on this section.',
      metrics: 'Metrics',
      export: 'Summary view',
    },
    instructions: {
      instructions: 'See instructions',
      muteInstructions: 'Mute Instructions',
      noInstructions: 'There are no specific instructions for this Mute',
    },
    goMeeting: 'Go to meeting',
    moreUnreads: 'More unreads',
    ready: {
      usersReady: ' user ready | users ready',
      ready: 'I\'m ready!',
    },
    textEditor: {
      cancel: 'Cancel',
      confirmation: 'Confirmation',
      delete: 'Delete',
      deleteWarning: 'Are you sure you want to delete this {type}? This action can\'t be undone.',
      saveChanges: 'Save changes',
    },
    unread: 'Unread',
    savedAnnouncements: 'Saved Announcements',
    draftComments: 'Draft Comments',
    customEmojis: {
      addEmoji: 'Add emoji',
      addEmojiExplanation: 'Your custom emoji will be available for all members of your team.',
      addEmojiSubExplanation: `You will find it in the custom tab of the emoji selector.
      (Clue: it is the one with the asterisk)`,
      addEmojiFirstStep: '1. Upload an image or a GIF',
      addEmojiUploadExplanationFirst: `It is better to use square images / GIFs that are
                                       less than 128 KB and have a transparent background.`,
      addEmojiUploadExplanationSecond: `Only images can be resized within Mute. We recommend you to use
                                        {0} in the meantime`,
      addEmojiUploadLink: 'this tool',
      addEmojiSecondStep: '2. Give it a name',
      addEmojiLabel: 'Emoji name',
      uploadImage: 'Upload',
      replaceImage: 'Replace',
      errors: {
        notSquareGif: "The GIF file doesn't have 1:1 aspect ratio",
        nameAlreadyExists: 'An emoji with this name already exists',
        nameContainsColonsOrSpaces: 'The name cannot contain colons or spaces',
        imageWrongFormat: 'Unsupported format. Image must be jpg, jpeg, png, svg or gif.',
        unknown: 'Unkown error, please try again',
      },
    },
    search: {
      resultsCount: 'Search results: ',
      noResults: 'No results found',
      searchPlaceholder: 'Search',
      error: 'Something went wrong searching. Please try again.',
      searchPrompt: 'Search for a word or phrase',
      filters: {
        announcements: 'Announcements',
        comments: 'Comments',
      },
    },
    filters: {
      filterMsg: 'Filter messages by:',
      mentions: 'Mentioned announcements',
      createdAnnouncements: 'Announcements created by me',
      createdComments: 'Comments created by me',
      tags: 'Tags',
    },
    reactions: {
      you: 'You',
      users: '{users} | {users} and {lastUser}',
      message: {
        userInvolved: '{0} reacted with {1}',
        userNotInvolved: '{0} reacted with {1}',
      },
    },
  },
  language: {
    english: {
      abbreviation: 'en',
      message: 'English',
    },
    message: 'Language',
    spanish: {
      abbreviation: 'es',
      message: 'Español',
    },
  },
  feedback: {
    emailSubject: 'Feedback from {user}',
    emailBody: 'Add what you want to suggest',
    navBarButtonMessage: 'Give us feedback',
    goToBoard: 'Go to the full board',
    loading: 'Loading...',
  },
  changelog: {
    title: 'Changelog',
    recentChangelogs: 'Recent changelogs',
    allChangelogs: 'All changelogs',
    explanation: {
      recent: 'Hi {0}, here you can check the last updates since you last used Mute.',
      all: 'Hi {0}, here you can check all the platform updates through time.',
    },
    close: 'Ok',
    noChangelogItems: 'There are no new updates to show.',
  },
  metrics: {
    announcements: {
      plural: 'announcements',
      empty: 'No announcements yet',
    },
    comments: {
      plural: 'comments',
      empty: 'No comments yet',
    },
    reactions: 'reactions',
    announcementBySection: 'announcements per section',
    commentsByAnnouncement: 'comments per announcement',
    totalAnnouncements: 'Total announcements',
    totalComments: 'Total comments',
    totalReactions: 'Total reactions',
    activity: {
      main: 'of member participated in this meeting',
      of: '{active} of {all}',
      secondary: 'if you consider',
    },
    totalWords: {
      main: 'words were written in the mute',
      secondary: 'considering announcements and comments',
    },
    totalWordsTime: {
      main: 'minutes would have taken this meeting if it had not been silent',
      secondary: 'estimation considering all words in announcements and comments',
    },
    title: 'Mute metrics',
  },
  sections: {
    addSection: 'New Section',
    add: 'Add',
    title: 'Sections',
    splash: 'Click one section to start',
  },
  landing: {
    navbar: {
      signIn: 'Log in',
    },
    hero: {
      title: 'Meetings that give back time and focus to your team',
      body: `
        With Mute you'll have meetings that are short and reproducible in complete silence.
        Everyone in your team will be able to portray their opinions and it will be easy to delegate
        communication responsibilities, allowing you to improve your meeting over time.
      `,
      alreadyRegistered: 'If your team is using Mute, {0}',
      signUp: 'sign up',
    },
    cta: {
      buttonLabel: 'Try it!',
      emailPlaceholder: "email{'@'}example.com",
    },
    companies: {
      header: 'These incredible teams are meeting in Mute',
    },
    features: [
      {
        header: 'Short and concise meetings',
        body: [
          `Have you noticed that when you talk you take up the space of the meeting and the rest must wait
          to communicate something else? In Mute, we make meetings in silence so that many conversations can
          take place at the same time.`,
          `We also help you define the topics of interest that you want to discuss prior to your meeting,
          so that you stick to them and avoid talking about things that you did not plan.`,
        ],
      },
      {
        header: 'Set up once and improve it over time',
        body: [
          `Many meetings are naturally recurrent, and in Mute we know that. We give you the tools to
          create the meeting once and forget about managing it afterwards.`,
          `Nevertheless, you have a space to iterate it: we help you focus on improving aspects that
          you did not identify before because you were worried about the time.`,
        ],
      },
      {
        header: 'Catch up easily',
        body: [
          'Have you gone on vacation or missed some meetings and need to catch up?',
          `By writing, you have an exact record of all the meetings that have happened.
          If you missed any, you can see it exactly as it happened, without distortions.`,
        ],
      },
      {
        header: "Have everyone's opinion",
        body: [
          `In all meetings there are people who, by personality, tend to participate more.
          With Mute everyone has the same opportunity to deliver their opinion and you will see an increase
          in the participation of your meetings.`,
          `Furthermore, we know that sometimes a 👍 is enough, and that's why you can use reactions during
          your meeting to communicate only what's needed.`,
        ],
      },
      {
        header: 'Delegate responsibilities',
        body: [
          `Forget about having only one person talk in the meeting or dividing it into several sub-meetings
          because it takes too long.`,
          `With Mute, we enable you to delegate the responsibilities of communicating, avoiding that you
          sub-divide your meeting or that one person monopolizes all the information and communicates it to
          the rest.`,
          'You don\'t have to have in five meetings what you could have in one.',
        ],
      },
    ],
    preFooter: {
      title: 'Have your new meeting today!',
    },
    footer: {
      allRightsReserved: 'Platanus SpA.',
      mute: {
        team: 'Team',
        signIn: 'Log in',
      },
      contact: {
        title: 'Any questions?',
        text: 'Write to us at',
      },
    },
  },
  editor: {
    bold: {
      tooltip: 'Bold',
    },
    italic: {
      tooltip: 'Italic',
    },
    strike: {
      tooltip: 'Strikethrough',
    },
    link: {
      input: 'Enter a url...',
      set: 'Link',
      unset: 'Unlink',
      tooltip: 'Link',
    },
    title: {
      tooltip: 'Title',
    },
    quote: {
      tooltip: 'Quote',
    },
    code: {
      tooltip: 'Code',
    },
    bulletList: {
      tooltip: 'Bullet List',
    },
    orderedList: {
      tooltip: 'Ordered List',
    },
    outdent: {
      tooltip: 'Outdent',
    },
    indent: {
      tooltip: 'Indent',
    },
    attach: {
      tooltip: 'Insert Image',
    },
    undo: {
      tooltip: 'Undo',
    },
    redo: {
      tooltip: 'Redo',
    },
    mention: {
      noResult: 'No results',
    },
    image: {
      size: {
        large: 'Large',
        medium: 'Medium',
        small: 'Small',
      },
      typeError: 'Only images can be uploaded.',
      sizeError: 'The image is too big.',
      uploadError: 'Something went wrong. Please try again later.',
    },
  },
  yup: {
    string: {
      email: 'Must be an email address',
      url: 'Must be a valid URL',
      min: 'Must have at least {argument} letters',
    },
    array: {
      min: 'Must have at least {argument} element(s)',
    },
    mixed: {
      required: 'This field is required',
    },
    custom: {
      username: 'You may only use letters, numbers, _ or .',
      passwordMatch: 'Passwords don\'t match',
    },
  },
  baseSelect: {
    defaultPlaceholder: 'Select one choice',
  },
  sidebar: {
    title: 'Mute',
    meetings: 'Meetings',
    users: 'Users',
    integrations: 'Integrations',
    profile: 'Profile',
    new: 'New',
  },
  user: {
    fields: {
      email: 'Email',
      userName: 'Username',
      password: 'Password',
      memberships: 'Default team',
      passwordConfirmation: 'Confirm your password',
    },
    errors: {
      update: "We couldn't update your info",
    },
    picture: {
      edit: 'Edit picture',
      delete: 'Delete picture',
    },
    profile: 'Profile',
    editPassword: 'Change your password',
    editData: 'Basic user data',
    signOut: 'Sign out',
    editProfile: 'Edit user',
    editPicture: 'Edit profile picture',
  },
  actions: {
    save: 'Save',
    delete: 'Delete',
    edit: 'Edit',
    cancel: 'Cancel',
    continue: 'Continue',
    ok: 'Got it',
  },
  errors: {
    default: {
      title: 'The changes were not saved',
      subtitle: 'You could try again later.',
    },
  },
  date: {
    formats: {
      long: 'MMMM Do, YYYY',
    },
  },
  integrations: {
    title: 'Integrations',
    beta: 'beta',
    slack: {
      title: 'Slack',
      subtitle: 'Import and/or update your Slack workspace emojis in Mute.',
      action: 'Import / update',
      success: `Your emojis are on the way! In a few minutes you will be able to see them updated in Mute.
      If after 5 minutes you're unable to see them, please contact us via chat.`,
      error: 'The integration with Slack failed. Contact us via chat or try again later.',
      noteLabel: 'Please note that:',
      notes: [
        `The import process will create the emojis that don't exist in your Mute team and update the
        existing ones with the image source from Slack. The ones that exist in Mute but not in Slack will
        remain untouched.`,
        `As a beta feature, Mute doesn't support realtime sync yet, so if any new emojis are added to your
        Slack workspace you'll need to import them again by clicking the button.`,
        `You might be asked to grant Mute access to your Slack workspace. After it's done, please come back
        and press the button again, only after that the import process will start.`,
      ],
    },
    feedback: {
      header: 'Have any integration ideas? Enjoying the current ones? Something not working as expected?',
      subheader: 'Please, {0} We treasure your feedback',
      callToAction: 'let us know!',
      channels: {
        email: 'Write us an email',
        slack: 'Contact us through Slack',
        meeting: 'Book a meeting with us',
        chat: 'Chat with us',
      },
    },
  },
};
