<template>
  <div class="flex flex-col w-screen bg-white lg:w-full lg:top-0">
    <div class="flex flex-row justify-between items-center w-screen p-2 bg-slate-100 text-xs font-bold text-gray-400 border-b border-gray-200 max-h-full z-0 lg:w-auto relative top-0">
      {{ $t('meetings.comment.title') }} "{{ selectedSectionTitle }}"
      <inline-svg
        class="text-gray-400 cursor-pointer fill-current hover:text-gray-600 -my-2"
        :src="require('assets/images/icons/close-icon.svg')"
        @click="closeComment"
      />
    </div>
    <div
      ref="commentsContainer"
      class="flex flex-col items-center grow w-full overflow-hidden overflow-y-auto text-sm lg:mt-0 lg:text-base"
    >
      <thread-announcement :announcement="announcement" />
      <thread
        :thread="announcement"
        :section-id="sectionId"
        @comment-sent="scrollBottom"
      />
      <p
        v-if="announcement.comments.length == 0"
        class="flex items-center justify-center w-full h-1/2 p-4 text-gray-600"
      >
        {{ $t('meetings.comment.noComments') }}
      </p>
    </div>
    <add-comment-card
      :thread="announcement"
      @comment-sent="scrollBottom"
    />
  </div>
</template>

<script>
import Thread from './thread.vue';
import announcementsApi from '../../api/announcements';
import AddCommentCard from '../comments-section/add-comment-card';
import ThreadAnnouncement from './thread-announcement.vue';

export default {
  components: {
    Thread,
    AddCommentCard,
    ThreadAnnouncement,
  },
  props: {
    announcement: {
      type: Object,
      required: true,
    },
    sectionId: {
      type: String,
      required: true,
    },
  },
  emits: ['close-comment'],
  data() {
    return {
      editing: false,
    };
  },
  computed: {
    reactions() {
      return this.$store.state.document.reactions;
    },
    sections() {
      return this.$store.state.document.sections || {};
    },
    selectedSection() {
      return this.sections[this.selectedSectionId];
    },
    selectedSectionTitle() {
      return (this.selectedSection) ? this.selectedSection.title : '';
    },
    selectedSectionId() {
      return this.$route.params.selectedSectionId;
    },
  },
  methods: {
    removeAnnouncement() {
      announcementsApi.removeAnnouncement(this.announcement.id);
    },
    confirmAnnouncementEdit(newAnnouncement) {
      announcementsApi.editAnnouncement(this.announcement.id, newAnnouncement).then(() => {
        this.editing = false;
      });
    },
    scrollBottom() {
      const container = this.$refs.commentsContainer;
      this.$nextTick(() => {
        container.scrollTop = container.scrollHeight;
      });
    },
    closeComment() {
      this.$emit('close-comment');
    },
  },
};
</script>
