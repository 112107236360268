import { InputRule, PasteRule } from '@tiptap/core';
import Mention from '@tiptap/extension-mention';

const mentionRegex = /@([\w._-]+)/g;

export default Mention.extend({
  addInputRules() {
    return [
      new InputRule({
        find: RegExp(`${mentionRegex.source} $`),
        handler: ({ state, range, match }) => {
          const [, pastedUsername] = match;
          const similarUsers = this.options.suggestion.items({ query: pastedUsername });
          const matchedUser = similarUsers.find((user) => user.username === pastedUsername);
          if (!matchedUser) return;
          const { tr } = state;
          tr.replaceWith(
            range.from,
            range.to,
            this.type.create({ id: matchedUser.username, label: matchedUser.username }),
          ).insertText(' ');
        },
      }),
    ];
  },
  addPasteRules() {
    return [
      new PasteRule({
        find: mentionRegex,
        handler: ({ state, range, match }) => {
          const [, pastedUsername] = match;
          const similarUsers = this.options.suggestion.items({ query: pastedUsername });
          const matchedUser = similarUsers.find((user) => user.username === pastedUsername);
          if (!matchedUser) return;
          const { tr } = state;
          tr.replaceWith(
            range.from,
            range.to,
            this.type.create({ id: matchedUser.username, label: matchedUser.username }),
          );
        },
      }),
    ];
  },
}).configure({
  HTMLAttributes: {
    class: 'text-green-500 font-bold',
  },
});
