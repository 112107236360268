export default {
  sessions: {
    continue: 'Continuar',
    forgotPassword: 'Olvidé mi contraseña',
    header: '¡Bienvenido!',
    login: 'Iniciar sesión',
    passwordRecovery: {
      email: {
        address: 'Correo asociado',
        message: 'Indica a qué correo está asociado tu cuenta. Si existe, te enviaremos un link para recuperarla.',
        send: 'Enviar correo de recuperación',
      },
      message: 'Recuperar contraseña',
      newPassword: {
        confirmation: 'Confirmación de nueva contraseña',
        enterPassword: 'Ingresa tu nueva contraseña',
        new: 'Nueva contraseña',
        recover: 'Recuperar contraseña',
      },
    },
    signUp: {
      almostThere: '¡Estamos casi!',
      endSignUp: 'Registrarse',
      fields: {
        confirmPassword: 'Confirmar contraseña',
        email: 'Correo',
        fill: 'Rellena tus datos y podrás ingresar.',
        password: 'Contraseña',
        userName: 'Nombre de usuario',
      },
      signUp: 'Registro',
    },
    remember: '¿Recordarme?',
    silentMeetings: 'reuniones silenciosas',
    start: 'Puedes empezar por ingresar o registrarte',
  },
  templates: {
    title: 'Reuniones',
    actions: {
      create: 'Crear reunión',
    },
    topbar: {
      back: 'Volver',
      new: 'Crear nueva plantilla',
      edit: 'Editar plantilla',
    },
    index: {
      goToTodaysMute: 'Ir al mute de hoy',
      latestDocuments: {
        title: 'Mutes',
        isToday: '¡Hoy!',
        noDocuments: 'Aún no hay mutes asociados a esta reunión.',
        createIfNoDocuments: 'Puedes crear uno {0}.',
        here: 'aquí',
        showPastDocuments: 'Ver listado de mutes pasados',
      },
    },
    noTemplates: 'No se encontraron plantillas',
    noSelected: 'Selecciona una plantilla para ver el detalle',
    informativeMessage: {
      title: 'Todavía no tienes reuniones',
      subtitle: 'Puedes crear una con el siguiente botón',
    },
    preview: {
      edit: 'Editar',
      participants: 'Participantes',
      moderators: 'Moderadores',
      showMore: 'Ver más',
      showLess: 'Ver menos',
      sections: 'Temas',
      instructions: 'Instrucciones',
      information: {
        configuration: 'Configuraciones',
        access: {
          title: 'Acceso',
          data: ' - {access}, {participants} persona{plural}',
          invite: 'Por invitación',
          team: 'Todo el equipo',
        },
      },
      configuration: {
        title: 'Configuración inicial',
        announcements: 'Titulares',
        comments: 'Comentarios',
        readyStatus: 'Marcar como listo',
      },
      delete: {
        cta: 'Eliminar',
        confirm: '¿Estás seguro? Esta acción es irreversible. Los mutes asociados a la plantilla no sufrirán cambios.',
        error: 'Algo pasó al tratar de borrar la plantilla 😰',
        message: 'Eliminar plantilla',
        no: 'No, volver',
        yes: 'Sí, eliminar plantilla',
      },
      recurrence: 'Se repite cada {weeks} semana{plural}, los {day} a las {hour}',
      recurrent: {
        none: 'No se repite',
        singleWeek: 'Todos los {day} a las {hour}',
        multipleWeeks: 'Cada {weeks} semanas, los {day} a las {hour}',
      },
    },
    form: {
      error: 'Algo pasó al tratar de crear la plantilla 😰',
      name: 'Nombre de la plantilla',
      weekDays: {
        0: 'Domingo',
        1: 'Lunes',
        2: 'Martes',
        3: 'Miércoles',
        4: 'Jueves',
        5: 'Viernes',
        6: 'Sábado',
      },
      recurrent: 'Es recurrente',
      recurrentDetails: {
        weeks: ' — Cada {week} semana(s)',
        dayDetail: 'Los días {day}, a las {hour}',
      },
    },
  },
  teams: {
    administration: {
      confirmDeletion: {
        single: '¿Estás seguro que quieres eliminar al usuario {value}?',
        multiple: '¿Estás seguro que quieres eliminar a estos {value} usuarios?',
        message: 'Sus anuncios y comentarios permanecerán, pero no podrá tener acceso a los recursos del equipo.',
        back: 'No, volver',
        continue: 'Sí, eliminar',
        title: 'Eliminar miembro',
      },
      delete: 'Eliminar',
      actions: 'Acciones',
      users: 'Usuarios',
      admin: 'Administrador',
      search: 'Buscar',
      selected: '{amount} seleccionados',
    },
    manage: 'administrar',
    myTeams: 'Mis equipos',
  },
  documents: {
    templates: 'Plantillas del equipo',
    goToHome: 'Ir al inicio',
    create: {
      create: 'Crear Mute',
      error: 'Algo pasó al tratar de crear el mute 😰',
      fields: {
        add: '<div class="flex justify-between mx-2 text-xs"><p>+</p><p>Añadir</p></div>',
        title: 'Título',
        dateTime: 'Fecha y hora',
        instructions: {
          label: 'Instrucciones',
          placeholder: 'Escribe las instrucciones de tu reunión aquí ...',
          info: `Describe cómo quieres organizar tu reunión acá por ejemplo:
          Escribir anuncios los primeros 20 minutos, luego pasar a la fase de comentarios.
          Las sesiones indicarán X cosas y los anuncios Y.`,
        },
        meetLink: 'URL de la videollamada',
        access: {
          team: 'Público para todo el equipo',
          invite: 'Especificar miembros del equipo',
          label: 'Participantes',
          select: 'Seleccionar participantes',
        },
        mastersOfCeremony: {
          label: 'Maestros/as de ceremonia',
          select: 'Seleccionar maestros/as de ceremonia',
        },
        configurations: {
          commentsLabel: 'Permitir comentarios',
          announcementsLabel: 'Permitir anuncios',
          readyStatusLabel: 'Permitir a usuarios marcar si están listos',
        },
        sections: 'Secciones',
        sectionNumber: 'Sección #{index}',
      },
      configurations: '¿Cuáles son las configuraciones iniciales de tu reunión?',
      configurationsInfo: `
        Puedes bloquear anuncios o comentarios desde un principio en tu reunión y luego
        activarlos durante el transcurso de esta. También puedes permitirle a los usuarios
        indicar si están listos o no.
      `,
      access: 'Políticas de acceso del mute',
      accessHint: `
        Que el acceso sea "Público para el equipo" significa que todos los miembros del
        equipo podrán ver el Mute. Un nuevo miembro podrá ver a todos los mutes que sean públicos.
        Si eliminas a alguien de tu equipo, no podrá volver a acceder.
      `,
      mastersOfCeremony: '¿Quiénes son maestros/as de ceremonia?',
      masterOfCeremonyInfo: `Los maestros/as de ceremonia asumen el rol de moderadores. Pueden
                             activar y desactivar comentarios o anuncios y agregar secciones
                             en un mute en curso.`,
      message: 'Crear mute',
      newMessage: 'Nuevo mute',
      templateMessage: 'Desde plantilla',
      new: 'Nuevo mute',
      save: 'Guardar Mute',
    },
    easyCreate: {
      back: 'Volver',
      continue: 'Continuar',
      first: {
        firstMuteInformation: `Para que puedas aprovechar al máximo Mute en tu primera experiencia
      te recomendamos que la reunión que vayas a agendar cumpla con las siguientes características:`,
        meetingType: 'Reunión recurrente e interna de tu empresa u organización',
        meetingTypeExplanation: `Algunos ejemplos de reuniones que funcionan son: revisión de métricas,
        planificación de producto o reunion de equipos de trabajo.`,
        participants: 'Entre 3 y 20 participantes',
        participantsExplanation: `Mute está hecho para cualquier cantidad de personas, pero en la primera
        experiencia es mejor cuando se tienen estos números.`,
      },
      fields: {
        add: '<div class="flex justify-between mx-2 text-xs"><p>+</p><p>Añadir</p></div>',
        dateTime: 'Fecha y hora',
        sections: '¿Qué temas secundarios van a discutir en la reunión?',
        sectionsExplanation: `Son categorías de discusión,
        por ejemplo: "Reclutamiento", "Planificación", "Discusión con cliente", etc `,
        sectionNumber: 'Subtema #{index}',
        title: '¿Cuál es el tema central de tu reunión?',
        titleExplanation: 'Piénsalo como el objetivo central, por ejemplo: "Planificación de producción"',
        date: '¿Cuándo quieres tener tu reunión?',
      },
      registrationFields: {
        email: 'Tu correo electrónico',
        emailUse: 'Lo usaremos para crear tu cuenta',
        teamName: '¿Cuál es el nombre de tu equipo?',
        teamUse: 'No importa la cantidad de personas que lo integren. Puede ser el nombre de tu empresa u organización',
        organizationLabel: 'Nombre de tu empresa, equipo u organización',
      },
      invitation: {
        secondOption: 'Puedes compatir este link para que tu equipo tenga acceso. Este durará 36 horas.',
        finish: 'Terminar',
      },
      done: {
        ready: '¡Listo!',
        enterToMeeting: `¡Tú y tu equipo pueden entrar a la reunión cuando quieran!.
        Te hemos creado una cuenta con una contraseña autogenerada, puedes cambiarla en tu perfil.`,
        goMeeting: 'Ir al Mute',
        back: 'Ir al mi perfil',
      },
      steps: {
        step1: 'Tu primer Mute',
        step2: 'Datos de tu equipo',
        step3: 'Datos de tu reunión',
        step4: 'Invitación a tu equipo',
      },
    },
    information: 'Datos del mute',
    delete: {
      confirm: `¿Estás seguro que quieres eliminar el mute {0}?
                Se eliminarán todos los anuncios y comentarios asociados.`,
      error: 'Algo pasó al tratar de borrar el mute 😰',
      message: 'Eliminar mute',
      no: 'No',
      yes: 'Sí',
    },
    document: 'Mute | Mutes',
    dontExist: 'Aún no existen reuniones para tu equipo',
    editTooltip: 'No puedes editar una reunión que ya pasó o que está en curso',
    edit: {
      edit: 'Editar "{name}"',
      error: 'Algo pasó al tratar de editar el mute 😰',
      message: 'Editar mute',
    },
    filter: {
      ascending: 'Ascendente',
      date: 'Fecha',
      descending: 'Descendente',
      orderBy: 'Ordenar por',
      orderedBy: 'Ordenado por',
      title: 'Nombre',
    },
    invite: {
      byEmail: 'Invitación por correo',
      byLink: 'Invitación por link',
      copy: '¡Copiado!',
      emailAdd: 'Agregar correo',
      emailMessage: 'Escribe los correos de las personas que quieres invitar al equipo.',
      emailSend: 'Invitar',
      emailSent: '¡Correo enviado!',
      emailError: 'Ocurrió un error al enviar el correo',
      hour: 'hora | horas',
      invitation: 'Invitación al equipo',
      link: 'Link de invitación',
      message: 'Invitar',
      modalMessage: 'Usa este link para invitar a más miembros de tu equipo. Expirará en {0}',
    },
    members: 'Miembros ({amount})',
    myTeam: 'Mi equipo',
    firstDocument: 'Este es tu primer Mute, haz click en él para acceder.',
    table: {
      copy: 'Duplicar',
      date: 'Fecha',
      delete: 'Borrar',
      edit: 'Editar',
      template: 'Plantilla',
      templateTooltip: 'Crear una plantilla a partir de este documento',
      title: 'Título',
      view: 'Ver',
    },
  },
  profile: {
    hello: '¡Hola {name}!',
    logOut: 'Cerrar sesión',
    memberships: {
      noTeams: 'Aún no tienes equipos. Para entrar a uno debes pedir un link de invitación.',
    },
    myProfile: 'Mi perfil',
    picture: {
      title: 'Foto de perfil',
      edit: 'Editar',
      crop: 'Recortar y subir',
      gravatar: 'Gravatar',
      info: 'Si no subes una foto de perfil, se usará por defecto la que tengas asociada a tu correo en {0}.',
      upload: 'Subir',
      cancel: 'Cancelar',
      deleteImage: 'Eliminar',
      delete: {
        info: `¿Estás seguro que quieres eliminar tu foto de perfil? Se asignará lo que tengas en
        {0} como imagen por defecto.`,
        confirmation: 'Sí, eliminar',
        title: 'Eliminar foto de perfil',
        cancel: 'No, volver',
      },
    },
    save: 'Guardar',
  },
  meetings: {
    announcement: {
      announcement: 'Anuncio | Anuncios',
      announce: 'Anunciar',
      new: 'Nuevo',
      placeholder: 'Escribe tu anuncio aquí...',
      error: 'Hubo un error enviando tu anuncio. Por favor intenta de nuevo.',
      noAnnouncements: 'Aún no hay anuncios en esta sección.',
      title: 'Anuncios en',
      singleTitle: 'Anuncio',
      saved: 'Anuncios guardados',
      noSavedAnnouncements: 'No has guardado anuncios en este mute',
      drafts: 'Borradores',
      noDrafts: 'No tienes borradores pendientes',
      unreadMessages: 'Mensajes no leídos',
      noUnreadMessages: 'No tienes mensajes pendientes',
      markSectionAsRead: 'Marcar sección como leída',
      markAllMessagesAsRead: 'Marcar todos los mensajes como leídos',
    },
    comment: {
      comment: 'Comentario | Comentarios',
      placeholder: 'Escribe tu comentario aquí...',
      error: 'Hubo un error enviando tu comentario. Por favor intenta de nuevo.',
      noComments: 'Aún no hay comentarios en este anuncio.',
      draftComment: 'De momento no se permiten comentarios, pero puedes redactarlo y enviarlo más adelante.',
      title: 'Hilo de comentarios en',
      singleTitle: 'Comentario',
    },
    config: {
      announcements: 'Anuncios permitidos',
      comments: 'Comentarios permitidos',
      experimental: 'Experimental',
      message: 'Configuración',
      notifications: 'Notificaciones',
      unreadMessages: 'Mensajes no leídos',
      moreSettings: 'Más opciones',
      global: {
        title: 'Configuración global',
        warning: 'Estas configuraciones afectan a todo el equipo',
      },
      personal: {
        title: 'Configuración personal',
        warning: 'Estas configuraciones solo te afectan a ti',
      },
      allowed: {
        comments: 'Se permiten hacer comentarios',
        announcements: 'Se permiten hacer anuncios',
        nothing: 'Por el momento no está permitido hacer anuncios ni comentarios',
        both: 'Está permitido hacer anuncios y comentarios',
      },
      locked: {
        comments: 'Los comentarios están bloqueados por el momento',
        announcements: 'Los anuncios están bloqueados por el momento',
      },
      enabled: {
        comments: 'Se han activado los <b>comentarios</b>',
        announcements: 'Se han activado los <b>anuncios</b>',
        indicator: 'Habilitados',
      },
      disabled: {
        comments: 'Se han desactivado los <b>comentarios</b>',
        announcements: 'Se han desactivado los <b>anuncios</b>',
        indicator: 'Deshabilitados',
      },
      more: 'Más',
    },
    document: {
      document: 'Mute',
      stats: 'Estadísticas',
      docFormat: 'Otras opciones',
      downloadPdf: 'Descargar .pdf',
      downloadDocx: 'Descargar .docx',
      open: 'Previsualización',
      noAnnouncements: 'No hay anuncios en esta sección.',
      metrics: 'Métricas',
      export: 'Vista de resumen',
    },
    instructions: {
      instructions: 'Ver instrucciones',
      muteInstructions: 'Instrucciones del Mute',
      noInstructions: 'No hay instrucciones específicas para este Mute',
    },
    goMeeting: 'Ir a videoconferencia',
    moreUnreads: 'Más sin leer',
    ready: {
      usersReady: ' usuario listo | usuarios listos',
      ready: 'Ya leí todo',
    },
    textEditor: {
      cancel: 'Cancelar',
      confirmation: 'Confirmación',
      delete: 'Eliminar',
      deleteWarning: '¿De verdad quieres borrar este {type}? Este paso no se podrá deshacer.',
      saveChanges: 'Guardar cambios',
    },
    unread: 'No leídos',
    savedAnnouncements: 'Anuncios guardados',
    draftComments: 'Borradores',
    customEmojis: {
      addEmoji: 'Añadir emoji',
      addEmojiExplanation: 'Tu emoji personalizado estará disponible para todos los miembros de tu equipo.',
      addEmojiSubExplanation: `Lo encontrarás en la pestaña personalizada del selector de emojis.
      (Una pista: es la que tiene el asterisco)`,
      addEmojiFirstStep: '1. Sube una imagen o un GIF',
      addEmojiUploadExplanationFirst: `Es mejor usar imágenes / GIFs cuadrados de menos de 128 KB y
                                       que tengan fondo transparente.`,
      addEmojiUploadExplanationSecond: `En Mute solo puedes recortar imágenes. Por ahora, te recomendamos
                                        usar {0} para recortar tus emojis animados.`,
      addEmojiUploadLink: 'esta herramienta',
      addEmojiSecondStep: '2. Dale un nombre',
      addEmojiLabel: 'Nombre del emoji',
      uploadImage: 'Subir',
      replaceImage: 'Reemplazar',
      errors: {
        notSquareGif: 'El GIF no tiene relación de aspecto 1:1',
        nameAlreadyExists: 'Ya hay un emoji con este nombre',
        nameContainsColonsOrSpaces: 'El nombre no puede contener espacios ni dos puntos',
        imageWrongFormat: 'Formato no soportado. La imagen debe ser jpg, jpeg, png, svg o gif.',
        unknown: 'Error desconocido, intenta nuevamente',
      },
    },
    search: {
      resultsCount: 'Resultados de búsqueda: ',
      noResults: 'No se encontraron resultados',
      searchPlaceholder: 'Buscar',
      error: 'Hubo un error al buscar. Por favor intenta de nuevo.',
      searchPrompt: 'Escribe para buscar',
      filters: {
        announcements: 'Anuncios',
        comments: 'Comentarios',
      },
    },
    filters: {
      filterMsg: 'Filtrar mensajes por:',
      mentions: 'Anuncios que me involucran',
      createdAnnouncements: 'Anuncios creados por mí',
      createdComments: 'Comentarios creados por mí',
      tags: 'Etiquetas',
    },
    reactions: {
      you: 'Tú',
      users: '{users} | {users} y {lastUser}',
      message: {
        userInvolved: '{0} reaccionaste con {1} | {0} reaccionaron con {1}',
        userNotInvolved: '{0} reaccionó con {1} | {0} reaccionaron con {1}',
      },
    },
  },
  language: {
    english: {
      abbreviation: 'en',
      message: 'English',
    },
    message: 'Idioma',
    spanish: {
      abbreviation: 'es',
      message: 'Español',
    },
  },
  feedback: {
    emailSubject: 'Feedback de {user}',
    emailBody: 'Añade lo que quieres sugerir',
    navBarButtonMessage: 'Dar feedback',
    goToBoard: 'Ir al tablero completo',
    loading: 'Cargando...',
  },
  changelog: {
    recentChangelogs: 'Nuevas actualizaciones',
    allChangelogs: 'Todas las actualizaciones',
    explanation: {
      recent: 'Hola {username}, acá podrás ver las actualizaciones de Mute desde la última vez que ingresaste.',
      all: 'Hola {username}, acá podrás ver las actualizaciones de Mute desde el inicio de la plataforma.',
    },
    close: 'Ok',
    noChangelogItems: 'No hay nuevas actualizaciones para mostrar',
  },
  metrics: {
    announcements: {
      plural: 'anuncios',
      empty: 'No hay anuncios aún',
    },
    comments: {
      plural: 'comentarios',
      empty: 'No hay comentarios aún',
    },
    reactions: 'reacciones',
    announcementBySection: 'anuncios por sección',
    commentsByAnnouncement: 'comentarios por anuncio',
    totalAnnouncements: 'Anuncios totales',
    totalComments: 'Comentarios totales',
    totalReactions: 'Reacciones totales',
    activity: {
      main: 'de los miembros participaron',
      of: '{active} de {all}',
      secondary: 'si es que consideras',
    },
    totalWords: {
      main: 'palabras fueron escritas en el mute',
      secondary: 'considerando anuncios y comentarios',
    },
    totalWordsTime: {
      main: 'minutos hubiese demorado esta reunión sin Mute',
      secondary: 'estimación propia en base al total de anuncios, comentarios y reacciones',
    },
    estimationText: `Considerando la cantidad de anuncios, comentarios y reacciones, estimamos
                     que esta sería la duración aproximada si la reunión no fuese silenciosa`,
    title: 'Métricas del mute',
  },
  sections: {
    addSection: 'Nueva Sección',
    add: 'Agregar',
    title: 'Secciones',
    splash: 'Haz click en una sección para comenzar',
  },
  landing: {
    navbar: {
      signIn: 'Iniciar sesión',
    },
    hero: {
      title: 'Reuniones que le devuelven el tiempo y foco a tu equipo',
      body: `
        Con Mute tendrás reuniones cortas y reproducibles en completo silencio.
        Podrás visibilizar la opinión de todos y delegar las responsabilidades de comunicar,
        dándote el espacio para repetir y mejorar tu reunión.
      `,
      alreadyRegistered: 'Si tu equipo ya usa Mute, {0}',
      signUp: 'regístrate',
    },
    cta: {
      buttonLabel: '¡Pruébalo!',
      emailPlaceholder: "correo{'@'}ejemplo.com",
    },
    companies: {
      header: 'Estos increíbles equipos ya se reúnen en Mute',
    },
    features: [
      {
        header: 'Reuniones cortas y concisas',
        body: [
          `¿Has notado que al hablar te tomas el espacio la reunión y el resto debe esperar para comunicar algo más?
          En Mute, hacemos las reuniones en silencio para que muchas conversaciones se den al mismo tiempo.`,
          `También te ayudamos a definir los temás de interés que quieres conversar previo a tu reunión,
          para que te apegues a ellos y evites dialogar cosas que no planificaste.`,
        ],
      },
      {
        header: 'Prepárala una vez y mejórala continuamente',
        body: [
          `La mayoría de las reuniones son recurrentes por naturaleza, y en Mute lo sabemos. Te damos las herramientas
          para crear la reunión una vez y olvidarte de administrarla.`,
          `Sin embargo, hay espacio para iterarla: te ayudamos a enfocarte en mejorar aspectos que
          no identificaste antes porque estabas preocupado por el tiempo.`,
        ],
      },
      {
        header: 'Ponte al día fácilmente',
        body: [
          '¿Te fuiste de vacaciones o te perdiste algunas reuniones y necesitas ponerte al día?',
          `Al escribir, tienes un registro exacto de todas las reuniones que han sucedido.
          Si te perdiste alguna, puedes verla exactamente como se dio, sin distorsiones.`,
        ],
      },
      {
        header: 'Ten la opinión de todos',
        body: [
          `En todas las reuniones hay personas que, por personalidad, tienden a participar más.
          Con Mute todos tienen la misma oportunidad de entregar su opinión y verás un aumento
          en la participación de las reuniones.`,
          `Además, sabemos que a veces un 👍 basta y sobra, y es por eso que puedes
          usar reacciones durante tu reunión para comunicar lo justo y necesario.`,
        ],
      },
      {
        header: 'Delega responsabilidades',
        body: [
          `Olvídate de que solo una persona hable en la reunión o de dividirla en varias sub-reuniones porque
          te falta tiempo.`,
          `Con Mute, habilitamos a que puedas delegar las responsabilidades de comunicar, evitando que sub-dividas tu
          reunión o que una persona acapare toda la información y la comunique al resto.`,
          'No tienes que tener en cinco reuniones lo que podrías tener en una.',
        ],
      },
    ],
    preFooter: {
      title: '¡Ten tu nueva reunión hoy!',
    },
    footer: {
      allRightsReserved: 'Platanus SpA.',
      mute: {
        team: 'Equipo',
        signIn: 'Iniciar sesión',
      },
      contact: {
        title: '¿Tienes dudas?',
        text: 'Escríbenos a',
      },
    },
  },
  editor: {
    bold: {
      tooltip: 'Negrita',
    },
    italic: {
      tooltip: 'Itálica',
    },
    strike: {
      tooltip: 'Tachado',
    },
    link: {
      input: 'Ingrese una url...',
      set: 'Enlazar',
      unset: 'Desenlazar',
      tooltip: 'Link',
    },
    title: {
      tooltip: 'Título',
    },
    quote: {
      tooltip: 'Cita',
    },
    code: {
      tooltip: 'Código',
    },
    bulletList: {
      tooltip: 'Lista',
    },
    orderedList: {
      tooltip: 'Lista enumerada',
    },
    outdent: {
      tooltip: 'Desindentar',
    },
    indent: {
      tooltip: 'Indentar',
    },
    attach: {
      tooltip: 'Insertar Imagen',
    },
    undo: {
      tooltip: 'Deshacer',
    },
    redo: {
      tooltip: 'Rehacer',
    },
    mention: {
      noResult: 'No hay resultados',
    },
    image: {
      size: {
        large: 'Grande',
        medium: 'Mediano',
        small: 'Pequeño',
      },
      typeError: 'Solo se pueden subir imágenes.',
      sizeError: 'La imagen es muy grande.',
      uploadError: 'Algo salió mal. Vuelve a intentarlo más tarde',
    },
  },
  yup: {
    string: {
      email: 'Debe ser un correo',
      url: 'Debe ser una URL válida',
      min: 'Debe tener al menos {argument} caracteres',
    },
    array: {
      min: 'Debe tener al menos {argument} elemento(s)',
    },
    mixed: {
      required: 'Este campo es requerido',
    },
    custom: {
      username: 'Solo puede tener letras, números, _ o .',
      passwordMatch: 'Las contraseñas no coinciden',
    },
  },
  baseSelect: {
    defaultPlaceholder: 'Selecciona una opción',
  },
  sidebar: {
    title: 'Mute',
    meetings: 'Reuniones',
    users: 'Usuarios',
    integrations: 'Integraciones',
    profile: 'Perfil',
    new: 'Nuevo',
  },
  user: {
    fields: {
      email: 'Correo',
      userName: 'Nombre de usuario',
      password: 'Contraseña',
      passwordConfirmation: 'Confirmar contraseña',
      memberships: 'Equipo por defecto',
      locale: 'Idioma',
    },
    errors: {
      update: 'No se han podido actualizar tus datos',
    },
    picture: {
      edit: 'Editar foto',
      delete: 'Eliminar foto',
    },
    profile: 'Perfil',
    editPassword: 'Cambio de contraseña',
    editData: 'Datos básicos',
    signOut: 'Cerrar sesión',
    editProfile: 'Editar usuario',
    editPicture: 'Editar foto de perfil',
  },
  actions: {
    save: 'Guardar',
    delete: 'Eliminar',
    edit: 'Editar',
    cancel: 'Cancelar',
    continue: 'Continuar',
    ok: 'Entendido',
  },
  errors: {
    default: {
      title: 'Los cambios no se pudieron guardar',
      subtitle: 'Puedes intentar nuevamente más tarde.',
    },
  },
  date: {
    formats: {
      long: 'DD [de] MMMM YYYY',
    },
  },
  integrations: {
    title: 'Integraciones',
    beta: 'beta',
    slack: {
      title: 'Slack',
      subtitle: 'Importa y/o actualiza tus emojis de Slack en Mute',
      action: 'Importar / actualizar',
      success: `Tus emojis ya van en camino! En unos minutos podrás verlos todos creados y actualizados.
      Si luego de cinco minutos no los ves, por favor contáctanos por el chat.`,
      error: 'La integración con Slack falló. Contáctanos por el chat o intenta nuevamente más tarde',
      noteLabel: 'Notar que:',
      notes: [
        `El proceso de importación creará los emojis que no existan en tu equipo de Mute y actualizará los
        existentes con la imagen de Slack. Los que existan en Mute, pero no en Slack, no tendrán cambios.`,
        `Dado que esto es una funcionalidad beta, aún no tenemos sincronización en tiempo real, por lo que si
        se agregan nuevos emojis a tu workspace de Slack, deberás importarlos nuevamente presionando el botón.`,
        `Es probable que Slack te pida permisos para acceder a tu workspace. Una vez que obtengas esos permisos,
        por favor vuelve a presionar el botón para iniciar el proceso de importación.`,
      ],
    },
    feedback: {
      header: '¿Se te ocurren nuevas integraciones? ¿Te gustan las que están? ¿Algo no funciona?',
      subheader: '{0} Valoramos mucho tu feedback',
      emailSubject: 'Feedback de {user}',
      callToAction: '¡Cuéntanos!',
      channels: {
        email: 'Escríbenos un correo',
        slack: 'Háblanos por Slack',
        meeting: 'Agenda una reunión',
        chat: 'Chatea con nosotros',
      },
    },
  },
};
