import Image from '@tiptap/extension-image';
import { mergeAttributes } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { handleImagesInsert } from '../../helpers/image';

const imageSizeClasses = {
  small: '!max-w-[150px]',
  medium: '!max-w-[450px]',
  large: '!max-w-full',
};

export default Image.extend({
  name: 'custom-image',

  addOptions() {
    const opts = this.parent ? this.parent() : {};

    return {
      ...opts,
      onError: () => ({}),
    };
  },

  addAttributes() {
    return {
      ...Image.config.addAttributes(),
      src: {
        default: '',
        rendered: false,
        parseHTML: (element) => element.getAttribute('url'),
      },
      url: {
        default: '',
        rendered: false,
        parseHTML: (element) => element.getAttribute('url'),
      },
      size: {
        default: 'small',
        rendered: false,
        parseHTML: (element) => element.getAttribute('width'),
      },
      contentType: {
        default: 'image/png',
        rendered: false,
        parseHTML: (element) => element.getAttribute('content-type'),
      },
      filename: {
        default: '',
        rendered: false,
        parseHTML: (element) => element.getAttribute('fileName'),
      },
      fileSize: {
        default: 0,
        rendered: false,
        parseHTML: (element) => element.getAttribute('fileSize'),
      },
    };
  },

  parseHTML() {
    return [
      { tag: 'action-text-attachment' },
    ];
  },

  addCommands() {
    return {
      setImage: (attributes) => ({ tr, commands }) => {
        const { selection } = tr;

        const { to, from } = selection;
        if (to !== from) {
          return commands.insertContentAt(to, {
            type: this.name,
            attrs: attributes,
          }, {
            updateSelection: true,
          });
        }

        return commands.insertContent({
          type: this.name,
          attrs: attributes,
        });
      },
      setImageSize: (size) => ({ tr, chain }) => {
        const { selection } = tr;
        const node = selection && selection.node;
        const type = node && node.type;
        const name = type && type.name;

        if (name === 'custom-image') {
          chain()
            .updateAttributes('custom-image', { size })
            .setTextSelection(selection.to)
            .run();
        }
      },
    };
  },

  renderHTML({ node, HTMLAttributes }) {
    const { size, url, filename, contentType, fileSize } = node.attrs;
    HTMLAttributes.class = `${imageSizeClasses[size] || ''} inline-block`;

    return [
      'action-text-attachment', // Required to work well with ActionText in the backend
      mergeAttributes({
        'content-type': contentType,
        url,
        filename,
        fileSize,
        width: size, // This makes ActionText (trix) save the image's size in the database
      }, HTMLAttributes),
      [
        'img',
        mergeAttributes({ src: url }, this.options.HTMLAttributes, HTMLAttributes),
      ],
    ];
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('custom-image-drop'),
        props: {
          handlePaste: (view, event) => {
            const hasFiles = event.clipboardData.files.length > 0;
            if (!hasFiles) return false;

            return handleImagesInsert({
              view,
              position: view.state.selection.anchor,
              files: event.clipboardData.files,
              onError: this.options.onError,
            });
          },
          handleDrop: async (view, event) => {
            const hasFile = event.dataTransfer.files.length > 0;
            if (!hasFile) return false;

            return handleImagesInsert({
              view,
              position: view.posAtCoords({ left: event.clientX, top: event.clientY }).pos,
              files: event.dataTransfer.files,
              onError: this.options.onError,
            });
          },
        },
      }),
    ];
  },
});
