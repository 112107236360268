import StarterKit from '@tiptap/starter-kit';

export default StarterKit.configure({
  blockquote: {
    HTMLAttributes: {
      class: 'border-solid border-gray-300 ml-1 pl-2',
      style: 'border-left-width: 0.3em;',
    },
  },
  bulletList: {
    HTMLAttributes: {
      class: 'list-disc ml-4',
    },
  },
  code: {
    HTMLAttributes: {
      class: 'text-purple-500 text-sm p-0.5 bg-gray-100',
    },
  },
  codeBlock: {
    HTMLAttributes: {
      class: 'text-sm text-gray-700 font-normal p-1.5 rounded-sm bg-gray-50 border border-gray-300 my-1',
    },
  },
  heading: false,
  listItem: {
    HTMLAttributes: {
      style: 'display: list-item',
    },
  },
  orderedList: {
    HTMLAttributes: {
      class: 'ml-4',
      style: 'list-style-type: decimal;',
    },
  },
  hardBreak: false,
});
