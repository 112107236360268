<template>
  <editable
    v-observe-visibility="(isVisible, entry) => $emit('visibility-changed', isVisible, entry)"
    :object-id="Number(announcementId)"
    variant="announcement"
    :editing="editing"
    :hover-bar-items="areHoverBarItemsAllowed ? hoverBarItems : null"
    class="w-full bg-white"
    :is-confirmation-modal-open="isConfirmationModalOpen"
    @remove="removeAnnouncement"
    @hover-bar-item-click="handleHoverBarItemClick"
    @close-confirmation-modal="isConfirmationModalOpen = false"
  >
    <separator
      v-if="isNew"
      :element-id="announcementId"
      @scroll-to-element="scrollHere"
    />
    <div
      class="flex w-full p-3 lg:p-4 text-left transition-colors duration-150 ease-in-out cursor-pointer"
      :class="{ 'cursor-auto': editing, 'bg-indigo-50 border-r-4 border-indigo-500': zero, 'hover:bg-slate-50': !zero }"
      @click="selectAnnouncement"
    >
      <div class="flex flex-col flex-1 min-w-0 break-words">
        <div class="flex flex-1 mb-2">
          <div class="flex flex-col flex-1 min-w-0 text-sm lg:text-base">
            <div class="flex flex-row">
              <mute-avatar
                class="self-start shrink-0 mr-2"
                size="medium"
                :src="avatar"
              />
              <div
                v-if="author.username"
                class="flex-1 font-bold text-purple-500"
              >
                {{ author.username }}
              </div>
            </div>
            <editable-text
              :text="text"
              :editing="editing"
              content-type="announcement"
              :content-id="announcementId"
              :author="author"
              @confirm-edit="confirmAnnouncementEdit"
              @cancel-edit="editing=false"
            />
          </div>
        </div>
        <div class="flex items-center">
          <reactions-section
            class="flex-1"
            type="announcements"
            :reactable="announcement"
          />
          <users-avatars
            class="justify-end"
            :users="usersInAnnouncement"
          />
        </div>
        <comments-footer
          class="mt-2"
          :announcement="announcement"
        />
      </div>
      <div class="flex flex-col ml-1">
        <mute-notification
          v-if="notification && notificationsAllowed"
          :count="selected ? 0 : unreadCommentsCount"
          class="mt-8 mr-3 lg:mt-0 lg:mr-0"
          :class="{ 'bg-purple-500': mentioned }"
        />
        <div class="flex items-center flex-1 transition-colors duration-200 rounded">
          <inline-svg
            v-if="!editing"
            :src="require('assets/images/icons/right-arrow.svg')"
            class="text-purple-500 fill-current"
          >
            {{ $tc('meetings.comment.comment', 1) }}
          </inline-svg>
        </div>
      </div>
    </div>
    <mute-divider
      class="-mt-px"
      color="gray-200"
      width="w-auto"
    />
  </editable>
</template>

<script>
import { mapState } from 'vuex';
import ReactionsSection from '../shared/reactions-section';
import UsersAvatars from '../shared/users-avatars';
import Editable from '../shared/editable';
import EditableText from '../shared/editable-text';
import announcementsApi from '../../api/announcements';
import CommentsFooter from './comments-footer.vue';
import Separator from '../shared/separator.vue';
import { logAmplitudeEvent } from '../../helpers/amplitude';

export default {
  components: {
    ReactionsSection,
    UsersAvatars,
    Editable,
    EditableText,
    CommentsFooter,
    Separator,
  },
  props: {
    announcement: {
      type: Object,
      required: true,
    },
    author: {
      type: Object,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    isSaved: {
      type: Boolean,
      default: false,
    },
    selectedSectionId: {
      type: String,
      default: null,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    areHoverBarItemsAllowed: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['visibility-changed', 'change-announcement'],
  data() {
    return {
      editing: false,
      isConfirmationModalOpen: false,
    };
  },
  computed: {
    ...mapState(['document', 'team']),
    notificationsAllowed() {
      return this.document.notificationsAllowed;
    },
    hoverBarItems() {
      return [
        {
          id: 'silence',
          iconSrc: require(
            `assets/images/icons/notifications${this.isSilent ? '' : '-off'}.svg`,
          ),
        },
        {
          id: 'bookmark',
          class: this.isSaved && 'text-red-500',
          iconSrc: require(`assets/images/icons/bookmark${this.isSaved ? '' : '-off'}.svg`),
        },
        ...(this.isCurrentUserAnnouncement ? [{
          id: 'edit',
          iconSrc: require('../../../assets/images/icons/edit.svg'),
        },
        {
          id: 'delete',
          iconSrc: require('../../../assets/images/icons/delete.svg'),
        }] : []),
      ];
    },
    isCurrentUserAnnouncement() {
      return this.author.id === this.$store.state.user.id && !this.editing;
    },
    isSilent() {
      return this.notificationsAllowed && this.notification;
    },
    unreadCommentsCount() {
      return this.commentsCount(this.document.unreadComments);
    },
    mentioned() {
      return this.commentsCount(this.document.commentsNotificated);
    },
    avatar() {
      return this.author.avatar;
    },
    hasImage() {
      return !!this.avatar;
    },
    announcementId() {
      return this.announcement.id;
    },
    usersInAnnouncement() {
      return Object.values(this.$store.state.document.users)
        .filter(user => user.position && user.position.announcementId === this.announcementId);
    },
    zero() {
      const routeAnnouncementId = Number(this.$route.params.selectedAnnouncementId);

      return routeAnnouncementId && this.announcementId === routeAnnouncementId;
    },
    showNotificationsImage() {
      return (this.zero || !this.notificationsAllowed || !this.notification || this.unreadCommentsCount === 0) &&
              this.author.id !== this.$store.state.user.id;
    },
    notification() {
      return !this.document.mutedAnnouncements[this.announcementId];
    },
    documentId() {
      return this.$store.state.document.document.id;
    },
    commentsAllowed() {
      return this.document.documentConfiguration.commentsAllowed;
    },
    users() {
      return Object.values(this.document.users).map((user) => user.username);
    },
  },
  methods: {
    scrollHere() {
      document.getElementById(`announcement-${this.announcementId}`).scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    },
    handleHoverBarItemClick(actionId) {
      const actions = {
        silence: this.toggleNotification,
        edit: () => { this.editing = true; },
        delete: () => { this.isConfirmationModalOpen = true; },
        bookmark: this.toggleSaveAnnouncement,
      };

      return actions[actionId] && actions[actionId]();
    },
    selectAnnouncement(event) {
      if (this.editing || event.target.tagName.toLowerCase() === 'a') return;
      this.$emit('change-announcement');
    },
    removeAnnouncement() {
      announcementsApi.removeAnnouncement(this.announcement.id);
    },
    confirmAnnouncementEdit(newAnnouncement, tags) {
      announcementsApi.editAnnouncement(this.announcement.id, { body: newAnnouncement, tagList: tags })
        .then(() => {
          this.editing = false;
        });
    },
    toggleNotification() {
      logAmplitudeEvent('silence-announcement', {
        teamId: this.team.teamId,
        announcementId: this.announcementId,
        toValue: !this.isSilent,
      });

      this.$store.dispatch('toggleNotification',
        { type: 'mutedAnnouncements', index: this.announcementId, notification: this.notification });
    },
    toggleSaveAnnouncement() {
      logAmplitudeEvent('bookmark-announcement', {
        teamId: this.team.teamId,
        announcementId: this.announcementId,
        toValue: !this.isSaved,
      });

      this.$store.dispatch('toggleSavedAnnouncement',
        { documentId: this.documentId, sectionId: this.selectedSectionId, announcementId: this.announcementId });
    },
    commentsCount(comments) {
      return comments.filter(
        u => Number(u.announcementId) === Number(this.announcementId),
      ).length;
    },
  },
};
</script>
