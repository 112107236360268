<template>
  <div
    v-tooltip="$t(`meetings.config.${status}.${resource}`)"
    class="flex items-center"
    data-testId="status-wrapper"
  >
    <inline-svg
      class="w-4 h-4 text-xs text-center rounded-full fill-slate-50"
      :class="[disabled ? 'bg-amber-500' : 'bg-teal-500']"
      :src="require(`assets/images/design-system-icons/${disabled ? 'disabled' : 'enabled'}.svg`)"
    />
    <p
      class="ml-1 text-xs border-b border-dotted hidden md:block"
      :class="[disabled ? 'text-amber-500 border-amber-500' : 'text-teal-500 border-teal-500']"
    >
      {{ labelIndicator }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    disabled: { type: Boolean, default: false },
    resource: { type: String, default: '' },
    label: { type: String, default: '' },
  },
  computed: {
    status() {
      return this.disabled ? 'locked' : 'allowed';
    },
    labelIndicator() {
      const labelExist = !(this.label === '');
      const labelDefault = this.disabled ? this.$t('meetings.config.disabled.indicator') :
        this.$t('meetings.config.enabled.indicator');

      return labelExist ? this.label : labelDefault;
    },
  },
};
</script>
