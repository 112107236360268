import { VueRenderer } from '@tiptap/vue-3';
import tippy from 'tippy.js';

function getSuggestion(queryFunction, listComponent) {
  return {
    items: queryFunction,

    render: () => {
      let component;
      let popup;

      return {
        onStart: props => {
          component = new VueRenderer(listComponent, {
            props,
            editor: props.editor,
          });

          if (!props.clientRect) {
            return;
          }

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'top-start',
          });
        },

        onUpdate(props) {
          component.updateProps(props);
          component.ref.resetItems();
          if (!props.clientRect) {
            return;
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },

        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            popup[0].hide();

            return true;
          }

          return component.ref.onKeyDown(props);
        },

        onExit() {
          popup[0].destroy();
          component.destroy();
        },
      };
    },
  };
}

export default getSuggestion;
