<template>
  <div class="flex flex-col flex-1 overflow-hidden lg:flex-row bg-white">
    <div
      class="flex flex-col lg:w-2/12 border-r overflow-y-auto overflow-x-hidden"
      :class="{ 'hidden lg:flex': (selectedAnnouncementId || selectedSectionId) }"
    >
      <div class="flex flex-row justify-between items-center w-screen p-2 bg-slate-100 text-xs font-bold text-gray-400 border-b border-gray-200 max-h-full z-0 lg:w-auto relative top-0">
        {{ $t('sections.title') }}
      </div>
      <div
        v-if="notificationsTop"
        class="absolute inset-x-0 top-0 justify-center hidden lg:flex lg:z-10 mt-14"
      >
        <more-unreads
          :is-on-top="true"
          @click="scrollToUnread(true)"
        />
      </div>

      <div
        v-if="notificationsBottom"
        class="absolute inset-x-0 bottom-0 justify-center lg:flex lg:z-10"
        :class="showsUnreadMessagesSection ? 'mb-56' : 'mb-32'"
      >
        <more-unreads @click="scrollToUnread(false)" />
      </div>
      <div
        id="announcement-section"
        class="flex flex-1 lg:flex-col h-4/6 overflow-hidden"
      >
        <div
          v-if="isAllowedToSeeAnnouncements"
          class="flex flex-col relative w-full flex-1 overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]"
        >
          <announcement-section
            v-for="section in sortedSections"
            :id="`section-${section.id}`"
            :key="section.id"
            :section-index="`${section.id}`"
            :title="section.title"
            :selected="selectedSection && section.id === selectedSection.id"
            @change-section="changeSection(section.id)"
            @visibility-changed="(isVisible, entry) => visibilityChanged(isVisible, entry, section.id)"
          />
          <mute-button
            v-if="isMasterOfCeremonies"
            class="h-full lg:h-auto m-2"
            outlined
            @click="openSectionModal"
          >
            {{ $t('sections.addSection') }}
          </mute-button>
        </div>
        <add-section-modal
          :document-id="String(documentId)"
          :total-sections="sortedSections.length"
          :open="isAddSectionModalOpen"
          @close="isAddSectionModalOpen = false"
        />
      </div>
      <div class="hidden lg:flex lg:flex-col border-t">
        <topics-bar />
      </div>
    </div>
    <div
      class="flex flex-col flex-1 w-screen lg:w-10/12 overflow-y-auto lg:mt-0"
    >
      <router-view v-if="!showSplash" />
      <div
        v-if="showSplash"
        class="lg:flex flex-1 flex-col items-center justify-center space-y-2 opacity-50 hidden"
      >
        <inline-svg
          class="h-24 w-24 fill-current text-indigo-500 mx-auto"
          :src="require('assets/images/design-system-icons/mute-logo.svg')"
        />
        <p>{{ $t('sections.splash') }}</p>
      </div>
    </div>
    <ready-button
      v-if="!hasLargeScreen"
      class="mb-2 self-center"
    />
    <custom-notification />
  </div>
</template>

<script>
import { ref } from 'vue';
import debounce from 'lodash/debounce';
import { regexPatterns } from '../utils/regexs';
import AnnouncementSection from '../components/announcement-section/announcement-section.vue';
import AddSectionModal from '../components/meeting/add-section-modal';
import ReadyButton from '../components/meeting/ready-button.vue';
import MoreUnreads from '../components/more-unreads';
import CustomNotification from './notification/custom-notification.vue';
import TopicsBar from '../components/meeting/topics-bar.vue';
import unreadMessagesApi from '../api/unread-messages';
import useBroadcastDocumentMessage from '../composables/useBroadcastDocumentMessage';

const DEBOUNCE_TIMER = 750;

export default {
  components: {
    AnnouncementSection,
    AddSectionModal,
    ReadyButton,
    MoreUnreads,
    CustomNotification,
    TopicsBar,
  },

  beforeRouteEnter(to, from, next) {
    next(vm => vm.handleRoutes(vm, next, to.params, from.params));
  },

  beforeRouteUpdate(to, from, next) {
    this.handleRoutes(this, next, to.params, from.params);
  },
  props: {
    documentId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const broadcastDocumentMessage = useBroadcastDocumentMessage();
    const isCheckingAnnouncement = ref(true);
    const isMobileView = ref(false);
    const isAllowedToSeeAnnouncements = ref(true);

    return { broadcastDocumentMessage, isCheckingAnnouncement, isMobileView, isAllowedToSeeAnnouncements };
  },
  data() {
    return {
      unreadMessages: {},
      isAddSectionModalOpen: false,
      applicationHostRegex: /^[^:]+:\/\/[^/]+/,
      isInSectionViewRegex: /\/teams\/\d+\/documents\/\d+\/?#?\/sections\/\d+\/*/,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
    sections() {
      return this.$store.getters.sections || {};
    },
    sortedSections() {
      const sections = this.sections;
      const sectionList = sections ? Object.values(sections) : [];
      sectionList.sort((a, b) => a.order - b.order);

      return sectionList;
    },
    selectedSection() {
      return this.sections[this.selectedSectionId];
    },
    selectedSectionId() {
      return this.$route.params.selectedSectionId;
    },
    selectedAnnouncementId() {
      return this.$route.params.selectedAnnouncementId;
    },
    isMasterOfCeremonies() {
      const document = this.$store.state.document.document;

      return document.mastersOfCeremonies?.includes(this.$store.state.user.id);
    },
    topBound() {
      return document.getElementById('announcement-section').getBoundingClientRect().top;
    },
    hiddenSections() {
      return this.$store.state.document.hiddenSections || {};
    },
    notificationsTop() {
      return Object.keys(this.hiddenSections).filter(
        (key) =>
          this.$store.getters.unreadCount(key) > 0 &&
          this.hiddenSections[key].isOnTop &&
          key !== this.selectedSectionId,
      ).length;
    },
    notificationsBottom() {
      return Object.keys(this.hiddenSections).filter(
        (key) =>
          this.$store.getters.unreadCount(key) > 0 &&
          !this.hiddenSections[key].isOnTop &&
          key !== this.selectedSectionId,
      ).length;
    },
    showsUnreadMessagesSection() {
      return this.$store.state.document.allowUnreadMessages || false;
    },
    hasLargeScreen() {
      return this.$store.getters.hasLargeScreen;
    },
    showSplash() {
      return this.$route.path === '/';
    },
  },
  watch: {
    $route(to, from) {
      this.isAllowedToSeeAnnouncements = this.visualizationLogicSection();
    },
  },
  methods: {
    visualizationLogicSection() {
      if (this.isMobile) {
        return !(this.isUserInsideSectionView(this.getHrefWithoutOrigin()));
      }

      return true;
    },
    getHrefWithoutOrigin() {
      return (document.location.href).replace(regexPatterns.applicationHostRegex, '');
    },
    isUserInsideSectionView(currentHref) {
      return regexPatterns.isInSectionViewRegex.test(currentHref);
    },
    changeSection(index) {
      const sectionRoute = `/sections/${index}/`;
      if (this.$route.fullPath !== sectionRoute) this.$router.push(sectionRoute);
    },
    openSectionModal() {
      this.isAddSectionModalOpen = true;
    },
    onTop(topBound) {
      return topBound < this.topBound;
    },
    visibilityChanged(isVisible, entry, index) {
      const isOnTop = this.onTop(entry.boundingClientRect.top);
      this.$store.dispatch('toggleHiddenSection', { isVisible, index, isOnTop });
    },
    scrollToUnread(scrollTop) {
      const hiddenUnreadSections = Object.keys(this.hiddenSections).filter(
        (key) => this.$store.getters.unreadCount(key) > 0,
      );
      const sectionId = hiddenUnreadSections[scrollTop ? 0 : hiddenUnreadSections.length - 1];
      document.getElementById(`section-${sectionId}`).scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
    updatePosition(sectionId, announcementId) {
      this.$store.dispatch('updateUserPosition', { id: this.$store.state.user.id, sectionId, announcementId });
    },
    notifyPosition: debounce(function (sectionId, announcementId) {
      this.broadcastDocumentMessage({ type: 'user_position', sectionId, announcementId });
    }, DEBOUNCE_TIMER),
    handleRoutes(vm, next, toParams, fromParams) {
      const { selectedAnnouncementId, selectedSectionId } = toParams;
      const { selectedAnnouncementId: leavingAnnouncementId, selectedSectionId: leavingSectionId } = fromParams;
      const changeAnnouncement = this.handleLeavingAnnouncement(selectedAnnouncementId, leavingAnnouncementId);
      const changeSection = this.handleLeavingSection(selectedSectionId, leavingSectionId);

      if (changeSection || changeAnnouncement || (!selectedSectionId && !selectedAnnouncementId)) {
        this.updatePosition(selectedSectionId, selectedAnnouncementId);
        this.notifyPosition(selectedSectionId, selectedAnnouncementId);
      }

      return next();
    },
    handleLeavingAnnouncement(selectedAnnouncementId, leavingAnnouncementId) {
      const isChangingAnnouncement = selectedAnnouncementId !== leavingAnnouncementId;
      if (isChangingAnnouncement && leavingAnnouncementId) {
        unreadMessagesApi.markAnnouncementCommentsAsRead(this.documentId, leavingAnnouncementId);
        this.$store.dispatch('markAnnouncementAsRead', leavingAnnouncementId);
      }

      return isChangingAnnouncement;
    },
    handleLeavingSection(selectedSectionId, leavingSectionId) {
      const isChangingSection = leavingSectionId !== selectedSectionId;
      if (isChangingSection && leavingSectionId) {
        unreadMessagesApi.markSectionAnnouncementsAsRead(this.documentId, leavingSectionId);
        this.$store.dispatch('markSectionAsRead', leavingSectionId);
      }

      return isChangingSection;
    },
  },

};
</script>
