<template>
  <base-modal
    :title="$t('meetings.customEmojis.addEmoji')"
    @close="resetUpload"
  >
    <p>
      {{ $t('meetings.customEmojis.addEmojiExplanation') }}
    </p>
    <p class="mb-4">
      {{ $t('meetings.customEmojis.addEmojiSubExplanation') }}
    </p>
    <p
      class="mb-2 font-semibold"
    >
      {{ $t('meetings.customEmojis.addEmojiFirstStep') }}
    </p>
    <div class="mb-4 ml-4">
      <div class="flex items-center">
        <div class="flex-1 mr-2 text-sm text-gray-600">
          <p class="mb-2">
            {{ $t('meetings.customEmojis.addEmojiUploadExplanationFirst') }}
          </p>
          <i18n-t
            keypath="meetings.customEmojis.addEmojiUploadExplanationSecond"
            tag="p"
            for="meetings.customEmojis.addEmojiUploadLink"
            scope="global"
          >
            <a
              href="https://onlinegiftools.com/crop-gif"
              class="text-green-500"
              rel="noopener noreferrer"
              target="_blank"
            >
              {{ $t('meetings.customEmojis.addEmojiUploadLink') }}
            </a>
          </i18n-t>
        </div>
        <mute-button
          id="upload-photo"
          type="button"
          icon-file-name="upload-icon.svg"
          @click="$refs.imageInput.click()"
        >
          {{ $t(`meetings.customEmojis.${file ? 'replace' : 'upload'}Image`) }}
        </mute-button>
      </div>
      <input
        ref="imageInput"
        type="file"
        accept="image/*"
        class="hidden"
        @change="loadImage($event)"
      >
      <div class="flex flex-col mb-4">
        <div
          v-if="file && file.type !== 'image/gif'"
          class="flex items-center justify-center mt-2 space-x-4"
        >
          <cropper
            ref="cropper"
            :src="imageSrc"
            class="w-48 h-48 border border-gray-500 rounded-lg"
            :stencil-props="{ aspectRatio: 1 }"
            :debounce="false"
            @change="onChange"
          />
          <div
            v-for="background in ['bg-white', 'bg-gray-800']"
            :key="background"
            class="flex items-center justify-center w-12 h-12 overflow-hidden border border-gray-500 rounded-lg"
            :class="background"
          >
            <preview
              :width="32"
              :height="32"
              :image="result.image"
              :coordinates="result.coordinates"
            />
          </div>
        </div>
        <div
          v-if="file && file.type === 'image/gif'"
          class="flex items-center justify-center mt-2 space-x-4"
        >
          <img
            v-for="background in ['bg-white', 'bg-gray-800']"
            :key="background"
            class="border-gray-500 w-auto h-12 p-2 border rounded-lg"
            :class="background"
            :src="localFileUrl"
            alt="gif"
          >
        </div>
      </div>
    </div>
    <p
      class="mb-2 font-semibold"
    >
      {{ $t('meetings.customEmojis.addEmojiSecondStep') }}
    </p>
    <div class="ml-4">
      <mute-input
        v-model="emojiName"
        :label="$t('meetings.customEmojis.addEmojiLabel')"
        class="mb-4"
      />
    </div>
    <div
      v-if="!!error"
      class="p-2 mb-4 text-red-500 bg-red-100 rounded-lg shadow"
    >
      {{ error }}
    </div>
    <div class="flex justify-end">
      <mute-button
        variant="green"
        :disabled="!file || !emojiName || loading"
        @click="onSubmit"
      >
        {{ $t('meetings.customEmojis.addEmoji') }}
      </mute-button>
    </div>
  </base-modal>
</template>

<script>
import 'vue-advanced-cropper/dist/style.css';
import { useI18n } from 'vue-i18n';
import { Cropper, Preview } from 'vue-advanced-cropper';
import customEmojisApi from '../../api/custom_emojis';

const UNPROCESSABLE_ENTITY_STATUS = 422;

export default {
  components: {
    Cropper,
    Preview,
  },
  emits: ['close'],
  setup() {
    const { t } = useI18n();

    return { t };
  },
  data() {
    return {
      imageSrc: null,
      file: null,
      result: {
        coordinates: null,
        image: null,
      },
      loading: false,
      emojiName: '',
      error: null,
    };
  },
  computed: {
    teamId() {
      return this.$store.state.team.teamId;
    },
    localFileUrl() {
      return URL.createObjectURL(this.file);
    },
  },
  methods: {
    async loadImage(event) {
      const input = event.target;
      if (!input.files || !input.files[0]) return;

      const [file] = input.files;
      const imageSrc = await this.loadImageSrc(file);

      this.file = file;
      if (file.type !== 'image/gif') this.imageSrc = imageSrc;

      this.error = null;
    },
    loadImageSrc(file) {
      const reader = new FileReader();

      return new Promise((resolve) => {
        reader.onload = (event) => resolve(event.target.result);
        reader.readAsDataURL(file);
      });
    },
    resetUpload() {
      this.imageSrc = null;
      this.file = null;
      this.emojiName = '';
      this.$emit('close');
    },
    async onSubmit() {
      this.loading = true;
      try {
        await this.handleEmojiUpload();
      } catch (error) {
        this.error = this.getErrorMessage(error);
      } finally {
        this.loading = false;
      }
    },
    async handleEmojiUpload() {
      let imageBlob = this.file;
      if (this.file.type !== 'image/gif') imageBlob = await this.getBlobFromVisibleCanvas();
      if (!imageBlob) return;

      const data = { emojiName: this.emojiName, image: imageBlob };
      await customEmojisApi.createCustomEmoji(this.teamId, data);
      this.reloadEmojiPicker();
      this.resetUpload();
    },
    async getBlobFromVisibleCanvas() {
      const { canvas } = this.$refs.cropper.getResult();
      if (!canvas) return null;

      return new Promise((resolve) => canvas.toBlob((blob) => resolve(blob), this.file.type));
    },
    onChange({ coordinates, image }) {
      this.result = { coordinates, image };
    },
    reloadEmojiPicker() {
      this.$store.dispatch('getCustomEmojis', this.teamId);
      this.$store.dispatch('closeEmojiPicker');
    },
    getErrorMessage(error) {
      const { response = {} } = error;
      const { status, data = {} } = response;
      const { errors = {} } = data;
      const { name: nameErrors = [], image: imageErrors } = errors;

      if (status === UNPROCESSABLE_ENTITY_STATUS) {
        return this.handleNameErrors(nameErrors) ||
               this.handleImageErrors(imageErrors) ||
               this.t('meetings.customEmojis.errors.unknown');
      }

      return this.t('meetings.customEmojis.errors.unknown');
    },
    handleNameErrors(nameErrors) {
      if (nameErrors.includes('has already been taken')) {
        return this.$t('meetings.customEmojis.errors.nameAlreadyExists');
      }

      if (nameErrors.includes('contains invalid characters')) {
        return this.$t('meetings.customEmojis.errors.nameContainsColonsOrSpaces');
      }

      return null;
    },
    handleImageErrors(imageErrors) {
      if (imageErrors) {
        return this.$t('meetings.customEmojis.errors.imageWrongFormat');
      }

      return null;
    },
  },
};
</script>
