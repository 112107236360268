import { test as testLink } from 'linkifyjs';

export function colonsRuleHandler({ state, range, match }) {
  if (match.input && testLink(match.input)) return;

  const [id, skin] = match[0].trim().split('::').map((s) => s.replace(/:/g, ''));

  const { tr } = state;

  const emoji = this.options.getEmoji(id, skin);
  if (!emoji) return;

  tr.replaceWith(range.from, range.to, this.type.create({ id, skin }));
}

export function unicodeRuleHandler({ state, range, match }) {
  const { id, skin } = this.options.emojiIndex.nativeEmoji(match);
  const { tr } = state;

  const emoji = this.options.getEmoji(id, skin);
  if (!emoji) return;

  tr.replaceWith(range.from, range.to, this.type.create({ id, skin }));
}
