<template>
  <div>
    <toolbar
      v-if="!hideToolbar"
      :editor="editor"
      :hidden-buttons="hiddenButtons"
      :disabled-indicator="disabledIndicator"
      :resource="resource"
      :allow-indicator="allowIndicator"
      @error="onError"
    />
    <image-bubble-menu
      v-if="editor && editor.isEditable"
      :editor="editor"
    />
    <editor-content
      :editor="editor"
    />
    <error-box :show-error="!!error">
      {{ error }}
    </error-box>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { isArray, isString } from 'lodash';
import { useI18n } from 'vue-i18n';

import { EditorContent } from '@tiptap/vue-3';
import Toolbar from './toolbar/toolbar.vue';

import { getTipTapEditor, getTipTapExtensions } from './editor';

import ImageBubbleMenu from './extensions/image/bubble-menu.vue';
import ErrorBox from '../error-box.vue';

import getSuggestion from './extensions/suggestion';
import MentionList from './extensions/mention/MentionList.vue';
import TagList from './extensions/tag/TagList.vue';
import EmojiList from './extensions/emoji/EmojiList.vue';

const MAX_SUGGESTIONS = 15;

export default {
  components: {
    EditorContent,
    Toolbar,
    ErrorBox,
    ImageBubbleMenu,
  },

  props: {
    modelValue: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    errorMessageTime: { type: Number, default: 3000 },
    disabledExtensions: { type: Array, default: () => [] },
    hiddenButtons: { type: Array, default: () => [] },
    hideToolbar: { type: Boolean, default: false },
    bordered: { type: Boolean, default: false },
    editorClass: { type: String, default: '' },
    disabledIndicator: { type: Boolean, default: false },
    resource: { type: String, default: '' },
    allowIndicator: { type: Boolean, default: false },
  },
  emits: ['image-click', 'update:model-value', 'json-input'],
  setup() {
    const { t } = useI18n();

    return { t };
  },

  data() {
    return {
      editor: null,
      error: '',
    };
  },

  computed: {
    ...mapState({
      users: ({ document }) => Object.values(document.users).map((user) => user),
      teamTagList: ({ team }) => team.tagList,
    }),
    emojiIndex() {
      return this.$store.state.emoji.emojiIndex;
    },
    editorStyle() {
      let editorClass = `${this.editorClass} mr-2 py-1 focus:outline-none`;
      if (this.bordered) {
        editorClass += ' border rounded-lg border-gray-300';
      }
      if (!this.disabled) {
        editorClass += ' p-2 h-full min-h-[4rem]';
      }

      return editorClass;
    },
  },
  watch: {
    modelValue(value) {
      if (this.editor.getHTML() === value) return;
      this.editor.commands.setContent(value, false);
    },
    disabled(value) {
      this.editor.setEditable(!value);
    },
  },

  mounted() {
    const mentionSuggestion = getSuggestion(this.queryUsers, MentionList);
    const tagSuggestion = getSuggestion(this.queryTags, TagList);
    const emojiSuggestion = getSuggestion(this.queryEmojis, EmojiList);

    const extensions = getTipTapExtensions({
      Placeholder: { placeholder: this.placeholder },
      Link: { openOnClick: false },
      Mention: { suggestion: mentionSuggestion },
      Tag: { suggestion: tagSuggestion },
      Emoji: { suggestion: emojiSuggestion, getEmoji: this.getEmoji, emojiIndex: this.emojiIndex },
      Image: { onError: this.onImageUploadError },
    }, this.disabledExtensions);
    this.editor = getTipTapEditor(
      this.modelValue,
      !this.disabled,
      extensions,
      {
        editorProps: {
          attributes: {
            class: this.editorStyle,
          },
          handleClickOn: (view, pos, node) => {
            if (this.disabled && node.type.name === 'custom-image') {
              this.$emit('image-click', node.attrs.src);

              return true;
            }

            return false;
          },
        },
        onUpdate: () => {
          const html = this.editor.isEmpty ? '' : this.editor.getHTML();
          this.$emit('update:model-value', html);
          this.$emit('json-input', this.editor.getJSON());
        },
      },
    );
  },

  beforeUnmount() {
    this.editor.destroy();
  },

  methods: {
    queryUsers({ query }) {
      return this.users
        .filter(user => user.username.toLowerCase().includes(query.toLowerCase())).slice(0, MAX_SUGGESTIONS);
    },
    queryTags({ query }) {
      const tags = this.teamTagList
        .filter(tag => tag.toLowerCase().includes(query.toLowerCase()));

      if (query && !tags.includes(query)) tags.unshift(query);

      return tags.slice(0, MAX_SUGGESTIONS);
    },
    queryEmojis({ query }) {
      const emojis = this.emojiIndex;

      if (query) {
        const result = emojis.search(query) || [];

        return result.slice(0, MAX_SUGGESTIONS);
      }

      const { _emojis: allEmojis } = emojis;

      return Object.values(allEmojis).slice(0, MAX_SUGGESTIONS);
    },
    getEmoji(emojiId, emojiSkin) {
      return this.$store.getters.getEmoji(emojiId, emojiSkin);
    },
    onError(errors) {
      let errorMessage = null;
      if (isString(errors)) {
        errorMessage = errors;
      } else if (isArray(errors)) {
        errorMessage = errors.join('\n');
      }

      this.error = errorMessage;
      setInterval(() => {
        this.error = '';
      }, this.errorMessageTime);
    },
    onImageUploadError(errors) {
      this.onError(errors.map(error => this.t(`editor.image.${error}`)));
    },
  },
};
</script>

<style lang="scss">
.ProseMirror {
  & p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    @apply text-gray-500 float-left pointer-events-none h-0;
  }

  &[contenteditable="true"] {
    & action-text-attachment.ProseMirror-selectednode img {
      @apply p-2 border rounded border-gray-400 bg-gray-100 drop-shadow;
    }
  }
}
</style>
