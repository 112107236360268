import amplitude from 'amplitude-js';
import moment from 'moment';

const INITIALIZED_AT_KEY = 'initializedAt';
const HOURS_UNTIL_MUTE_IS_OLD = 2;

export function initializeAmplitude() {
  if (!process.env.AMPLITUDE_API_KEY) return;

  amplitude.getInstance().init(process.env.AMPLITUDE_API_KEY);
}

export function logAmplitudeEvent(eventName, params = {}) {
  if (!process.env.AMPLITUDE_API_KEY) return;

  amplitude.getInstance().logEvent(eventName, params);
}

export function setAmplitudeUserId(userId) {
  amplitude.getInstance().setUserId(userId);
}

export function logInitAmplitudeEvent(teamId) {
  const initializedAt = moment(localStorage.getItem(INITIALIZED_AT_KEY));
  const now = moment();

  if (initializedAt.day() !== now.day()) {
    logAmplitudeEvent('initialize', { teamId });
    localStorage.setItem(INITIALIZED_AT_KEY, now.toISOString());
  }
}

export function logCheckOldMuteEvent(document, teamId) {
  const { datetime } = document;
  if (moment().subtract(HOURS_UNTIL_MUTE_IS_OLD, 'h').isAfter(moment(datetime))) {
    logAmplitudeEvent('open-old-mute', { documentId: document.id, teamId });
  }
}
