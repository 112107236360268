<template>
  <div class="flex flex-col grow overflow-y-auto bg-white">
    <div
      class="flex flex-row justify-between items-center w-screen p-2 bg-slate-100 text-xs font-bold text-gray-400 border-b border-gray-200 max-h-full z-0 lg:w-auto relative top-0"
    >
      {{ $t(`meetings.announcement.unreadMessages`) }}
      <a
        v-if="!isEmpty"
        href="#"
        class="text-teal-600 font-normal underline"
        @click="markAllSectionsAsRead"
      >
        {{ $t(`meetings.announcement.markAllMessagesAsRead`) }}
      </a>
    </div>
    <div
      v-if="isEmpty"
      class="flex items-center justify-center w-full h-full text-gray-600"
    >
      {{ $t(`meetings.announcement.noUnreadMessages`) }}
    </div>
    <div
      v-else
      class="bg-white"
    >
      <template
        v-for="(section, sectionId) in unread"
        :key="`${sectionId}-container`"
      >
        <div v-if="!mutedSections[sectionId]">
          <h2
            :key="`title-${sectionId}`"
            class="flex items-center px-2 py-1 text-xl text-green-500 bg-gray-50"
          >
            {{ section.title }}
            <button
              class="px-2 py-px ml-auto text-sm text-gray-500 bg-white border border-gray-500 rounded shadow"
              @click="markSectionAsRead(sectionId)"
            >
              {{ $t(`meetings.announcement.markSectionAsRead`) }}
            </button>
          </h2>
          <transition-group
            name="list"
            tag="div"
            class="cursor-pointer"
          >
            <template
              v-for="announcement in sortAnnouncementsByDate(section.unreadAnnouncements)"
              :key="announcement.id"
            >
              <announcement
                :class="{
                  'bg-blue-100': isNew(announcement.createdAt, mountedDate)
                }"
                :announcement="announcement"
                :author="announcement.author"
                :text="announcement.body"
                class="w-full border-b"
                :are-hover-bar-items-allowed="false"
                @change-announcement="goToAnnouncement(sectionId, announcement.id)"
              />
              <transition-group
                v-if="announcement.unreadComments"
                name="list"
                tag="div"
              >
                <comment
                  v-for="comment in sortCommentsByDate(announcement.unreadComments)"
                  :key="comment.commentId"
                  :comment="comments[comment.commentId]"
                  :section-id="sectionId"
                  class="w-full pl-10 -mt-px bg-white"
                  :class="{'bg-blue-100': isNew(comments[comment.commentId].createdAt, mountedDate) }"
                  @click="goToAnnouncement(sectionId, announcement.id)"
                />
              </transition-group>
            </template>
          </transition-group>
        </div>
      </template>
      <emoji-picker />
    </div>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy';
import Announcement from '../components/announcement/announcement.vue';
import Comment from './comments-section/comment.vue';
import EmojiPicker from '../components/shared/emoji-picker.vue';
import unreadMessagesApi from '../api/unread-messages';

export default {
  components: {
    Announcement,
    Comment,
    EmojiPicker,
  },
  data() {
    return {
      mountedDate: null,
    };
  },
  computed: {
    documentId() {
      return this.$store.state.document.document.id;
    },
    sections() {
      return this.$store.state.document.sections || {};
    },
    mutedSections() {
      return this.$store.state.document.mutedSections || {};
    },
    announcements() {
      return this.$store.state.document.announcements || {};
    },
    mutedAnnouncements() {
      return this.$store.state.document.mutedAnnouncements || {};
    },
    comments() {
      return this.$store.state.document.comments || {};
    },
    unreadComments() {
      return this.$store.state.document.unreadComments.filter(
        unreadComment => !this.mutedAnnouncements[unreadComment.announcementId] &&
                              !this.mutedSections[unreadComment.sectionId],
      );
    },
    unreadAnnouncements() {
      return this.$store.state.document.unreadAnnouncements.filter(
        unreadAnnouncement => !this.mutedAnnouncements[unreadAnnouncement] &&
                              !this.mutedSections[unreadAnnouncement.sectionId],
      );
    },
    unread() {
      return [...this.unreadAnnouncements, ...this.unreadComments].reduce(
        (prev, curr) => {
          prev[curr.sectionId] = prev[curr.sectionId] || { ...this.sections[curr.sectionId] };
          prev[curr.sectionId].unreadAnnouncements = prev[curr.sectionId].unreadAnnouncements || {};
          prev[curr.sectionId].unreadAnnouncements[curr.announcementId] = prev[
            curr.sectionId
          ].unreadAnnouncements[curr.announcementId] || {
            ...this.announcements[curr.announcementId],
          };

          if (curr.commentId) {
            prev[curr.sectionId].unreadAnnouncements[curr.announcementId].unreadComments =
              prev[curr.sectionId].unreadAnnouncements[curr.announcementId].unreadComments || [];
            prev[curr.sectionId].unreadAnnouncements[curr.announcementId].unreadComments.push(curr);
          }

          return prev;
        },
        {},
      );
    },
    isEmpty() {
      return this.unreadComments.length + this.unreadAnnouncements.length === 0;
    },
  },
  mounted() {
    this.mountedDate = new Date();
  },
  methods: {
    showHeader(a, b, item) {
      if (b) {
        return a && b && a[item] !== b[item];
      }

      return true;
    },
    goToAnnouncement(sectionId, announcementId) {
      this.$router.push(`/sections/${sectionId}/${announcementId}`);
      this.markSectionAsRead(sectionId);
    },
    markAllSectionsAsRead() {
      unreadMessagesApi.markAllMessagesAsRead(this.documentId);
      Object.keys(this.unread).forEach(this.markSectionAsReadInStore);
    },
    markSectionAsRead(sectionId) {
      unreadMessagesApi.markSectionMessagesAsRead(this.documentId, sectionId);
      this.markSectionAsReadInStore(sectionId);
    },
    markSectionAsReadInStore(sectionId) {
      this.$store.dispatch('markSectionAsRead', sectionId);
      this.unreadComments.forEach((c) => {
        if (c.sectionId === sectionId) {
          this.$store.dispatch('markAnnouncementAsRead', c.announcementId);
        }
      });
    },
    isNew(dateString, date) {
      const commentDate = new Date(dateString);

      return commentDate > date;
    },
    sortAnnouncementsByDate(announcements) {
      const announcementsValues = Object.values(announcements);
      const sortedAnnouncements = orderBy(announcementsValues, (item) => new Date(item.createdAt), ['desc']);

      return sortedAnnouncements;
    },
    sortCommentsByDate(comments) {
      const sortedComments = orderBy(comments, (item) => item.commentId, ['desc']);

      return sortedComments;
    },
  },
};
</script>

<style>
.list-enter-active,
.list-leave-active {
  @apply transition-all duration-500;
}

.list-enter,
.list-leave-to {
  @apply opacity-0 translate-y-6;
}
</style>
