<template>
  <tip-tap-editor
    disabled
    hide-toolbar
    :model-value="richText"
    @image-click="onImageClick"
  />
</template>

<script>
import { createPopper } from '@popperjs/core';
import { isNil } from 'lodash';
import TipTapEditor from './tiptap/tiptap.vue';

export default {
  components: {
    TipTapEditor,
  },
  props: {
    richText: {
      type: String,
      required: true,
    },
  },
  emits: ['open-img-modal'],
  data() {
    return {
      popperInstance: null,
    };
  },
  computed: {
    emojis() {
      const { _emojis: emojis } = this.$store.state.emoji.emojiIndex;

      return emojis;
    },
  },
  updated() {
    this.$nextTick().then(() => {
      this.createIconTooltip();
    });
  },
  mounted() {
    this.$nextTick().then(() => {
      this.createIconTooltip();
    });
  },
  methods: {
    onImageClick(src) {
      this.$emit('open-img-modal', src);
    },
    emojiImage(emojiName, emojiSkin) {
      const emoji = this.$store.getters.getEmoji(emojiName, emojiSkin);

      if (emoji.native) {
        return emoji.native;
      }

      return `<span class="trix-content__custom-emoji" style="background-image:url('${emoji.imageUrl}')"></span>`;
    },
    createIconTooltipInnerHtml(emojiId, emojiSkin) {
      const emojiImageResult = this.emojiImage(emojiId, emojiSkin);
      let name = emojiId;
      if (!isNil(emojiSkin)) {
        name += `::${emojiSkin}`;
      }

      return `
      <span class="flex flex-col items-center justify-center px-2 py-2 text-xs text-white">
        <span class="flex items-center justify-center w-8 h-8 mx-2 mb-1 text-2xl text-black bg-white rounded">
          ${emojiImageResult}
        </span>
        <span>:${name}:</span>
      </span>
      <span class="arrow" data-popper-arrow />`;
    },
    createIconTooltip() {
      const emojis = this.$el.querySelectorAll('.emoji-icon');
      const iconTooltip = document.querySelector('#icon-tooltip');
      emojis.forEach((emojiButton) => {
        const [emojiId, emojiSkin] = emojiButton.dataset.id.split('::');

        const show = () => {
          if (this.popperInstance) {
            this.popperInstance.destroy();
            this.popperInstance = null;
          }
          iconTooltip.innerHTML = this.createIconTooltipInnerHtml(emojiId, emojiSkin);

          this.popperInstance = createPopper(emojiButton, iconTooltip, {
            modifiers: [
              {
                name: 'offset',
                options: {
                  // eslint-disable-next-line no-magic-numbers
                  offset: [0, 8],
                },
              },
            ],
          });
          iconTooltip.setAttribute('data-show', '');
          this.$nextTick(() => {
            this.popperInstance.update();
          });
        };

        function hide() {
          iconTooltip.innerHTML = '';
          if (this.popperInstance) {
            this.popperInstance.destroy();
            this.popperInstance = null;
          }
          iconTooltip.removeAttribute('data-show');
        }

        const showEvents = ['mouseenter', 'focus'];
        const hideEvents = ['mouseleave', 'blur'];

        showEvents.forEach((event) => {
          emojiButton.addEventListener(event, show);
        });

        hideEvents.forEach((event) => {
          emojiButton.addEventListener(event, hide);
        });
      });
    },
  },
};
</script>

<style lang="scss">
.trix-content {
  & img {
    @apply cursor-pointer;
    @apply max-w-full;
    @apply object-contain;
    @apply object-left;
    max-height: 45vh;

    &[width="small"] {
      @apply max-w-[200px];
    }
    &[width="medium"] {
      @apply max-w-[500px];
    }
    &[width="large"] {
      @apply max-w-full;
    }
  }

  & action-text-attachment {
    @apply block;
  }

  &__custom-emoji {
    @apply inline-block w-6 h-6 bg-center bg-no-repeat bg-contain;
  }
}

#icon-tooltip {
  display: none;
}

#icon-tooltip[data-show] {
  display: block;
}
.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

#icon-tooltip[data-popper-placement^='top'] > .arrow {
  bottom: -4px;
}

#icon-tooltip[data-popper-placement^='bottom'] > .arrow {
  top: -4px;
}

#icon-tooltip[data-popper-placement^='left'] > .arrow {
  right: -4px;
}

#icon-tooltip[data-popper-placement^='right'] > .arrow {
  left: -4px;
}
</style>
