<template>
  <div
    class="relative flex flex-row justify-between mb-2"
  >
    <div
      ref="toolbar"
      class="flex flex-row"
    >
      <div
        v-for="(group, index) in buttonGroups"
        :key="index"
        class="flex flex-row"
        :class="{'ml-2': index > 0}"
      >
        <mute-button
          v-for="(button, buttonIndex) in group"
          :key="buttonIndex"
          v-tooltip="button.tooltip"
          borderless
          variant="white"
          class="border"
          :icon-file-name="button.iconFileName"
          size="sm"
          toolbar
          :disabled="!editor || buttonDisabled(button)"
          :class="[{
            'hover:text-black hover:bg-gray-200': !buttonDisabled(button),
            'bg-gray-200': button.isActive && button.isActive(),
            'rounded-l-md': buttonIndex === 0,
            'rounded-r-md': buttonIndex === group.length - 1,
          }]"
          @click="button.onClick()"
        />
      </div>
      <link-popper
        ref="linkPopper"
        :editor="editor"
        :toolbar-ref="$refs.toolbar"
      />
      <image-input
        ref="imageInput"
        @file-uploaded="addImage"
        @file-upload-progress="onImageUploadProgress"
        @error="onError"
      />
    </div>
    <indicator
      v-if="allowIndicator"
      class="absolute -right-10 md:-right-12 p-1"
      :disabled="disabledIndicator"
      :resource="resource"
    />
  </div>
</template>

<script>
import { omit } from 'lodash';

import getButtonGroups from './buttons';
import linkPopper from './linkPopper.vue';
import ImageInput from '../extensions/image/ImageInput.vue';
import Indicator from './indicator';

const MAX_PROGRESS = 100;

export default {
  components: { linkPopper, ImageInput, Indicator },
  props: {
    editor: { type: Object, default: null },
    hiddenButtons: { type: Array, default: () => [] },
    disabledIndicator: { type: Boolean, default: false },
    resource: { type: String, default: '' },
    allowIndicator: { type: Boolean, default: false },
  },
  emits: ['error'],
  data() {
    return {
      buttonGroups: [],
      imageUploading: false,
    };
  },
  watch: {
    editor() {
      this.mountButtons();
    },
  },
  mounted() {
    this.mountButtons();
  },
  methods: {
    mountButtons() {
      const buttonGroups = getButtonGroups(this.editor, {
        link: { toggle: this.toggleLinkPopper },
        image: { onClick: this.startImageUpload, disabled: this.attachmentDisabled },
      });

      this.buttonGroups = buttonGroups
        .map((group) => Object.values(omit(group, this.hiddenButtons)))
        .filter((group) => group.length > 0);
    },
    buttonDisabled(button) {
      if (!this.editor || !this.editor.isEditable) return true;

      return button.disabled && button.disabled();
    },
    toggleLinkPopper() {
      this.$refs.linkPopper.toggleLinkPopper();
    },
    startImageUpload() {
      this.$refs.imageInput.clickImageUpload();
    },
    addImage(attributes) {
      this.editor.chain().focus().setImage(attributes)
        .run();
    },
    onImageUploadProgress(progress) {
      this.imageUploading = progress > 0 && progress < MAX_PROGRESS;
    },
    attachmentDisabled() {
      return this.imageUploading;
    },
    onError(error) {
      this.$emit('error', error);
    },
  },
};
</script>
