import { serialize } from 'object-to-formdata';
import api from './index';

const path = '/api/internal/attachments';

const percentageConverter = 100;

export default {
  uploadAttachment(body, attachment) {
    return api({
      method: 'post',
      url: path,
      data: serialize(body),
      onUploadProgress: (progress) => {
        attachment.setUploadProgress(progress.loaded / progress.total * percentageConverter);
      },
    });
  },
};
