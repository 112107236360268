<template>
  <div class="flex flex-col w-full">
    <comment
      v-for="id in sortedComments"
      :id="`comment-${id}`"
      :key="id"
      :comment="comments[id]"
      :section-id="sectionId"
      :is-new="firstUnreadCommentId === id"
    />
    <div
      v-if="!commentsAllowed"
      class="m-2"
    >
      <mute-banner kind="warning">
        {{ $t('meetings.comment.draftComment') }}
      </mute-banner>
    </div>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy';
import Comment from './comment.vue';

export default {
  components: {
    Comment,
  },
  props: {
    thread: {
      type: Object,
      required: true,
    },
    sectionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      open: true,
    };
  },
  computed: {
    sortedComments() {
      return orderBy(
        this.thread.comments,
        [commentId => new Date(this.comments[commentId].createdAt)],
        ['asc'],
      );
    },
    comments() {
      return this.$store.state.document.comments;
    },
    firstUnreadCommentId() {
      const unreadIds = this.$store.state.document.unreadComments.map(unread => Number(unread.commentId));

      return this.sortedComments.find(commentId => unreadIds.includes(commentId));
    },
    commentsAllowed() {
      return this.$store.getters.commentsAllowed();
    },
  },
  methods: {
    toggleOpen() {
      this.open = !this.open;
    },
  },
};
</script>
