<template>
  <img
    :src="src"
    class="object-cover rounded shadow"
    :class="sizeClasses"
  >
</template>

<script>
export default {
  props: {
    src: { type: String, required: true },
    size: { type: String, default: 'medium' },
  },
  computed: {
    sizeClasses() {
      const SIZE_CLASSES = {
        'extra-large': 'w-10 h-10 lg:w-24 lg:h-24',
        'large': 'w-10 h-10 lg:w-16 lg:h-16',
        'medium': 'w-6 h-6',
        'small': 'w-4 h-4',
      };

      return SIZE_CLASSES[this.size] || SIZE_CLASSES.medium;
    },
  },
};
</script>
