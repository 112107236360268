<template>
  <div>
    <div
      v-if="editing"
      @keydown.enter.ctrl="sendUpdate"
      @keydown.enter.meta="sendUpdate"
    >
      <tip-tap-editor
        v-model="newContent"
        input-id="edit"
        type="text"
        class="w-full p-2 mb-2"
        bordered
        @json-input="setNewContentJson"
      />
      <div class="flex justify-end">
        <mute-button
          class="mr-2"
          variant="white"
          @click.stop="$emit('cancel-edit')"
        >
          {{ $t('meetings.textEditor.cancel') }}
        </mute-button>
        <mute-button
          :disabled="!newContent"
          @click.stop="sendUpdate"
        >
          {{ $t('meetings.textEditor.saveChanges') }}
        </mute-button>
      </div>
    </div>
    <RichContent
      v-else
      :rich-text="text"
      @open-img-modal="openZoom"
    />
    <base-modal
      :open="isImageModalOpen"
      @close="isImageModalOpen = false"
    >
      <template #header>
        <div class="flex">
          <img
            v-if="hasImage"
            class="self-start shrink-0 object-cover w-12 h-12 rounded-lg shadow-md"
            :src="author.avatar"
            alt="avatar"
          >
          <div
            v-if="author.username"
            class="flex-1 ml-4 font-bold text-purple-500"
          >
            {{ author.username }}
          </div>
          <div class="flex items-center">
            <a
              :href="zoomedImg"
              target="_blank"
            >
              <inline-svg
                class="w-8 h-8 mr-3 text-gray-500 cursor-pointer fill-current hover:text-gray-600"
                :src="require('assets/images/icons/open-external-icon.svg')"
              />
            </a>
            <button @click="downloadImg">
              <inline-svg
                class="w-8 h-8 mr-3 text-gray-500 cursor-pointer fill-current hover:text-gray-600"
                :src="require('assets/images/icons/download-icon.svg')"
              />
            </button>
            <button @click="closeZoom">
              <inline-svg
                class="w-8 h-8 text-gray-500 cursor-pointer fill-current hover:text-gray-600"
                :src="require('assets/images/icons/close-icon.svg')"
              />
            </button>
          </div>
        </div>
        <mute-divider
          class="my-3"
          width="w-auto"
        />
      </template>
      <div
        v-if="!!error"
        class="mt-5 text-center text-red-500"
      >
        {{ error }}
      </div>
      <v-zoomer
        ref="zoomer"
        :max-scale="10"
      >
        <img
          alt="zommed-image"
          :src="zoomedImg"
          class="min-w-[400px] m-auto max-h-[600px] object-contain"
        >
      </v-zoomer>
      <div
        class="flex justify-center"
      >
        <mute-button
          icon-file-name="zoom-minus-icon.svg"
          size="sm"
          variant="purple"
          class="m-2"
          only-icon
          @click="$refs.zoomer.zoomOut()"
        />
        <mute-button
          icon-file-name="zoom-plus-icon.svg"
          size="sm"
          variant="purple"
          class="m-2"
          only-icon
          @click="$refs.zoomer.zoomIn()"
        />
      </div>
    </base-modal>
  </div>
</template>

<script>
import RichContent from './rich-content.vue';
import DownloadRequest from '../../api/download-img';
import TipTapEditor from '../shared/tiptap/tiptap.vue';
import getTags from './tiptap/helpers/getTags';

export default {
  components: {
    RichContent,
    TipTapEditor,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    editing: {
      type: Boolean,
      required: true,
    },
    contentType: {
      type: String,
      required: true,
    },
    contentId: {
      type: Number,
      required: true,
    },
    author: {
      type: Object,
      required: true,
    },
  },
  emits: ['cancel-edit', 'confirm-edit'],
  data() {
    return {
      newContent: this.text,
      newContentJson: {},
      zoomedImg: null,
      error: '',
      isImageModalOpen: false,
    };
  },
  computed: {
    hasImage() {
      return !!this.author.avatar;
    },
  },
  watch: {
    text(newVal) {
      this.newContent = newVal;
    },
  },
  methods: {
    sendUpdate() {
      const tags = getTags(this.newContentJson);
      this.$emit('confirm-edit', this.newContent, tags);
    },
    openZoom(imageUrl) {
      this.zoomedImg = imageUrl;
      this.isImageModalOpen = true;
    },
    closeZoom() {
      this.isImageModalOpen = false;
      this.zoomedImg = null;
      this.error = '';
    },
    downloadImg() {
      let name = this.zoomedImg.split('/');
      name = name[name.length - 1];
      DownloadRequest.downloadImg(this.zoomedImg)
        .then(response => {
          this.forceImgDownload(response, name);
        })
        .catch(() => {
          this.error = 'Ha ocurrido un error, vuelva a intentarlo más tarde';
        });
    },
    forceImgDownload(response, name) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      link.click();
      this.closeZoom();
    },
    setNewContentJson(newContentJson) {
      this.newContentJson = newContentJson;
    },
  },
};
</script>
