/**
 * This provides common functionality for tiptap mention list components.
 * A method `itemLabel` may be overridden to provide a custom way of computing
 * the item's label.
 * @mixin
 */
const SuggestionMixin = {
  props: {
    items: {
      type: Array,
      required: true,
    },
    command: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      selectedIndex: 0,
      firstItem: 0,
      lastItem: 3,
    };
  },

  watch: {
    items: {
      handler() {
        this.selectedIndex = 0;
      },
      deep: true,
    },
  },

  methods: {
    onKeyDown({ event }) {
      return (
        this.verticalHandler(event) ||
        this.enterHandler(event)
      );
    },

    verticalHandler(event) {
      const { key } = event;
      if (key !== 'ArrowUp' && key !== 'ArrowDown') return false;

      if (event.key === 'ArrowUp') {
        this.upHandler();
      } else if (event.key === 'ArrowDown') {
        this.downHandler();
      }

      return true;
    },

    enterHandler(event) {
      const { key } = event;
      if (key !== 'Enter' && key !== 'Tab') return false;

      this.selectItem(this.selectedIndex);

      return true;
    },

    upHandler() {
      const newIndex = this.selectedIndex - 1;
      if (newIndex >= 0) {
        this.selectedIndex = newIndex;
        if (this.selectedIndex < this.firstItem) {
          document.getElementById(this.selectedIndex).scrollIntoView();
          this.updateUpItems();
        }
      }
    },

    downHandler() {
      const newIndex = this.selectedIndex + 1;
      if (newIndex < this.items.length) {
        this.selectedIndex = newIndex;
        if (this.selectedIndex > this.lastItem) {
          document.getElementById(this.firstItem + 1).scrollIntoView();
          this.updateDownItems();
        }
      }
    },
    updateDownItems() {
      this.firstItem += 1;
      this.lastItem += 1;
    },
    updateUpItems() {
      this.firstItem -= 1;
      this.lastItem -= 1;
    },
    resetItems() {
      this.firstItem = 0;
      this.lastItem = 3;
      const firstItem = document.getElementById(this.firstItem);
      if (firstItem) firstItem.scrollIntoView();
    },

    selectItem(index) {
      const item = this.items[index];

      if (item) {
        this.command({ id: this.itemLabel(item) });
      }
    },

    itemLabel(item) {
      return item;
    },
  },
};

export default SuggestionMixin;
