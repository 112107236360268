<template>
  <button
    class="flex items-center justify-center p-1 text-sm leading-none transition-all duration-150 ease-in-out border border-transparent rounded-full shadow cursor-pointer select-none"
    :class="{
      'bg-white/50 text-gray-700 hover:bg-purple-200/50 hover:text-purple-500': !active,
      'border-purple-500 bg-purple-200/50 hover:bg-purple-300/50 text-purple-500': active,
    }"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    active: { type: Boolean, default: false },
  },
};
</script>
