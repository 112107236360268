<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="flex w-full h-full cursor-pointer"
    @click="navigateToAnnouncementOrComment"
  >
    <img
      v-if="authorImg && authorImg.length"
      class="self-start shrink-0 object-cover w-10 h-10 mr-4 rounded-lg shadow-md "
      :src="authorImg"
    >
    <div class="flex flex-col flex-1 min-w-0 break-words">
      <div class="flex flex-1 mb-2">
        <div class="flex flex-col flex-1 min-w-0">
          <div
            class="flex items-center font-bold text-purple-500"
          >
            <span> {{ author }} </span>
            <inline-svg
              class="w-4 h-4 ml-auto text-gray-500 fill-current hover:text-gray-600"
              :src="require('assets/images/icons/close-icon.svg')"
              @click.stop="$emit('close')"
            />
          </div>
          <div
            v-html="notificationHtml(text)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const MAX_CHARS_NOTIFICATION = 500;

export default {
  props: {
    text: { type: String, required: true },
    author: { type: String, required: true },
    authorImg: { type: String, required: true },
    sectionId: { type: [Number, String], required: true },
    announcementId: { type: [Number, String], required: true },
  },
  emits: ['close'],
  methods: {
    navigateToAnnouncementOrComment() {
      const route = `/sections/${this.sectionId}/${this.announcementId}`;
      this.$emit('close');
      this.$router.push(route);
    },
    notificationHtml(text) {
      if (text.length > MAX_CHARS_NOTIFICATION) return `${text.slice(0, MAX_CHARS_NOTIFICATION)}...`;

      return text;
    },
  },
};
</script>

