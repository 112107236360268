<template>
  <div class="fixed right-0 flex flex-1 w-screen h-screen pb-28 lg:pb-0 lg:z-auto lg:top-0 lg:w-auto top-28 lg:relative lg:h-full">
    <div
      ref="announcementsContainer"
      class="flex flex-col grow overflow-y-auto bg-white"
    >
      <div class="flex flex-row justify-between items-center w-screen p-2 bg-slate-100 text-xs font-bold text-gray-400 border-b border-gray-200 max-h-full z-0 lg:w-auto relative top-0">
        {{ $t('meetings.announcement.saved') }}
      </div>
      <div v-if="activeSavedAnnouncements.length > 0">
        <announcement
          v-for="announcement in activeSavedAnnouncements"
          :id="`announcement-${announcement.id}`"
          :key="announcement.id"
          class="justify-start"
          :announcement="announcement"
          :author="announcements[announcement.id].author"
          :text="announcements[announcement.id].body"
          :selected-section-id="announcement.section"
          :is-saved="true"
          @change-announcement="goToAnnouncement(announcement.section, announcement.id)"
        />
      </div>
      <p
        v-else
        class="flex items-center justify-center w-full h-full p-4 text-gray-600"
      >
        {{ $t('meetings.announcement.noSavedAnnouncements') }}
      </p>
    </div>
    <emoji-picker />
  </div>
</template>

<script>
import Announcement from './announcement/announcement.vue';
import EmojiPicker from '../components/shared/emoji-picker';

export default {
  components: {
    Announcement,
    EmojiPicker,
  },
  computed: {
    sections() {
      return this.$store.state.document.sections || {};
    },
    announcements() {
      return this.$store.state.document.announcements || {};
    },
    savedAnnouncementsSections() {
      return this.$store.state.document.savedAnnouncementsByDocument[this.documentId] || {};
    },
    documentId() {
      return this.$store.state.document.document.id;
    },
    activeSavedAnnouncements() {
      const activeAnnouncements = [];

      Object.entries(this.savedAnnouncementsSections).forEach(([section, announcementsId]) => {
        announcementsId.forEach(id => {
          if (id in this.announcements) {
            activeAnnouncements.push({ 'id': id, 'section': section });
          }
        });
      });

      return activeAnnouncements;
    },
  },
  methods: {
    goToAnnouncement(sectionId, announcementId) {
      this.$router.push(`/sections/${sectionId}/${announcementId}`);
    },
  },
};
</script>
