<template>
  <div
    class="w-full"
    :class="$attrs.class"
  >
    <div
      class="w-full"
      :class="[sizeClasses.mainContainerSizes, {'bg-gray-100': disabled}]"
    >
      <input
        v-bind="filteredAttrs"
        ref="input"
        :value="modelValue"
        :disabled="disabled"
        :readonly="readonly"
        :placeholder="isSmall ? label : undefined"
        class="w-full border rounded-lg outline-none"
        :class="[sizeClasses.inputSizes, inputColorClasses]"
        @focus="focus"
        @click="focus"
        @blur="blur"
        @input="(event) => $emit('update:modelValue', event.target.value)"
      >
      <mute-input-label
        v-if="label"
        :label="label"
        :size="size"
        :value="modelValue"
        :focus="focus"
        :focused="focused"
        :has-error="hasError"
      />
    </div>
    <p
      v-if="hasError"
      class="mt-1 text-xs text-red-500 cursor-text"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import useFilteredAttrs from '../../composables/useFilteredAttrs';
import MuteInputLabel from './mute-input-label';
import { MuteInputClassesMixin, MuteInputFocusMixin } from './mixins/mute-input';

export default {
  components: {
    MuteInputLabel,
  },
  mixins: [MuteInputClassesMixin, MuteInputFocusMixin],
  inheritAttrs: false,

  props: {
    modelValue: { type: String, default: null },
    label: { type: String, default: null },
    focusedOnMount: { type: Boolean, default: false },
    size: { type: String, default: 'large' },
    disabled: { type: Boolean, default: false },
    hasError: { type: Boolean, default: false },
    errorMessage: { type: String, default: null },
    readonly: { type: Boolean, default: false },
  },
  emits: ['update:modelValue'],
  setup() {
    const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });

    return { filteredAttrs };
  },
  computed: {
    inputColorClasses() {
      if (this.hasError) return 'border-red-500 focus:border-red-600 placeholder-red-300';

      return ({
        'border-gray-500 focus:border-purple-500 ': !this.disabledOrReadonly,
        'border-gray-400 focus:border-gray-400 text-gray-400 ': this.disabledOrReadonly,
      });
    },
    disabledOrReadonly() {
      return this.disabled || this.readonly;
    },
  },
  mounted() {
    if (this.focusedOnMount) {
      this.focus();
    }
  },
};
</script>
