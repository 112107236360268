<template>
  <div class="relative flex items-center w-full lg:z-10">
    <div
      class="flex-1 border-t"
      :class="separatorClasses"
      :style="{ marginRight }"
    />
    <span
      ref="text"
      class="absolute right-0 px-1 py-0.5 mr-3 leading-none bg-white rounded-md"
      :class="textClasses"
    >
      {{ showText }}
    </span>
  </div>
</template>
<script>
import { useI18n } from 'vue-i18n';

export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    elementId: {
      type: Number,
      required: true,
    },
    textClasses: {
      type: String,
      default: 'text-red-600',
    },
    separatorClasses: {
      type: String,
      default: 'border-red-600',
    },
  },
  emits: ['scroll-to-element'],
  setup() {
    const { t } = useI18n();

    return { t };
  },
  data() {
    return {
      textWidth: 0,
    };
  },
  computed: {
    marginRight() {
      return this.text && `calc(${this.textWidth}px + 1rem)` || '0';
    },
    showText() {
      return this.text.length ? this.text : this.t('meetings.announcement.new');
    },
  },
  mounted() {
    this.textWidth = this.$refs.text.clientWidth;
    this.$emit('scroll-to-element', this.elementId);
  },
};
</script>
