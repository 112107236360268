import api from './index';

export default {
  searchEntitiesInDocument(teamId, documentId, queryParams) {
    return api({
      method: 'get',
      url: `/api/internal/documents/${documentId}/entities`,
      params: { teamId, q: queryParams },
    });
  },
  searchAllEntities(teamId, queryParams) {
    return api({
      method: 'get',
      url: '/api/internal/entities',
      params: { teamId, q: queryParams },
    });
  },
};
