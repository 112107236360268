<template>
  <input
    ref="imgInput"
    type="file"
    accept="image/*"
    hidden
    @change="handleImageAdd"
  >
</template>

<script>
import { useI18n } from 'vue-i18n';
import { handleImageUpload, DEFAULT_MAX_FILE_SIZE } from '../../helpers/image';

const MAX_PROGRESS = 100;

export default {
  props: {
    maxFileSize: {
      type: Number,
      default: DEFAULT_MAX_FILE_SIZE,
    },
  },
  emits: ['error', 'file-uploaded', 'file-upload-progress', 'file-upload-progress'],
  setup() {
    const { t } = useI18n();

    return { t };
  },
  data() {
    return {
      errors: [],
    };
  },
  methods: {
    clickImageUpload() {
      this.$refs.imgInput.click();
    },
    async handleImageAdd(event) {
      const file = event.target.files[0];
      const result = await handleImageUpload(file, {
        setUploadProgress: this.setUploadProgress,
        maxFileSize: this.maxFileSize,
      });

      if (!result) return;

      const { attributes, errors } = result;

      if (errors.length) {
        const errorMsgs = errors.map(error => this.t(`editor.image.${error}`));
        this.$emit('error', errorMsgs);
      } else {
        this.$emit('file-uploaded', attributes);
      }
    },
    setUploadProgress(progress) {
      if (progress === MAX_PROGRESS) {
        this.$emit('file-upload-progress', 0);
      } else {
        this.$emit('file-upload-progress', progress);
      }
    },
  },
};
</script>
