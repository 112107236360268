import imageApi from '../../../../api/attachments';

export const ERROR_TYPES = {
  typeError: 'typeError',
  sizeError: 'sizeError',
  uploadError: 'uploadError',
};

// eslint-disable-next-line no-magic-numbers
export const DEFAULT_MAX_FILE_SIZE = 1024 * 1024 * 2;
function noop() {
  return null;
}

/**
 * Validates that the file is an image and that it is within the max file size
 * @param {File} file - The file to validate
 * @param {Number} maxFileSize - The max file size in bytes
 * @returns {Promise<Array<String>>} An array containing rejection reasons. Can include
 * the following reasons:
 * - typeError: The file is not an image
 * - sizeError: The file is too large
 */
function validateImage(file, maxFileSize = DEFAULT_MAX_FILE_SIZE) {
  const acceptedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml', 'image/gif'];

  const acceptedByType = acceptedTypes.includes(file.type);
  const acceptedBySize = file.size <= maxFileSize;

  const errors = [];
  if (!acceptedByType) {
    errors.push(ERROR_TYPES.typeError);
  }
  if (!acceptedBySize) {
    errors.push(ERROR_TYPES.sizeError);
  }

  return errors;
}

/**
 * Handles the file uploading to the server
 * @param {File} file - The file to upload
 * @param {Function} setUploadProgress - Callback to set the upload progress
 * @returns {Promise<{attributes: Object, errors: Array<String>}>} A promise that resolves
 * with the file attributes if it is accepted, or an array of errors if it is rejected.
 */
async function uploadAttachment(file, setUploadProgress = noop) {
  return imageApi.uploadAttachment({ attachment: file }, { file, setUploadProgress })
    .then((response) => {
      const res = response.data.attachment;
      const attributes = {
        url: res.attachmentUrl,
        contentType: file.type,
        filename: file.name,
        fileSize: file.size,
      };

      return { attributes, errors: [] };
    })
    .catch(() => ({ attributes: {}, errors: [ERROR_TYPES.uploadError] }));
}

/**
 * Handles file validation and uploading
 * @param {File} file - The file to validate and upload
 * @param {Object} options - Options for the file upload
 * @param {Function} options.setUploadProgress - Callback to set the upload progress
 * @param {Number} options.maxFileSize - The max file size in bytes
 * @returns {Promise<{attributes: Object, errors: Array<String>}>} A promise that resolves
 * with the file attributes if it is accepted, or an array of errors if it is rejected.
 */
export async function handleImageUpload(file, {
  setUploadProgress = noop,
  maxFileSize = DEFAULT_MAX_FILE_SIZE,
} = {}) {
  if (!file) return null;

  const errors = validateImage(file, maxFileSize);
  if (errors.length) {
    return { errors };
  }
  const { attributes, errors: uploadErrors } = await uploadAttachment(file, setUploadProgress);

  return { attributes, errors: [...errors, ...uploadErrors] };
}

export async function handleImagesInsert({ view, position, files, onError = noop }) {
  const { schema } = view.state;

  for (const file of files) {
    const { attributes, errors } = await handleImageUpload(file);
    if (errors.length) onError(errors);
    if (errors.length || !attributes.url) return false;

    const node = schema.nodes['custom-image'].create(attributes);
    const transaction = view.state.tr.insert(position, node);
    view.dispatch(transaction);
  }

  return true;
}
