<template>
  <div
    v-show="showPicker || forceShowPicker"
    ref="emojiPicker"
    v-click-outside="closePickerWithDelay"
    class="fixed m-4"
    :class="showPicker ? 'z-50' : '-z-1'"
    :style="{ left, top }"
  >
    <picker
      v-if="emojisLoaded"
      :data="emojiIndex"
      class="static transform-none"
      :custom="customEmojis"
      :sheet-size="32"
      title="Pick your emoji"
      emoji="point_up"
      @select="emoji => selectEmoji(emoji)"
    />
    <div class="flex items-center h-10 pl-2 border-b border-x border-gray-400 border-solid rounded-b bg-slate-50">
      <div>
        <button
          class="h-6 px-2 text-sm font-medium bg-white border border-gray-400 border-solid rounded"
          @click="addCustomEmoji()"
        >
          {{ $t('meetings.customEmojis.addEmoji') }}
        </button>
      </div>
    </div>
    <upload-custom-emoji
      :open="isUploaderOpen"
      @close="isUploaderOpen = false"
    />
  </div>
</template>

<script>
import 'emoji-mart-vue-fast/css/emoji-mart.css';
import { Picker } from 'emoji-mart-vue-fast/src';

import reactionsApi from '../../api/reactions';
import uploadCustomEmoji from '../emojis/upload-custom-emoji.vue';

const WAIT_TIME = 300;

export default {
  components: {
    Picker,
    uploadCustomEmoji,
  },
  data() {
    return {
      height: 0,
      width: 0,
      customEmojisChangeCounter: 0,
      isUploaderOpen: false,
    };
  },
  computed: {
    forceShowPicker() {
      return this.$store.state.emoji.forceShow;
    },
    showPicker() {
      return this.$store.state.emoji.show;
    },
    lastOpenDateTime() {
      return this.$store.state.emoji.lastOpenDateTime;
    },
    left() {
      const leftPosition = this.$store.state.emoji.left - this.width;

      return `${leftPosition < 0 ? 0 : leftPosition}px`;
    },
    top() {
      const topPosition = this.$store.state.emoji.top - this.height;

      return `${topPosition < 0 ? 0 : topPosition}px`;
    },
    customEmojis() {
      return this.$store.state.emoji.customEmojis;
    },
    emojisLoaded() {
      return this.$store.state.emoji.loaded;
    },
    emojiIndex() {
      return this.$store.state.emoji.emojiIndex;
    },
    teamId() {
      return this.$store.state.team.teamId;
    },
  },

  watch: {
    customEmojis: {
      handler() {
        this.customEmojisChangeCounter += 1;
      },
      deep: true,
    },
  },
  mounted() {
    this.setDimensions();
  },
  created() {
    this.closePicker();
  },
  methods: {
    closePickerWithDelay() {
      if (!this.showPicker || !this.lastOpenDateTime ||
        new Date().getTime() - this.lastOpenDateTime.getTime() < WAIT_TIME) {
        return;
      }

      this.closePicker();
    },
    closePicker() {
      this.$store.dispatch('closeEmojiPicker');
    },
    selectEmoji(emoji) {
      const { type, id } = this.$store.state.emoji;
      const emojiParams = {
        content: emoji.native ? emoji.native : emoji.imageUrl,
        emojiType: emoji.native ? 'native' : 'custom',
        emojiColons: emoji.colons,
      };
      reactionsApi.saveReaction(type, id, emojiParams);

      document.querySelector('.emoji-mart-search > input:nth-child(1)').value = '';

      const event = new Event('input');
      document.querySelector('.emoji-mart-search > input:nth-child(1)').dispatchEvent(event);

      this.closePicker();
    },
    setDimensions() {
      const { height, width } = this.$refs.emojiPicker.getBoundingClientRect();
      this.height = height;
      this.width = width;
    },
    addCustomEmoji() {
      this.isUploaderOpen = true;
    },
  },
};
</script>

<style>

.emoji-mart.static.transform-none {
  border-top-left-radius: 4px ;
  border-top-right-radius: 4px ;
  border-bottom-left-radius: 0px ;
  border-bottom-right-radius: 0px ;
  font-size: 14px;
  font-weight: 700;
  height: 348px;
}

.emoji-mart.static.transform-none .emoji-mart-search input {
  font-size: 14px;
}

.emoji-mart-preview .emoji-mart-preview-data .emoji-mart-title-label {
  font-size: 18px;
}
.emoji-mart-preview .emoji-mart-preview-data .emoji-mart-preview-name {
  color: #888;
  font-size: 14px;
}
.emoji-mart-preview .emoji-mart-preview-data .emoji-mart-preview-shortnames {
  font-weight: 100;
}

.emoji-mart-preview .emoji-mart-preview-data .emoji-mart-preview-emoticons {
  display: none;
}

.emoji-type-custom {
  @apply bg-no-repeat bg-center bg-contain !important;
}
</style>
