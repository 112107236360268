import api from './index';

export default {
  removeComment(commentId) {
    return api({
      method: 'delete',
      url: `/api/internal/comments/${commentId}`,
    });
  },
  editComment(commentId, body) {
    return api({
      method: 'patch',
      url: `/api/internal/comments/${commentId}`,
      data: { body },
    });
  },
};
