<template>
  <base-modal
    :title="$t('sections.addSection')"
    @close="closeModal"
  >
    <div class="my-3">
      <mute-input
        v-model="sectionTitle"
        type="text"
        :label="$t('documents.create.fields.sectionNumber', { index: lastSectionIndex + 1 })"
        rules="required"
      />
    </div>
    <div class="flex">
      <mute-button
        class="mt-3 ml-auto"
        :disabled="!sectionTitle"
        icon-file-name="send.svg"
        @click="addSection"
      >
        {{
          $t('sections.add')
        }}
      </mute-button>
    </div>
  </base-modal>
</template>

<script>
import size from 'lodash/size';
import muteInput from '../shared/mute-input.vue';
import sectionsApi from '../../api/sections';

export default {
  components: { muteInput },
  props: {
    documentId: {
      type: String,
      required: true,
    },
    totalSections: {
      type: Number,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      sectionTitle: '',
    };
  },
  computed: {
    lastSectionIndex() {
      const sections = this.$store.state.document.sections;

      return sections && size(sections);
    },
  },
  methods: {
    closeModal() {
      this.sectionTitle = '';
      this.$emit('close');
    },
    addSection() {
      if (this.sectionTitle) {
        sectionsApi.addSection(this.documentId, { title: this.sectionTitle, order: this.totalSections })
          .then(() => {
            this.sectionTitle = '';
            this.closeModal();
          });
      }
    },
  },
};
</script>
