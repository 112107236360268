<template>
  <component
    :is="tag"
    class="px-2 leading-none relative transition-colors duration-150 ease-in-out bg-white cursor-pointer lg:px-4 "
    :class="{ 'font-medium bg-indigo-50 border-r-4 border-indigo-500': active, 'hover:bg-slate-50': !active }"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tag: { type: String, default: 'div' },
    active: { type: Boolean, required: true },
  },

};
</script>
