<template>
  <editable
    class="flex flex-col items-center w-full p-3 pb-4 text-xs lg:text-base"
    :show-edit-controls="author.id === $store.state.user.id && !editing"
    variant="comments-section"
    controls-classes="top-0 right-0 mt-2 mr-2"
    :object-id="Number(announcement.id)"
    :class="{ 'border-b border-gray-200': commentsCount === 0 }"
    @start-editing="editing = true"
  >
    <div class="flex w-full text-left transition-colors duration-150 ease-in-out focus:outline-none">
      <div class="flex flex-col flex-1 min-w-0 break-words">
        <div class="flex flex-1 mb-2">
          <div class="flex flex-col flex-1 min-w-0">
            <div class="flex flex-row">
              <mute-avatar
                class="self-start shrink-0 mr-2"
                size="medium"
                :src="author.avatar"
              />
              <div
                v-if="author.username"
                class="flex-1 font-bold text-purple-500"
              >
                {{ author.username }}
              </div>
            </div>
            <editable-text
              class="text-sm"
              :content-id="announcement.id"
              :author="author"
              :text="announcement.body"
              :editing="editing"
              content-type="thread-announcement"
              @confirm-edit="confirmAnnouncementEdit"
              @cancel-edit="editing = false"
            />
          </div>
        </div>
        <div class="flex items-center">
          <reactions-section
            class="flex-1"
            type="announcements"
            :reactable="announcement"
          />
        </div>
      </div>
    </div>
  </editable>
  <separator
    v-if="commentsCount > 0"
    :element-id="announcement.id"
    :text="`${commentsCount} ${pluralize($tc('meetings.comment.comment', 2).toLowerCase(), commentsCount)}`"
    text-classes="text-gray-400 text-[0.75rem]"
    text-side="left"
    separator-classes="border-gray-200"
  />
</template>

<script>
import { mapState } from 'vuex';
import map from 'lodash/map';
import pluralize from 'pluralize';
import Editable from '../shared/editable.vue';
import EditableText from '../shared/editable-text.vue';
import ReactionsSection from '../shared/reactions-section.vue';
import Separator from '../shared/separator.vue';
import announcementsApi from '../../api/announcements';

export default {
  components: {
    Editable,
    EditableText,
    ReactionsSection,
    Separator,
  },
  props: {
    announcement: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editing: false,
    };
  },
  computed: {
    ...mapState(['document']),
    author() {
      return this.announcement.author;
    },
    users() {
      return map(this.document.users, (user) => user.username);
    },
    commentsCount() {
      return this.announcement.comments && this.announcement.comments.length || 0;
    },
  },
  methods: {
    removeAnnouncement() {
      announcementsApi.removeAnnouncement(this.announcement.id);
    },
    async confirmAnnouncementEdit(newAnnouncement, tags) {
      await announcementsApi.editAnnouncement(this.announcement.id, { body: newAnnouncement, tagList: tags });
      this.editing = false;
    },
    pluralize,
  },
};
</script>
