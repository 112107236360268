<template>
  <button
    class="flex items-center justify-center px-2 py-1 text-sm text-white align-bottom bg-green-500 rounded-full"
  >
    <inline-svg
      :src="require('assets/images/icons/arrow-down.svg')"
      :class="['w-4 h-4 mr-2 fill-current', isOnTop ? 'rotate-180' : '']"
    />
    {{ $t('meetings.moreUnreads') }}
  </button>
</template>

<script>
export default {
  props: {
    isOnTop: { type: Boolean, default: false },
  },
};
</script>
