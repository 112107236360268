<template>
  <announcement-section-wrapper
    v-observe-visibility="(isVisible, entry) => $emit('visibility-changed', isVisible, entry)"
    :active="selected"
    class="relative"
    @click="selectSection"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
    <hover-bar
      v-if="notificationsAllowed"
      :items="hoverBarItems"
      :class="(hovered ? 'flex' : 'flex lg:hidden')"
      @item-click="toggleNotification"
    />
    <div
      class="flex py-4 border-b whitespace-nowrap lg:whitespace-normal items-center"
    >
      <div class="flex flex-col flex-1 mr-2">
        <div
          class="flex items-center mb-2 leading-none"
          :class="{ 'text-gray-300': isSilent }"
        >
          <inline-svg
            v-if="isSilent"
            :src="require('assets/images/icons/notifications-off.svg')"
            class="w-4 h-4 fill-current group-hover:block"
          />
          <p>{{ title }}</p>
        </div>
        <users-avatars
          class="h-4"
          :class="{ 'opacity-25': isSilent }"
          :users="usersInSection"
          :card-title-length="title.length"
        />
      </div>
      <div class="group w-6 h-6">
        <mute-notification
          v-if="notificationsAllowed && notification"
          :count="zero ? 0: unreadCount"
          class="group-hover:hidden"
          :class="{'bg-purple-500': mentioned}"
        />
      </div>
    </div>
  </announcement-section-wrapper>
</template>

<script>
import { mapState } from 'vuex';
import { logAmplitudeEvent } from '../../helpers/amplitude';
import HoverBar from '../shared/hover-bar.vue';
import UsersAvatars from '../shared/users-avatars';
import AnnouncementSectionWrapper from './announcement-section-wrapper';

export default {
  components: {
    UsersAvatars,
    AnnouncementSectionWrapper,
    HoverBar,
  },
  props: {
    sectionIndex: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['visibility-changed', 'change-section'],
  data() {
    return {
      hovered: false,
    };
  },
  computed: {
    ...mapState(['document', 'team']),
    notificationsAllowed() {
      return this.document.notificationsAllowed;
    },
    mutedAnnouncements() {
      return this.document.mutedAnnouncements;
    },
    unreadCount() {
      return this.$store.getters.unreadCount(this.sectionIndex);
    },
    mentioned() {
      return this.commentsCount(this.document.commentsNotificated) +
      this.announcementsCount(this.document.announcementsNotificated);
    },
    usersInSection() {
      return Object.values(this.$store.state.document.users)
        .filter(user => user.position && user.position.sectionId === this.sectionIndex);
    },
    documentConfiguration() {
      return this.$store.state.document.documentConfiguration;
    },
    zero() {
      const routeSectionId = this.$route.params.selectedSectionId;

      return routeSectionId && this.sectionIndex === routeSectionId;
    },
    showNotificationsImage() {
      return this.zero || !this.notificationsAllowed || !this.notification || this.unreadCount === 0;
    },
    notification() {
      return !this.document.mutedSections[this.sectionIndex];
    },
    isSilent() {
      return !this.notification || !this.notificationsAllowed;
    },
    hoverBarItems() {
      return [
        {
          id: 'silence',
          iconSrc: require(
            `assets/images/icons/notifications${this.isSilent ? '-off' : ''}.svg`,
          ),
        },
      ];
    },
  },
  methods: {
    toggleNotification() {
      logAmplitudeEvent('silence-section', {
        teamId: this.team.teamId,
        sectionId: this.sectionIndex,
        toValue: !this.isSilent,
      });

      this.$store.dispatch('toggleNotification',
        { type: 'mutedSections', index: this.sectionIndex, notification: this.notification });
    },
    selectSection() {
      this.$emit('change-section');
      this.$store.dispatch('resetHiddenAnnouncements');
    },
    announcementsCount(announcements) {
      return announcements.filter((u) => u.sectionId === this.sectionIndex).length;
    },
    commentsCount(comments) {
      return comments.filter((u) => u.sectionId === this.sectionIndex).length;
    },

  },
};
</script>
