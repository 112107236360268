<template>
  <div class="fixed right-0 flex flex-1 w-screen h-screen pb-28 lg:pb-0 lg:z-auto lg:top-0 lg:w-auto top-28 lg:relative lg:h-full">
    <div
      ref="announcementsContainer"
      class="flex flex-col grow overflow-y-auto bg-white"
    >
      <div class="flex flex-row justify-between items-center w-screen p-2 bg-slate-100 text-xs font-bold text-gray-400 border-b border-gray-200 max-h-full z-0 lg:w-auto relative top-0">
        {{ $t('meetings.announcement.drafts') }}
      </div>
      <div v-if="drafts.length > 0">
        <div
          v-for="(draft, index) in drafts"
          :key="index"
          class="flex p-2 cursor-pointer hover:bg-gray-50"
          @click="goToDraft(draft)"
        >
          <div class="flex flex-col flex-1 mb-2">
            <h4 class="font-semibold text-purple-500">
              Para anuncio en "{{ section(draft.sectionId).title }}"
            </h4>
            <rich-content :rich-text="draft.content" />
          </div>
          <div class="flex items-center">
            <inline-svg
              :src="require('../../assets/images/icons/right-arrow.svg')"
              class="w-5 h-5 text-green-500 fill-current"
            />
          </div>
        </div>
      </div>
      <p
        v-else
        class="flex items-center justify-center w-full h-full p-4 text-gray-600"
      >
        {{ $t('meetings.announcement.noDrafts') }}
      </p>
    </div>
    <emoji-picker />
  </div>
</template>

<script>
import map from 'lodash/map';
import { mapState } from 'vuex';
import EmojiPicker from '../components/shared/emoji-picker';
import RichContent from './shared/rich-content.vue';
import { logAmplitudeEvent } from '../helpers/amplitude';

export default {
  components: {
    EmojiPicker,
    RichContent,
  },
  computed: {
    ...mapState({
      document: (state) => state.document,
      teamId: (state) => state.team.teamId,
    }),
    sections() {
      return this.document.sections || {};
    },
    drafts() {
      const arrayDrafts = map(this.document.draftComments, (draftInfo, announcementId) => ({
        ...draftInfo, announcementId,
      }));

      return arrayDrafts || [];
    },
  },
  mounted() {
    if (this.drafts.length > 0) {
      logAmplitudeEvent('enter-drafts-section', {
        teamId: this.teamId,
        draftsCount: this.drafts.length,
      });
    }
  },
  methods: {
    section(sectionId) {
      return this.sections[sectionId];
    },
    goToDraft(draft) {
      this.$router.push(`/sections/${draft.sectionId}/${draft.announcementId}`);
    },
    sectionByAnnouncementId(announcementId) {
      return Object.values(this.sections).find((section) => (announcementId in section.announcements));
    },
  },
};
</script>
