import Mention from '@tiptap/extension-mention';
import { InputRule } from '@tiptap/core';
import { PluginKey } from 'prosemirror-state';

const tagRegex = /#([\w._-]+)/g;

export const TagPluginKey = new PluginKey('tag');

export default Mention.extend({
  name: 'tag',
  addInputRules() {
    return [
      new InputRule({
        find: RegExp(`${tagRegex.source} $`),
        handler: ({ state, range, match }) => {
          const [, inputTag] = match;
          const similarTags = this.options.suggestion.items({ query: inputTag });
          const matchedTag = similarTags.find((tag) => tag === inputTag);
          if (!matchedTag) return;
          const { tr } = state;
          tr.replaceWith(
            range.from,
            range.to,
            this.type.create({ id: matchedTag, label: matchedTag }),
          ).insertText(' ');
        },
      }),
    ];
  },
}).configure({
  HTMLAttributes: {
    class: 'text-gray-500 font-bold',
  },
  suggestion: {
    char: '#',
    pluginKey: TagPluginKey,
  },
});
