<!-- eslint-disable vue/no-v-html -->
<template>
  <mute-banner
    :kind="enabled ? 'success' : 'error'"
    :transparent="false"
    with-icon
  >
    <div
      class="flex items-center justify-between w-full"
    >
      <span v-html="text" />
      <inline-svg
        class="w-4 h-4 text-gray-500 cursor-pointer fill-current hover:text-gray-600"
        :src="require('assets/images/icons/close-icon.svg')"
        @click="$emit('close')"
      />
    </div>
  </mute-banner>
</template>

<script>
import { useI18n } from 'vue-i18n';
import MuteBanner from '../shared/mute-banner.vue';

export default {
  components: {
    MuteBanner,
  },
  props: {
    changedConfiguration: { type: String, required: true },
    enabled: { type: Boolean, required: true },
  },
  emits: ['close'],
  setup() {
    const { t } = useI18n();

    return { t };
  },
  computed: {
    text() {
      const name = this.changedConfiguration.replace(/Allowed/ig, '');
      const enabled = this.enabled ? 'enabled' : 'disabled';

      return this.t(`meetings.config.${enabled}.${name}`);
    },
  },
};
</script>

